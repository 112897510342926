import Api from "./Api.js";
import AxiosHelper from "./AxiosHelper.js";

class SkillMaterialManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new SkillMaterialManager();
    }
    return this._instance;
  }

  async deleteMaterialAsync(jwt, materialUuid) {
    const deleteSkillMaterialsResponse =
      await AxiosHelper.axoisDeleteAsync(Api.SKILL_MATERIALS +'/' + materialUuid, {}, jwt);

    if (deleteSkillMaterialsResponse.errorName) {
      console.warn(`Delete material failed (${deleteSkillMaterialsResponse.errorName})`);
    }

    return deleteSkillMaterialsResponse;
  }
}

export default SkillMaterialManager;
