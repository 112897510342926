import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import PfnKey from "../PfnKey";
import { message, Row, Col, Table } from "antd";
import { useSelector } from "react-redux";
import RobotManager from "../backend/RobotManager";
import LoginStateSlice from "../redux/LoginStateSlice";
import StoreState from "../redux/StoreState";
import timestampToDate from "../utils/timestampToDate";
import styled from "styled-components";
import { request } from "../request";

const ContentPlayHistoryPerRobotBox = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #f39800;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #f6ede4;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
  .header {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    .robot-name {
      font-size: 32px;
      font-weight: 700;
    }
  }
`;
const ContentPlayHistoryPerRobot = (props) => {
  const [robotVendorSerial, setRobotVendorSerial] = useState("");
  const [robotName, setRobotName] = useState("");
  const [tableData, setTableData] = useState([]);
  const loginState = useSelector((state) => state[StoreState.LOGIN]);
  const _getRobot = async () => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    const robotUuid = props[PfnKey.KEY_ROBOT_UUID];

    const getRobotResponse = await RobotManager.getInstance().getRobotAsync(
      robotUuid,
      jwt
    );
    if (!getRobotResponse.errorName) {
      const robot = getRobotResponse.data;
      setRobotVendorSerial(robot.vendorSerial);
      setRobotName(robot.robotName);
    } else {
      message.error("Error!");
    }
  };
  const getRobotViews = () => {
    const robotUuid = props[PfnKey.KEY_ROBOT_UUID];
    request("getViewListInRobot", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
      },
      data: {
        robotUuid: robotUuid,
        page: 1,
        pageSize: 10,
      },
    }).then((res) => {
      if (res.code == 200) {
        setTableData(res.data);
      }
    });
  };
  useEffect(() => {
    _getRobot();
  }, []);
  useEffect(() => {
    getRobotViews();
  }, [])
  const columns = [
    {
      title: "範疇",
      dataIndex: "itemName",
    },
    {
      title: "生活技能名稱",
      dataIndex: "skillName",
    },
    {
      title: "觀看次數",
      dataIndex: "viewCount",
    },
    {
      title: "日期和時間",
      dataIndex: "viewDate",
      render: (val)=><span>{timestampToDate(val)}</span>
    },
  ];
  return (
    <ContentPlayHistoryPerRobotBox>
      <div className="header">
        <div className="robot-name">影片觀看次數({robotName})</div>
      </div>
      <div>
        <Table dataSource={tableData} columns={columns} />
      </div>
    </ContentPlayHistoryPerRobotBox>
  );
};

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];

  return props;
}

export default connect(mapStateToProps)(ContentPlayHistoryPerRobot);
