class Response {
  constructor(data, errorName) {
    this._data = data;
    this._errorName = errorName;
  }

  get data() {
    return this._data;
  }

  get errorName() {
    return this._errorName;
  }
}

export default Response;
