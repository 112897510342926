import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import i18n from "../../i18n";
import { connect } from "react-redux";
import { Table, Modal, Button, Form, Input, Select, message } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import SchoolManager from "../../backend/SchoolManager";
import RobotManager from "../../backend/RobotManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import StoreState from "../../redux/StoreState";
import styled from "styled-components";
import "./index.css";

const RobotTable = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #f39800;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #f6ede4;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
`;
const OperationButtons = styled.div`
  .enableBtn {
    background: #0367ac;
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    height: 24px;
    width: 50px;
    font-size: 14px;
    &:hover {
      transform: scale(0.9);
    }
  }
  .updateBtn {
    background: rgb(34, 150, 34);
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    height: 24px;
    width: 50px;
    font-size: 14px;
    margin-right: 20px;
    &:hover {
      transform: scale(0.9);
    }
  }
  .deleteBtn {
    background: rgb(232, 56, 40);
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    height: 24px;
    width: 50px;
    font-size: 14px;
    &:hover {
      transform: scale(0.9);
    }
  }
`;
const HeaderBar = styled.div`
  .addUserBtn {
    background: #999999;
    text-align: center;
    width: 110px;
    border: 0;
    border-radius: 10px;
    transition: 0.5s;
    color: white;
    text-align: center;
    padding: 2px 0;
    font-size: 14px;
    margin-bottom: 12px;
    margin-left: calc( 60vw - 110px );
    .btnIcon {
      height: 14px;
      margin-right: 6px;
      margin-bottom: 2px;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
`;
const DataRobotManagement = (props) => {
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(null);
  const [choiceTableItem, setChoiceTableItem] = useState({});
  const [form] = Form.useForm();
  const [reRender, setReRender] = useState(1);
  const [schoolList, setSchoolList] = useState([]);
  const [robotList, setRobotList] = useState([]);
  const [editRobot, setEditRobot] = useState(null);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [showInputNameWarning, setShowInputNameWarning] = useState(false);
  const [showInputSerialWarning, setShowInputSerialWarning] = useState(false);
  // const _getSchoolList = async (jwt) => {
  //   let _schoolList = [];
  //   const getSchoolResponse =
  //     await SchoolManager.getInstance().getSchoolsAsync(null, jwt);

  //   for (let school of getSchoolResponse.data) {
  //     _schoolList.push({
  //       id: school.schoolUuid,
  //       name: school.schoolName
  //     })
  //   }
  //   setSchoolList(_schoolList)
  // }

  const _getRobotList = async (jwt) => {
    let _schoolList = [],
      _robotList = [];
    const getSchoolResponse = await SchoolManager.getInstance().getSchoolsAsync(
      null,
      jwt
    );
    for (let school of getSchoolResponse.data) {
      _schoolList.push({
        id: school.schoolUuid,
        name: school.schoolName,
      });
    }

    const getRobotsResponse = await RobotManager.getInstance().getRobotsAsync(
      jwt,
      null,
      null,
      null,
      null,
      i18n.language,
      true, //false,
      null, //false,
      true,
      null, //false,
      null,
      null,
      null
    );
    for (let robot of getRobotsResponse.data) {
      const schoolName = getSchoolName(_schoolList, robot.schoolUuid);
      _robotList.push({
        robotUuid: robot.robotUuid,
        robotName: robot.robotName,
        vendorSerial: robot.vendorSerial,
        schoolUuid: robot.schoolUuid,
        schoolName: schoolName,
        action: robot,
      });
    }
    setSchoolList(_schoolList);
    setRobotList(_robotList);
  };

  const getSchoolName = (schoolList, sid) => {
    var school = schoolList.find((s) => s.id === sid);
    if (school !== undefined) {
      return school.name;
    }
    return "";
  };
  const getSchoolOptionList = () => {
    const optionList = schoolList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    return optionList;
  };
  const openAddModal = () => {
    setIsModalOpen(true);
    setIsEditModal(false);
  };
  const modalSubmit = () => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    if (!isEditModal) {
      form
        .validateFields()
        .then(async (values) => {
          const postRobotResponse =
            await RobotManager.getInstance().postRobotAsync(
              jwt,
              values.schoolName,
              values.robotName,
              values.vendorSerial
            );
          if (postRobotResponse.errorName) {
            message.error(postRobotResponse.errorName);
          } else {
            message.success(<Trans>Succeed</Trans>)
          }
          form.resetFields();
          setReRender((pre) => pre + 1);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
      setIsModalOpen(false);
    } else {
      form
        .validateFields()
        .then(async (values) => {
          const putRobotResponse =
            await RobotManager.getInstance().putRobotAsync(
              choiceTableItem.robotUuid,
              jwt,
              values.schoolName,
              values.robotName,
              values.vendorSerial
            );
          if (putRobotResponse.errorName) {
            // alert(putRobotResponse.errorName);
            message.error(putRobotResponse.errorName)
          } else {
            message.success(<Trans>Succeed</Trans>)
          }
          form.resetFields();
          setReRender((pre) => pre + 1);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
      setIsModalOpen(false);
    }
  };
  const modalSubmitFails = (value) => {
    console.log("fails", value);
  };
  const updateModal = async (value) => {
    setIsModalOpen(true);
    setIsEditModal(true);
    form.setFieldsValue({
      robotName: value.robotName,
      vendorSerial: value.vendorSerial,
      schoolName: value.schoolUuid,
    });
    setChoiceTableItem({
      robotUuid: value.robotUuid,
    });
  };
  const updateUserStatus = async (item, boolean) => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    const putRobotResponse =
      await RobotManager.getInstance().updateRobotStatusAsync(
        item.robotUuid,
        jwt,
        boolean
      );
    if (putRobotResponse.errorName) {
      alert(putRobotResponse.errorName);
    } else {
      setReRender((pre) => pre + 1);
    }
  };
  const columns = [
    {
      title: "機器人名稱",
      dataIndex: "robotName",
      key: "robotName",
    },
    {
      title: "機器人唯一識別碼",
      dataIndex: "vendorSerial",
      key: "vendorSerial",
    },
    {
      title: "學校名稱",
      dataIndex: "schoolName",
      key: "schoolName",
    },
    {
      title: "編輯機器人",
      dataIndex: "operation",
      key: "operation",
      render: (val, item) => {
        return (
          <OperationButtons key={item.id}>
            <button className="updateBtn" onClick={() => updateModal(item)}>
              修改
            </button>
            {item.action.status == "NORMAL" && (
              <button
                className="deleteBtn"
                onClick={() => updateUserStatus(item, false)}
              >
                停用
              </button>
            )}
            {item.action.status == "DISABLED" && (
              <button
                className="enableBtn"
                onClick={() => updateUserStatus(item, true)}
              >
                啟用
              </button>
            )}
          </OperationButtons>
        );
      },
    },
  ];
  useEffect(() => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    _getRobotList(jwt);
  }, [reRender]);
  return (
    <div>
      <Modal
        title="機器人管理"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={800}
        style={{ borderRadius: "50px" }}
        wrapClassName="recodeRobotModal"
        closeIcon={null}
        destroyOnClose={true}
      >
        <Form
          name="addRobot"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          preserve={false}
          onFinish={modalSubmit}
          onFinishFailed={modalSubmitFails}
        >
          <Form.Item
            label="機器人名稱"
            name="robotName"
            rules={[{ message: "請輸入機器人名稱！", required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="機器人唯一識別碼"
            name="vendorSerial"
            rules={[{ required: true, message: "請輸入機器人唯一識別碼!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="學校名稱"
            name="schoolName"
            rules={[{ required: true, message: "請輸入學校名稱!" }]}
            required
          >
            <Select options={getSchoolOptionList()} />
          </Form.Item>
          <Form.Item label="">
            <div className="modalBtnBox">
              <Button htmlType="submit" className="saveBtn">
                儲存
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                className="cancelBtn"
              >
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div
        style={{
          fontSize: "32px",
          marginTop: "50px",
          marginBottom: "20px",
          fontWeight: 800,
        }}
      >
        機器人管理
      </div>
      <HeaderBar>
        <div onClick={openAddModal} className="addUserBtn">
          <img src="./icon/add.png" className="btnIcon"></img>
          <span>新增機器人</span>
        </div>
      </HeaderBar>
      <RobotTable>
        <Table dataSource={robotList} columns={columns} />
      </RobotTable>
    </div>
  );
};

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(DataRobotManagement);
