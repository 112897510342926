/* eslint-disable react/prop-types */
import React from "react";
import $ from "jquery";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import {
  Card,
  Container,
  Modal,
  Row,
  Col,
  Image,
  Stack,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCloudArrowUp,
  faPlayCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import LoginStateSlice from "../../redux/LoginStateSlice";
import SchoolStateSlice from "../../redux/SchoolStateSlice";
import SkillManager from "../../backend/SkillManager";
import SkillStatusManager from "../../backend/SkillStatusManager";
import StoreState from "../../redux/StoreState";
import PfnKey from "../../PfnKey";
import styled from "styled-components";
import { Radio, Pagination } from "antd";
const RoundPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
`;
const SortBar = styled.div`
  margin-bottom: 20px;
  .upArrow {
    background: #057dd3;
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    height: 30px;
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
    img {
      width: 18px;
      margin-bottom: 2px;
    }
  }
  .downArrow {
    background: #10b46a;
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    height: 30px;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
    margin-left: 20px;
    img {
      width: 18px;
      margin-bottom: 2px;
    }
  }

  .origin {
    background: #f39800;
  }
`;
const VideoList = styled.div`
  height: 550px;
  .uploadstep {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .uploadbox {
      position: relative;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      color: #fff;

      .step {
        position: absolute;
        padding: 5px;
        background: rgba(133, 51, 51, 0.2);
      }
    }
    .card {
      background: #fff;
      padding: 10px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      text-align: center;
      cursor: pointer;
      transition: 0.5s;
      height: 240px;
      width: 220px;
      &:hover {
        transform: scale(1.05);
      }

      > div {
        padding: 5px;
      }
      .imgContent {
        width: 200px;
        height: 150px;
        border: 1px solid #d9dbdb;
      }
      .name {
        color: #551a8b;
        font-weight: 600;
        .eye {
          width: 20px;
          height: 22px;
          margin-bottom: 4px;
        }
      }
    }
  }
`;
const ContentBox = styled.div`
  .pageTitle {
    font-size: large;
    color: #fff;
    margin: 20px 0;
    padding: 8px;
    border-radius: 5px;
    background: #00a0e9;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .inputSkill {
    height: 40px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px;
    padding-left: 10px;
  }

  .uploadstep {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .uploadbox {
      position: relative;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      color: #fff;
    }
  }

  .submit-opinion {
    display: flex;
    justify-content: right;

    .btn-left {
      background: #b6cc9b;
      color: #fff;
      font-weight: bold;
      padding: 8px 36px;
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.5s;
      border: 0;
      margin-top: 30px;
    }

    .btn-right {
      margin-left: 30px;
      background: rgb(0, 160, 233);
    }
  }

  .upload-two-code {
    color: black;
    background-color: white;
    font-weight: bold;
    border: 1px solid #dee2e6;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 20px 4px 2px 4px;
    width: 200px;
    height: 150px;

    .upload-content-wrap {
      position: relative;
      vertical-align: top;
      padding: 2px;
      margin: 2px;

      .close {
        position: absolute;
        display: inline-block;
        width: 25px;
        height: 25px;
        padding-left: 2px;
        line-height: 0.7;
        color: floralwhite;
        text-shadow: 1px 1px #282c34;
        border-radius: 50%;
        background-color: rgb(99, 99, 99, 0.5);
        font-size: 28px;
        top: 2px;
        right: 2px;
        z-index: 100;
      }
    }

    .file-upload-container {
      position: relative;
      text-align: center;
      color: gray;

      .file-upload-hint {
        position: absolute;
        top: -20px;
        left: 40px;
        color: black;
      }
    }
  }
  .submit-tow-code {
    background: rgb(0, 160, 233);
    color: #fff;
    font-weight: bold;
    width: 125px;
    height: 38px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.5s;
    border: 0;
    margin-top: 30px;
    margin-left: calc(100% - 130px);
  }
  .pageline {
    height: 1px;
    background: #ccc;
    margin: 30px 0;
  }
  .btnBox {
    display: flex;
    justify-content: center;
    .submitBtn {
      background: #b6cc9b;
      color: #fff;
      font-weight: bold;
      /* padding: 8px 36px; */
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      border: 0;
      transition: 0.5s;
      /* margin-right: 30px; */
      width: 100px;
      height: 40px;
      margin-right: 15px;
      margin-bottom: 20px;
    }
    .cancelBtn {
      margin-left: 15px;
    }
  }
`;
const Forcebox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .force_list {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin: 10px 0;
    gap: 10px;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const UploadStep = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .uploadbox {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #fff;
    width: 250px;
    height: 300px;
    .step {
      position: absolute;
      padding: 5px;
      background: rgba(133, 51, 51, 0.2);
      width: 50px;
      height: 30px;
      z-index: 10;
    }
    .stepbox {
      .container {
        padding: 0;
        .thumbnail {
          display: flex;
          justify-content: center;
        }
        .play {
          position: absolute;
          /* display: inline-block; */
          width: 48px;
          height: 48px;
          /* padding-left: 1px; */
          /* line-height: 0.7; */
          color: floralwhite;
          text-shadow: 2px 2px #282c34;
          border-radius: 50%;
          background-color: rgb(99, 99, 99, 0.5);
          font-size: 24px;
          top: 15px;
          /* right: 40px; */
          z-index: 100;
          /* text-align: center; */
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }
`;
const ModalHead = styled.div`
  position: relative;
  height: 20px;
  .close {
    inset-inline-end: 17px;
    z-index: 1010;
    padding: 0;
    top: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    position: absolute;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
    font-size: 30px;
  }
`;
class ContentSharingSelection extends React.Component {
  static SORTING_NEWEST = "Newest";
  static SORTING_OLDEST = "Oldest";
  static SORTING_SCHOOL = "School";

  constructor() {
    super();

    this.categoryUuid = "";
    this.state = {
      show: false,
      sortingOrder: ContentSharingSelection.SORTING_NEWEST,
      skillCategories: {},
      checkSkillCategory: [],
      showSkill: null,
      page: 1,
      current: 1,
      skillRecordList: [],
      skillRecordPagination: []
    };
  }

  componentDidMount() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    this.categoryUuid = this.props[PfnKey.KEY_CATEGORY_UUID];
    console.log("*** Get category ID: " + this.categoryUuid);

    this._getSkillRecords(jwt, this.categoryUuid);
    this._getSkillCategories(jwt);
    this.setState({
      sortingOrder: ContentSharingSelection.SORTING_NEWEST,
    });
  }
  componentDidUpdate(preProps) {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    if (preProps.categoryUuid !== this.props.categoryUuid) {
      this._getSkillRecords(jwt, this.props.categoryUuid);
      this._getSkillCategories(jwt);
      this.setState({
        sortingOrder: ContentSharingSelection.SORTING_NEWEST,
      });
    }
  }

  getOptions(catId, name, values) {
    const showSkill = this.state.showSkill;
    const optionId = catId + "-";
    var isOptionChecked = this.state.checkSkillCategory.includes(catId);
    var categoryInput = [];

    categoryInput.push(
      <div className="form-check" key={catId}>
        <input
          id={catId}
          name={catId}
          type="checkbox"
          readOnly
          checked={isOptionChecked}
          onChange={(e) => {
            $('[id^="' + optionId + '"]').attr("disabled", !e.target.checked);

            var checkCategory = this.state.checkSkillCategory;
            if (e.target.checked) {
              checkCategory.push(catId);
            } else {
              const index = checkCategory.indexOf(catId);
              if (index > -1) {
                checkCategory.splice(index, 1);
              }

              for (var v in values) {
                if (
                  showSkill !== null &&
                  showSkill.categoryItemList.includes(values[v].uid)
                ) {
                  const index = showSkill.categoryItemList.indexOf(
                    values[v].uid
                  );
                  if (index > -1) {
                    showSkill.categoryItemList.splice(index, 1);
                  }
                }
              }
            }

            this.setState({
              checkSkillCategory: checkCategory,
              disabledSubmit: this.validateInputFields(),
              showSkill: showSkill,
            });
            this.setUpdateFields(ContentSharingSelection.FIELD_CATAGORY);
          }}
        ></input>
        <label
          className="modal-options"
          style={{ padding: "2px", display: "inline-block" }}
        >
          {name}
        </label>
      </div>
    );

    for (var v in values) {
      var checked = false;
      if (
        showSkill !== null &&
        showSkill.categoryItemList.includes(values[v].uid)
      ) {
        checked = true;
      }

      categoryInput.push(
        <div className="form-check" key={v}>
          <input
            className="modal-custom-checkbox"
            id={optionId + v}
            name={values[v].uid}
            type="checkbox"
            readOnly
            disabled={!isOptionChecked}
            checked={checked}
          ></input>
          &nbsp;
          <label
            className="modal-options"
            style={{ padding: "2px", display: "inline-block" }}
          >
            {values[v].name}
          </label>
        </div>
      );
    }

    return (
      <Col xs={3} key={catId}>
        {categoryInput}
      </Col>
    );
  }

  async _getSkillRecords(jwt, categoryUuid) {
    var skillList = [];
    const skillResponse =
      await SkillStatusManager.getInstance().getApprovedSkillByCategoryAsync(
        jwt,
        categoryUuid
      );
    for (var skill of skillResponse.data) {
      var skillImage = null;
      if (skill["skillDiagram_00"]) {
        skillImage = skill.skillDiagram_00;
      }
      skillList.push({
        id: skill.skillUuid,
        name: skill.skillName,
        school: skill.schoolName,
        status: skill.skillStatus,
        image: skillImage,
        updateTime: skill.updateTime,
      });
    }
    const pageOneRecords = skillList.slice(0, 8)
    this.setState({
      skillRecordList: skillList,
      skillRecordPagination: pageOneRecords
    });
  }

  async _getSkillCategories(jwt) {
    var categoryArray = {};
    const schoolInfoResponse =
      await SkillManager.getInstance().getSkillCategoriesAsync(jwt);
    if (Object.keys(schoolInfoResponse.data).length > 1) {
      schoolInfoResponse.data.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });
    }

    for (var category of schoolInfoResponse.data) {
      const name = category.categoryName;
      const itemList = category.itemList;
      itemList.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });

      var data = [];
      for (var item of itemList) {
        data.push({
          uid: item.itemUuid,
          name: item.itemName,
        });
      }
      categoryArray[name] = {
        uid: category.categoryUuid,
        items: data,
      };
    }

    this.setState({
      skillCategories: categoryArray,
    });
  }

  onCardClick(skillId, schoolName) {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this._getSkill(jwt, skillId, schoolName);
  }

  async _getSkill(jwt, skillId, schoolName) {
    const skillResponse = await SkillManager.getInstance().getSkillAsync(
      jwt,
      skillId
    );
    const skill = skillResponse.data;
    console.log("getSkill: " + JSON.stringify(skill));
    var skillCoverFile = null;
    var skillQrCodeFile = null;
    var skillVideoCovers = [],
      skillVideoLinks = [];
    for (var section of skill.sectionList) {
      if (
        section.sectionName === "skillDiagram" &&
        section.materialList.length > 0
      ) {
        skillCoverFile = section.materialList[0].internalPath;
      } else if (section.sectionName === "skillVideo") {
        for (var material of section.materialList) {
          skillVideoCovers[material.partsSeq] = material.thumbnailUrl;
          skillVideoLinks[material.partsSeq] = material.externalLink;

          if (material.partsSeq === 0) {
            skillQrCodeFile =
              material.qrFilePath === "" ? null : material.qrFilePath;
          }
        }
      }
    }

    const checkCategory = [];
    const map = this.state.skillCategories;
    Object.keys(map).forEach((key) => {
      for (var item of map[key].items) {
        if (skill.categoryItemList.includes(item.uid)) {
          if (!checkCategory.includes(map[key].uid)) {
            checkCategory.push(map[key].uid);
          }
        }
      }
    });

    this.setState({
      showSkill: {
        id: skillId,
        name: skill.skillName,
        schoolName: schoolName,
        commentList: skill.commentList,
        categoryItemList: skill.categoryItemList,
        robotUuidList: skill.robotUuidList,
        skillCoverFile: skillCoverFile,
        skillQrCodeFile: skillQrCodeFile,
        skillVideoCovers: skillVideoCovers,
        skillVideoLinks: skillVideoLinks,
      },
      checkSkillCategory: checkCategory,
      checkApplyAllRobot: skill.robotUuidList == null,
      show: true,
    });
    this.updateFields = "";
  }

  sortSkillsByNewestUpdate(a, b) {
    if (a.updateTime > b.updateTime) {
      return -1;
    } else if (a.updateTime < b.updateTime) {
      return 1;
    }
    return 0;
  }

  sortSkillsByOldestUpdate(a, b) {
    if (a.updateTime > b.updateTime) {
      return 1;
    } else if (a.updateTime < b.updateTime) {
      return -1;
    }
    return 0;
  }

  sortSkillsBySchool(a, b) {
    return a.school.localeCompare(b.school);
  }

  sortSkills(skillList, sortOrder) {
    if (skillList !== undefined) {
      if (skillList.length < 1) return skillList;

      switch (sortOrder) {
        case ContentSharingSelection.SORTING_OLDEST:
          skillList.sort(this.sortSkillsByOldestUpdate);
          break;

        case ContentSharingSelection.SORTING_SCHOOL:
          skillList.sort(this.sortSkillsBySchool);
          break;

        default:
          skillList.sort(this.sortSkillsByNewestUpdate);
          break;
      }
      return skillList;
    }
    return [];
  }

  handleModalHide = () => {
    this.setState({ show: false });
  };

  renderSkillCategory() {
    // const map = this.state.skillCategories;

    // var htmlStr = [];
    // Object.keys(map).forEach((key) => {
    //   htmlStr.push(this.getOptions(map[key].uid, key, map[key].items));
    // });

    // return (
    //   <Row id="skillCategoryRow" style={{ fontSize: "12px" }}>
    //     {htmlStr}
    //   </Row>
    // );
    const { checkSkillCategory, showSkill } = this.state;
    const imgObj = {
      衣: "06-03.png",
      食: "06-04.png",
      住: "06-05.png",
      行: "06-06.png",
    };
    const map = this.state.skillCategories;
    const checkBoxs = Object.keys(map).map((key) => {
      const option = [];
      map[key].items.forEach((item) => {
        option.push({
          label: item.name,
          value: item.uid,
        });
      });
      let RadioVal = "";
      if (map[key].uid === checkSkillCategory[0])
        RadioVal = showSkill.categoryItemList[0];
      return (
        <div key={map[key].uid} className="force_list">
          <div>
            <img src={imgObj[key]} style={{ width: "100%" }} />
          </div>
          <Radio.Group
            options={option}
            onChange={(e) =>
              this.RadioGroupChange(e.target.value, map[key].uid)
            }
            value={RadioVal}
            style={{ width: "200px", display: 'grid' }}
            disabled
          />
        </div>
      );
    });
    return checkBoxs;
  }

  showVideo(e, videoLinks, index) {
    e.preventDefault();
    if (videoLinks !== null && videoLinks[index] !== null) {
      window.open(videoLinks[index], "videolink", "width=600,height=500");
      return false;
    }
  }
  BoxTemp = () => {
    // const sortingOrder = this.state.sortingOrder;
    // let skillList = this.state.skillRecordList;
    const {sortingOrder, skillRecordPagination} = this.state
    let skillList = this.sortSkills(skillRecordPagination, sortingOrder);
    return (
      <div className="uploadstep">
        {skillList.map((skill, index) => (
          <div
            className="card"
            key={index}
            onClick={() => this.onCardClick(skill.id, skill.school)}
          >
            <img src={skill.image} alt="" className="imgContent" />
            <div className="name">
              <a>
                <span style={{ marginRight: 10 }}>{skill.name}</span>
                <img src="/icon/eye.png" className="eye" />
              </a>
            </div>
            <div className="school">{skill.school}</div>
          </div>
        ))}
      </div>
    );
  };
  renderVideoSteps = () => {
    const n = 8;
    const showSkill = this.state.showSkill;
    const skillCoverImg = showSkill != null ? showSkill.skillCoverFile : null;
    const skillQrCodeImg = showSkill != null ? showSkill.skillQrCodeFile : null;
    const skillVideoCovers =
      showSkill != null ? showSkill.skillVideoCovers : null;
    const skillVideoLinks =
      showSkill != null ? showSkill.skillVideoLinks : null;
    return (
      <UploadStep>
        {[...Array(n)].map((item, index) => {
          const stepIndex = index + 1;
          return (
            <div className="uploadbox" key={index}>
              <div className="step">
                <Trans>{`UploadMovieStep${stepIndex}`}</Trans>
              </div>
              {!(
                skillVideoLinks !== null && skillVideoLinks.length > stepIndex
              ) && (
                <img
                  src="img_03.png"
                  style={{ width: "250px" }}
                  id={`step${stepIndex}VideoHint`}
                />
              )}
              <div className="stepbox">
                <FontAwesomeIcon
                  id={`step${stepIndex}VideoHint`}
                  width="130px"
                  icon={faCloudArrowUp}
                  className="fa-5x"
                  hidden={
                    skillVideoLinks !== null &&
                    skillVideoLinks.length > stepIndex
                  }
                />
                <div
                  href="\#"
                  className="container"
                  onClick={(e) => this.showVideo(e, skillVideoLinks, stepIndex)}
                  hidden={
                    !(
                      skillVideoLinks !== null &&
                      skillVideoLinks.length > stepIndex
                    )
                  }
                >
                  <div
                    className="play"
                    hidden={
                      !(
                        skillVideoLinks !== null &&
                        skillVideoLinks.length > stepIndex &&
                        skillVideoLinks[stepIndex] !== null
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlayCircle} className="fa-2x" />
                  </div>
                  <div
                    className="thumbnail"
                    hidden={
                      !(
                        skillVideoLinks !== null &&
                        skillVideoLinks.length > stepIndex &&
                        skillVideoLinks[stepIndex] !== null
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faVideo}
                      hidden={
                        skillVideoCovers !== null &&
                        skillVideoCovers.length > stepIndex
                          ? skillVideoCovers[stepIndex] !== null
                          : true
                      }
                      style={{
                        width: "130px",
                        height: "80px",
                        background: "#F5F5F5",
                        color: "gray",
                      }}
                      className="fa-2x"
                    />
                    <img
                      src={
                        skillVideoCovers !== null &&
                        skillVideoCovers.length > stepIndex
                          ? skillVideoCovers[stepIndex]
                          : ""
                      }
                      alt="Step 1"
                      style={{ width: "130px" }}
                      hidden={
                        skillVideoCovers !== null &&
                        skillVideoCovers.length > stepIndex
                          ? skillVideoCovers[stepIndex] === null
                          : true
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </UploadStep>
    );
  };
  paginationChange = (page) => {
    const {skillRecordList} = this.state
    this.setState({current: page})
    if(skillRecordList.length) {
      const middle = skillRecordList.slice((page - 1) * 8, 8 * page)
      this.setState({skillRecordPagination: middle})
    } 
  }
  render() {
    // const schoolName = SchoolStateSlice.getSchoolName(
    //   this.props[StoreState.SCHOOL]
    // );
   const {skillRecordList} = this.state
   const showSkill = this.state.showSkill;
   const skillCoverImg = showSkill != null ? showSkill.skillCoverFile : null;
   const skillQrCodeImg = showSkill != null ? showSkill.skillQrCodeFile : null;
   const skillVideoCovers =
     showSkill != null ? showSkill.skillVideoCovers : null;
   const skillVideoLinks =
     showSkill != null ? showSkill.skillVideoLinks : null;
    // var titleImage;
    // if (this.categoryUuid !== "") {
    //   titleImage = "/images/sharing_title_" + this.categoryUuid + ".png";
    // } else {
    //   titleImage = "/images/sharing_title_catUuidClothing.png";
    // }

    // var frameImage;
    // if (this.categoryUuid !== "") {
    //   frameImage = "/images/sharing_frame_" + this.categoryUuid + ".png";
    // } else {
    //   frameImage = "/images/sharing_frame_catUuidClothing.png";
    // }

    return (
      <div className="h-100">
        <div className="h-100 pb-5">
          <Modal
            id="uploadModal"
            size="xl"
            show={this.state.show}
            onHide={() => {
              this.handleModalHide();
            }}
          >
            <div className="modal-content">
              <ModalHead>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.handleModalHide()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHead>
              <div className="modal-body">
                <ContentBox id="uploadContent">
                  <div className="pageTitle">
                    <Trans>UploadSkillSchool</Trans>
                  </div>

                  <input
                    id="inputSkillName"
                    className="inputSkill"
                    type="text"
                    defaultValue={showSkill != null ? showSkill.schoolName : ""}
                    readOnly
                  ></input>

                  <div className="pageTitle">
                    <Trans>UploadSkillName</Trans>
                  </div>

                  <input
                    id="inputSkillName"
                    className="inputSkill"
                    type="text"
                    defaultValue={showSkill != null ? showSkill.name : ""}
                    readOnly
                  ></input>

                  <div className="pageTitle">
                    <Trans>UploadSkillCategory</Trans>
                  </div>

                  <Forcebox id="categoryCheckboxes">
                    {this.renderSkillCategory()}
                  </Forcebox>

                  <div className="pageTitle">
                    <Trans>UploadSkillVideo</Trans>
                  </div>
                  <UploadStep>
                    <button style={{ border: "none" }}>
                      <div className="upload-content-wrap">
                        <FontAwesomeIcon
                          id="fullVideoHint"
                          width="130px"
                          icon={faCloudArrowUp}
                          className="fa-5x"
                          hidden={
                            skillVideoLinks !== null &&
                            skillVideoLinks.length > 0
                          }
                        />
                        <div className="video-link-wrap">
                          <a
                            href="\#"
                            onClick={(e) =>
                              this.showVideo(e, skillVideoLinks, 0)
                            }
                            hidden={
                              !(
                                skillVideoLinks !== null &&
                                skillVideoLinks.length > 0
                              )
                            }
                          >
                            <div
                              className="play"
                              hidden={
                                !(
                                  skillVideoLinks !== null &&
                                  skillVideoLinks.length > 0 &&
                                  skillVideoLinks[0] !== null
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPlayCircle}
                                className="fa-2x"
                              />
                            </div>
                            <div
                              className="thumbnail"
                              hidden={
                                !(
                                  skillVideoLinks !== null &&
                                  skillVideoLinks.length > 0 &&
                                  skillVideoLinks[0] !== null
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faVideo}
                                hidden={
                                  skillVideoCovers !== null &&
                                  skillVideoCovers.length > 0
                                    ? skillVideoCovers[0] !== null
                                    : true
                                }
                                style={{
                                  width: "130px",
                                  height: "80px",
                                  background: "#F5F5F5",
                                  color: "gray",
                                }}
                                className="fa-2x"
                              />
                              <img
                                src={
                                  skillVideoCovers !== null &&
                                  skillVideoCovers.length > 0
                                    ? skillVideoCovers[0]
                                    : ""
                                }
                                alt="Full Video"
                                style={{ width: "130px" }}
                                hidden={
                                  skillVideoCovers !== null &&
                                  skillVideoCovers.length > 0
                                    ? skillVideoCovers[0] === null
                                    : true
                                }
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </button>
                  </UploadStep>
                  <div className="pageTitle">
                    <Trans>UploadStepsHints</Trans>
                  </div>
                  {this.renderVideoSteps()}

                  <div className="pageTitle">
                    <Trans>UploadSkillImage</Trans>
                  </div>
                  <button disabled>
                    <div className="file-upload-container">
                      <img
                        src={skillCoverImg}
                        alt="img"
                        style={{
                          padding: "0px",
                          margin: "0px",
                          width: "130px",
                        }}
                        hidden={skillCoverImg === null}
                      />
                      <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        width="130px"
                        className="fa-5x"
                        hidden={skillCoverImg !== null}
                      />
                    </div>
                  </button>

                  <div className="pageTitle">
                    <Trans>UploadSkillQrCode</Trans>
                  </div>

                  <button disabled>
                    <div className="upload-content-wrap">
                      <div className="file-upload-container">
                        <div className="file-upload-hint">
                          <Trans>UploadQrCode</Trans>
                        </div>
                        <img
                          id="qrCodePreview"
                          src={skillQrCodeImg}
                          alt="img"
                          style={{
                            padding: "0px",
                            margin: "0px",
                            width: "140px",
                          }}
                          hidden={skillQrCodeImg === null}
                        />
                        <FontAwesomeIcon
                          id="qrCodeHint"
                          width="130px"
                          icon={faCloudArrowUp}
                          className="fa-5x"
                          hidden={skillQrCodeImg !== null}
                        />
                      </div>
                    </div>
                  </button>

                  <Row>
                    <Col xs={5}></Col>
                    <Col xs={2}>
                      <button
                        id="cancelButton"
                        style={{
                          minWidth: "140px",
                          color: "white",
                          background: "#00a0e9",
                          fontWeight: "bold",
                          borderWidth: "1px",
                          letterSpacing: "3px",
                          padding: "4px 20px 4px 20px",
                          borderRadius: "5px",
                          border: "none",
                          marginTop: '30px'
                        }}
                        onClick={() => this.handleModalHide()}
                      >
                        <Trans>Done</Trans>
                      </button>
                    </Col>
                    <Col xs={5}></Col>
                  </Row>
                </ContentBox>
              </div>
            </div>
          </Modal>
          <Container>
            <SortBar>
              <button
                className="upArrow"
                onClick={() =>
                  this.updateSorting(ContentSharingSelection.SORTING_NEWEST)
                }
              >
                <img src="/icon/up_arrow.png" />
                <Trans>SortNewestToOldest</Trans>
              </button>
              <button
                className="downArrow"
                onClick={() =>
                  this.updateSorting(ContentSharingSelection.SORTING_OLDEST)
                }
              >
                <img src="/icon/down_arrow.png" />
                <Trans>SortOldestToNewest</Trans>
              </button>
              <button
                className="upArrow origin"
                onClick={() =>
                  this.updateSorting(ContentSharingSelection.SORTING_SCHOOL)
                }
              >
                <img src="/icon/sortAZ.png" />
                <Trans>SortbySchool</Trans>
              </button>
            </SortBar>
            <VideoList>{this.BoxTemp()}</VideoList>
            <RoundPagination>
              <Pagination
                total={skillRecordList.length}
                onChange={this.paginationChange}
                current={this.state.current}
                pageSize={8}
                showSizeChanger={false}
              />
            </RoundPagination>
          </Container>
        </div>
      </div>
    );
  }

  updateSorting(ordering) {
    this.setState({
      sortingOrder: ordering,
    });
  }
}

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(ContentSharingSelection);
