import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import './i18n';
import App from './App';
import AppStore from "./redux/AppStore";
import reportWebVitals from './reportWebVitals';
ReactDOMClient.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={AppStore}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
