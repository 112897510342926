import { createSlice } from '@reduxjs/toolkit';


class BaseStateSlice {
  constructor(persistent = false) {
    this._persistent = persistent;

    this._slice = createSlice({
      name: this.constructor.name,
      initialState: this._createInitState(),
      reducers: {
        clearState: () => {
          const newState = {};
          this._saveState(newState);
          return newState;
        },

        setState: (state, action) => {
          Object.keys(action.payload).forEach((key) => {
            state[key] = action.payload[key];
          });
          this._saveState(state);
        },
      }
    });
  }  

  get slice() {
    return this._slice;
  }

  get reducer() {
    return this._slice.reducer;
  }

  _createInitState() {
    let savedState = null;

    if (this._persistent) {
      try {
        const savedJsonStr = window.localStorage.getItem(this.constructor.name);

        savedState = savedJsonStr ? JSON.parse(savedJsonStr) : null;
      } catch (e) {
        console.error(e);
      }
    }

    return savedState ? savedState : {};
  }

  _saveState(state) {
    if (this._persistent) {
      window.localStorage.setItem(this.constructor.name, JSON.stringify(state));
    }
  }
}

export default BaseStateSlice;
