import Api from "./Api.js";
import AxiosHelper from "./AxiosHelper.js";
import PfnKey from "../PfnKey.js";

class RobotManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new RobotManager();
    }
    return this._instance;
  }

  async getRobotsAsync(
    jwt,
    schoolUuid = null,
    partialSchoolName = null,
    partialRobotName = null,
    partialVendorSerial = null,
    locale = null,
    noSchool = null,
    noUser = null,
    withSchool = null,
    withUser = null,
    status = null,
    limit = null,
    offset = null
  ) {
    console.log(`Getting robot list, arg:`);

    let arg = {};
    if (schoolUuid) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (partialSchoolName) arg[PfnKey.KEY_PARAM_PARTIAL_SCHOOL_NAME] = partialSchoolName;
    if (partialRobotName) arg[PfnKey.KEY_PARAM_PARTIAL_ROBOT_NAME] = partialRobotName;
    if (partialVendorSerial) arg[PfnKey.KEY_PARAM_PARTIAL_VENDOR_SERIAL] = partialVendorSerial;
    if (locale) arg[PfnKey.KEY_PARAM_LOCALE] = locale;
    if (noSchool !== null) arg[PfnKey.KEY_PARAM_NO_SCHOOL] = noSchool;
    if (noUser !== null) arg[PfnKey.KEY_PARAM_NO_USER] = noUser;
    if (withSchool !== null) arg[PfnKey.KEY_PARAM_WITH_SCHOOL] = withSchool;
    if (withUser !== null) arg[PfnKey.KEY_PARAM_WITH_USER] = withUser;
    if (status !== null) arg[PfnKey.KEY_STATUS] = status;
    if (limit || limit === 0) arg[PfnKey.KEY_PARAM_LIMIT] = limit;
    if (offset || offset === 0) arg[PfnKey.KEY_PARAM_OFFSET] = offset;
    
    console.log(arg);

    const response = await AxiosHelper.axoisGetAsync(Api.ROBOTS, arg, jwt);

    console.log(`Get robot list ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async addRobotAssignmentAsync(
    jwt,
    displayName = null,
    schoolName = null,
    vendorSerial = null,
    userUuid = null,
    schoolUuid = null,
    robotUuid = null
  ) {
    console.log(`Adding robot assignment, arg:`);

    let arg = {};
    if (displayName) arg[PfnKey.KEY_USER_DISPLAY_NAME] = displayName;
    if (schoolName) arg[PfnKey.KEY_SCHOOL_NAME] = schoolName;
    if (vendorSerial) arg[PfnKey.KEY_VENDOR_SERIAL] = vendorSerial;
    if (userUuid) arg[PfnKey.KEY_USER_UUID] = userUuid;
    if (schoolUuid !== null) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (robotUuid !== null) arg[PfnKey.KEY_ROBOT_UUID] = robotUuid;
    
    console.log(arg);

    const response = await AxiosHelper.axoisPostAsync(Api.ROBOT_ASSIGNMENT, arg, jwt);

    console.log(`Add robot assignment ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async deleteRobotAssignmentAsync(
    jwt,
    robotUuid
  ) {
    console.log(`Deleting robot assignment of ${robotUuid}...`);

    const response = await AxiosHelper.axoisDeleteAsync(`${Api.ROBOT_ASSIGNMENT}/${robotUuid}`, null, jwt);

    console.log(`Deleted robot assignment ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  /*
  // commented as the implementation of this API is outdated in the backend and update is not required anymore
  async updateRobotAssignmentAsync(
    jwt,
    robotUuid,
    userUuid = null,
    schoolUuid = null
  ) {
    console.log(`Updating robot assignment of ${robotUuid}, arg:`);

    let arg = {};
    if (userUuid !== null) arg[PfnKey.KEY_USER_UUID] = userUuid;
    if (schoolUuid !== null) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    
    console.log(arg);

    const response = await AxiosHelper.axoisPutAsync(`${Api.ROBOT_ASSIGNMENT}/${robotUuid}`, arg, jwt);

    console.log(`Update robot assignment ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }
  */

  async postRobotAsync(
    jwt,
    schoolUuid = null,
    robotName = null,
    vendorSerial = null
  ) {
    console.log(`Posting robot list, arg:`);

    let arg = {};
    if (schoolUuid) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (robotName) arg[PfnKey.KEY_PARAM_ROBOT_NAME] = robotName;
    if (vendorSerial) arg[PfnKey.KEY_PARAM_VENDOR_SERIAL] = vendorSerial;

    console.log(arg);

    const response = await AxiosHelper.axoisPostAsync(Api.ROBOTS, arg, jwt);

    console.log(`Post robot ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async putRobotAsync(
    robotUuid,
    jwt,
    schoolUuid = null,
    robotName = null,
    vendorSerial = null
  ) {
    let route = "";
    if (robotUuid) {
      route += `/${robotUuid}`;
    }

    console.log(`Putting robot list, arg:`);

    let arg = {};
    if (schoolUuid) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (robotName) arg[PfnKey.KEY_PARAM_ROBOT_NAME] = robotName;
    if (vendorSerial) arg[PfnKey.KEY_PARAM_VENDOR_SERIAL] = vendorSerial;

    console.log(arg);

    const response = await AxiosHelper.axoisPutAsync(Api.ROBOTS + route, arg, jwt);

    console.log(`Put robot ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async updateRobotStatusAsync(
    robotUuid,
    jwt,
    enabled
  ) {
    let route = "";
    if (robotUuid) {
      route += `/${robotUuid}`;
    }

    console.log(`Put robot status, arg:`);

    let arg = {};
    if (enabled) {
      arg[PfnKey.KEY_STATUS] = "NORMAL";
    } else {
      arg[PfnKey.KEY_STATUS] = "DISABLED";
    }

    console.log(arg);

    const response = await AxiosHelper.axoisPutAsync(Api.ROBOTS + route, arg, jwt);

    console.log(`Update robot status ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async getRobotAsync(
    robotUuid,
    jwt
  ) {
    let route = "";
    if (robotUuid) {
      route += `/${robotUuid}`;
    }

    let arg = {};

    const response = await AxiosHelper.axoisGetAsync(Api.ROBOTS + route, arg, jwt);

    console.log(`Get robot ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }
}

export default RobotManager;
