import React, { useEffect } from "react";
import { withRouter } from "../../withRouter";
const CheckHref = ()=>{


 useEffect(()=>{
  const userAgent = navigator.userAgent;  
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {  
   // 当前设备是iOS设备  
   window.location.href = "https://apps.apple.com/app/%E6%81%A9%E6%85%88%E6%A9%9F%E6%A2%B0%E4%BA%BA/id6468986244"
 } else if (/Android/.test(userAgent)) {  
   // 当前设备是Android设备  
   window.location.href = "https://play.google.com/store/apps/details?id=org.astri.cr2203a02app01"
 } 
 }, [])
  return <div>Loading...</div>
}
export default withRouter(CheckHref)