import React, { useCallback, useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import RobotManager from "../../backend/RobotManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import StoreState from "../../redux/StoreState";
import { Table, Modal, Form, Input, Button, message, Select } from "antd";
import styled from "styled-components";
import { request } from "../../request";

const RobotAssignment = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #f39800;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #f6ede4;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
`;
const AssignmentHeader = styled.div`
  .addUserBtn {
    background: #999999;
    text-align: center;
    width: 110px;
    border: 0;
    border-radius: 10px;
    transition: 0.5s;
    color: white;
    text-align: center;
    padding: 2px 0;
    font-size: 14px;
    margin-bottom: 12px;
    margin-left: calc(60vw - 110px);
    .btnIcon {
      height: 14px;
      margin-right: 6px;
      margin-bottom: 2px;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
`;
const UpdateBtn = styled.div`
  background: rgb(34, 150, 34);
  color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border: unset;
  border-radius: 6px;
  transition: 0.5s;
  cursor: pointer;
  height: 24px;
  width: 50px;
  font-size: 14px;
  margin: auto;
  &:hover {
    transform: scale(0.9);
  }
`;

const RobotAssignmentMain = (props) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [reRender, setReRender] = useState(0);
  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(null);
  const [schoolval, setSchoolval] = useState("");
  const [schoolData, setSchoolData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [robotData, setRobotData] = useState([]);
  const loginState = useSelector((state) => state[StoreState.LOGIN]);
  const { t, i18n } = useTranslation();
  const _getRobotAssignmentAsync = useCallback(async () => {
    const res = await RobotManager.getInstance().getRobotsAsync(
      LoginStateSlice.getJwt(loginState),
      null,
      null,
      null,
      null,
      i18n.language,
      false,
      false,
      true,
      true,
      "NORMAL",
      null,
      null
    );
    if (!res.errorName && res.data) {
      return res.data;
    }
    return null;
  }, [i18n, loginState]);



  let timeout = null;
  const robotFetch = (val) => {
    return request("/robots", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
      },
      params: {
        locale: "zh-Hant",
        noSchool: false,
        noUser: true,
        withSchool: true,
        withUser: false,
        status: "NORMAL",
        limit: 15,
        offset: 0,
        partialVendorSerial: val || '',
        partialSchoolName: schoolval,
      },
    }).then((res) => {
      try {
        if (res.data) {
          setRobotData(res.data);
        }
      } catch (error) {
        console.log(error)
      }

    });
  };
  const studentFetch = (val) => {
    return request("/users", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
      },
      params: {
        role: "STUDENT",
        partialUserName: val || '',
        partialSchoolName: schoolval,
        status: "NORMAL",
        locale: "zh-Hant",
        limit: 15,
        offset: 0,
      },
    }).then((res) => {
      if (res.data) {
        setStudentData(res.data);
      }
    });
  };
  const getDefaultOption = async(val)=>{
     request("/users", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
      },
      params: {
        role: "STUDENT",
        partialUserName: '',
        partialSchoolName: val,
        status: "NORMAL",
        locale: "zh-Hant",
        limit: 15,
        offset: 0,
      },
    }).then((res) => {
      try {
        if (res.data) {
          setStudentData(res.data);
        }
      } catch (error) {
        console.log(error)
      }

    });
     request("/robots", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
      },
      params: {
        locale: "zh-Hant",
        noSchool: false,
        noUser: true,
        withSchool: true,
        withUser: false,
        status: "NORMAL",
        limit: 15,
        offset: 0,
        partialVendorSerial: '',
        partialSchoolName: val,
      },
    }).then((res) => {
      try {
        if (res.data) {
          setRobotData(res.data);
        }
      } catch (error) {
        console.log(error)
      }

    });
  }
  const onSearch = async (val, key) => {
    switch (key) {
      case "school":
        request("/schools", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
          },
          params: {
            partialSchoolName: val,
            locale: "zh-Hant",
            limit: 70,
            offset: 0,
          },
        }).then(async (res) => {
          if (res.data) {
            setSchoolData(res.data);
          }
        });
        break;
      case "student":
        studentFetch(val);
        break;
      case "robot":
        robotFetch(val);
        break;
    }
  };

  const modalSubmit = () => {
    if (!isEditModal) {
      form.validateFields().then(async (values) => {
        const res = await RobotManager.getInstance().addRobotAssignmentAsync(
          LoginStateSlice.getJwt(loginState),
          values.studentname,
          values.schoolName,
          values.robotCode
        );
        if (!res.errorName) {
          messageApi.success("Successd");
        } else {
          if(res.errorName == 'ERROR_IN_USE'){
            messageApi.error("該學生已經綁定過機器人！");
          }else {
            messageApi.error("Faild");
          }      
        }
        form.resetFields();
        setIsModalOpen(false);
        setReRender((pre) => pre + 1);
      });
    } else if (isEditModal) {
      form.validateFields().then(async (values) => {
        const res = await RobotManager.getInstance().deleteRobotAssignmentAsync(
          LoginStateSlice.getJwt(loginState),
          values.robotUuid
        );
        if (!res.errorName) {
          messageApi.success("Successd");
        } else {
          messageApi.error("Faild");
        }
        form.resetFields();
        setIsModalOpen(false);
        setReRender((pre) => pre + 1);
      });
    }
  };
  const modalSubmitFails = (value) => {
    console.log("fails", value);
  };
  const addModal = () => {
    setIsModalOpen(true);
    setIsEditModal(false);
  };
  const updateModal = (value) => {
    setIsEditModal(true);
    setIsModalOpen(true);
    form.setFieldsValue({
      robotCode: value.vendorSerial,
      schoolName: value.schoolName,
      studentname: value.displayName,
      robotUuid: value.robotUuid,
    });
  };
  async function fetchData() {
    const rows = await _getRobotAssignmentAsync();
    setRows(rows === null ? [] : rows);
  }
  const formValuesChange = (changedFields, allFields) => {
     if(changedFields.schoolName){
      form.setFieldValue('studentname', '')
      form.setFieldValue('robotCode', '')
     }
  }
  useEffect(() => {
    onSearch('', 'school')
    fetchData();
  }, [reRender]);
  const columns = [
    {
      title: "機器人名稱",
      dataIndex: "robotName",
      key: "robotName",
    },
    {
      title: "機器人唯一識別碼",
      dataIndex: "vendorSerial",
      key: "vendorSerial",
    },
    {
      title: "機器人所屬學校",
      dataIndex: "schoolName",
      key: "schoolName",
    },
    {
      title: "學生名稱",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "選項",
      dataIndex: "choice",
      key: "choice",
      render: (_, item) => (
        <UpdateBtn key={item.vendorSerial} onClick={() => updateModal(item)}>
          修改
        </UpdateBtn>
      ),
    },
  ];
  return (
    <div>
      {contextHolder}
      <Modal
        title="配對機器人"
        open={isModalOpen}
        width={800}
        onCancel={() => setIsModalOpen(false)}
        wrapClassName="rewriteModalStyle"
        footer={null}
        destroyOnClose
      >
        <Form
          form={form}
          preserve={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={modalSubmit}
          onFinishFailed={modalSubmitFails}
          onValuesChange={formValuesChange}
        >
          <Form.Item
            label="機器人所屬學校"
            name="schoolName"
            rules={[{ required: true, message: "請選擇機器人所屬學校!" }]}
            validateDebounce={1000}
          >
            <Select
              disabled={isEditModal}
              // onSearch={(val) => onSearch(val, "school")}
              showSearch
              onChange={(val) => setSchoolval(val)}
              onSelect={(val) => getDefaultOption(val)}
              value={schoolval}
              options={(schoolData || []).map((item) => ({
                value: item.schoolName,
                label: item.schoolName,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="機器人名稱"
            name="robotCode"
            rules={[{ required: true, message: "請選擇機器人名稱!" }]}
            validateDebounce={1000}
          >
            <Select
              disabled={isEditModal}
              onSearch={(val) => onSearch(val, "robot")}
              showSearch
              options={(robotData || []).map((item) => ({
                value: item.vendorSerial,
                label: item.robotName,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="學生名稱"
            name="studentname"
            rules={[{ required: true, message: "請選擇學生名稱!" }]}
            validateDebounce={1000}
          >
            <Select
              onSearch={(val) => onSearch(val, "student")}
              showSearch
              options={(studentData || []).map((item) => ({
                value: item.displayName,
                label: item.displayName,
              }))}
              disabled={isEditModal}
            />
          </Form.Item>
          <Form.Item name="robotUuid" />
          <Form.Item label="">
            <div className="modalBtnBox">
              <Button htmlType="submit" className="saveBtn">
                {isEditModal ? <Trans>Unassign</Trans> : <Trans>Save</Trans>}
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                className="cancelBtn"
              >
                <Trans>Cancel</Trans>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div className="userMangeTitle">
        <Trans>NavRobotAssignment</Trans>
      </div>
      <AssignmentHeader>
        <div className="addUserBtn" onClick={() => addModal()}>
          <img src="./icon/add.png" className="btnIcon"></img>
          <Trans>AddRobot</Trans>
        </div>
      </AssignmentHeader>
      <RobotAssignment>
        <Table dataSource={rows} columns={columns} />
      </RobotAssignment>
    </div>
  );
};

export default RobotAssignmentMain;
