import PfnKey from '../PfnKey.js';
import BaseStateSlice from './BaseStateSlice.js';


class LoginStateSlice extends BaseStateSlice {
  constructor() {
    super(true);
  }
  
  createState(jwt, role, schoolUuid) {
    const state = {};

    if (jwt) {
      state[PfnKey.KEY_JWT] = jwt;
    }
    if (role) {
      state[PfnKey.KEY_ROLE] = role;
    }
    if (schoolUuid) {
      state[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    }    

    return state;
  }

  getJwt(state) {
    return state ? state[PfnKey.KEY_JWT] : null;
  }

  getRole(state) {
    return state ? state[PfnKey.KEY_ROLE] : null;
  }

  getSchoolUuid(state) {
    return state ? state[PfnKey.KEY_SCHOOL_UUID] : null;
  }
}

export default new LoginStateSlice();
