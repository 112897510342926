import OSS from "ali-oss";

const uploadAliOss = async (type, fileName, file) => {
  if (!(type === "img" || type === "video")) {
    return "";
  }
  const client = new OSS({
    accessKeySecret: "Sv2MIjQucpGG2RBNJZ92bTU5pCThDi",
    bucket: "talkbo-enci",
    region: "oss-cn-hongkong",
    accessKeyId: "LTAI5t7stBYpycYHk8vGvXhm",
    timeout: "3600000",
  });
  try {
    if (type === "img") {
      const ossSchoolImg =
        // eslint-disable-next-line no-undef
        process.env.NODE_ENV === "development" ? "devSchoolImg" : "SchoolImg";
      return await client
        .put(`${ossSchoolImg}/${fileName}`, file)
        .then((res) => {
          if (res.res.status == 200) {
            return res.url;
          } else {
            return "";
          }
        });
    }
  } catch (e) {
    return "";
  }
};

export default uploadAliOss;
