import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector, connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SchoolManager from "../../backend/SchoolManager";
import AppRoute from "../../AppRoute";
import PfnKey from "../../PfnKey";
import UserManager from "../../backend/UserManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import SchoolStateSlice from "../../redux/SchoolStateSlice";
import StoreState from "../../redux/StoreState";
import { withRouter } from "../../withRouter";
import styled from "styled-components";
const SidebarStyled = styled.div`
   width: 16vw;
  .usermenu {
    font-size: large;
    font-family: "Noto Serif HK", serif;
    list-style-type: none;
    margin: 20px 0;
    padding: 0;
    a {
      color: #fff;
      font-style: normal;
      text-decoration: none;
      li {
        margin: 10px;
        line-height: 50px;
        height: 50px;
        padding-left: 5px;
        font-size: 20px;
        font-weight: 700;
        background: #dcdbdb;
        color: #fff;
        border-radius: 5px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        &:hover {
          background: #eab646;
          cursor: pointer;
        }
      }
    }
  }
  .main_left {
    background: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    .logo {
      width: 16vw;
    }
  }
  .userprofile {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;

    .avatar {
      max-width: 150px;
      border-radius: 50%;
      margin: 10px 0;
    }

    .name {
      font-size: 18px;
      font-weight: bold;
    }

    .time {
      font-size: xx-small;
      color: #666;
    }

    .letter {
      transition: 0.5s;
      &:hover {
        color: #eab646;
      }
    }
  }
`;

const RcgsSidebar = (props) => {
  const role = LoginStateSlice.getRole(
    useSelector((state) => state.loginState)
  );
  const [schoolName, setSchoolName] = useState("XXX School");
  const [pictureUrl, setPictureUrl] = useState("");
  let [preClickDom, setPreClickDom] = useState(null);
  const [addImg, setAddImg] = useState("");
  const inAdhocGroup1 = [
    UserManager.UserRole.ADMIN,
    UserManager.UserRole.SCHOOL,
  ].includes(role);

  const inAdhocGroup2 = [
    UserManager.UserRole.ADMIN,
    UserManager.UserRole.AUDITOR,
  ].includes(role);

  const authority = {
    skillSubmission: inAdhocGroup1,
    skillSubmissHistory: inAdhocGroup1,
    skillSharing: inAdhocGroup1,
    skillReview: inAdhocGroup2,
    robotAssignment: inAdhocGroup2,
    VideoPlayHistoryRobotSelection: inAdhocGroup2,
    dataSchoolManagement: inAdhocGroup2,
    dataUserManagement: inAdhocGroup2,
    dataRobotManagement: inAdhocGroup2,
  };
  const UserProfile = () => {
    return (
      <div className="userprofile">
        <img src={pictureUrl} className="avatar" alt="" />
        <div className="name">{schoolName}</div>
        {/* <div className="time">2022-05-17 10:09</div> */}
      </div>
    );
  };
  const getSchoolInfoAsync = async () => {
    const schoolMgr = SchoolManager.getInstance();

    const loginState = props[StoreState.LOGIN];
    const schoolUuid = LoginStateSlice.getSchoolUuid(loginState);
    const jwt = LoginStateSlice.getJwt(loginState);

    const schoolInfoResponse = await schoolMgr.getSchoolsAsync(schoolUuid, jwt);

    const error = schoolInfoResponse.errorName;

    if (!error) {
      let schoolName = schoolInfoResponse.data[PfnKey.KEY_SCHOOL_NAME];
      let pictureUrl = schoolInfoResponse.data[PfnKey.KEY_PICTURE_URL];
      // this.setState({
      //   schoolName: schoolName,
      //   pictureUrl: pictureUrl
      // });
      setSchoolName(schoolName);
      setPictureUrl(pictureUrl);

      props.dispatch(
        SchoolStateSlice.slice.actions.setState(
          SchoolStateSlice.createState(schoolName, pictureUrl)
        )
      );
    }
  };
  const onLogout = () => {
    console.log("Logging out ...");
    props.dispatch(LoginStateSlice.slice.actions.clearState());
    props.navigate(AppRoute.Login);
  };
  const sidebarClick = (e, key) => {
    if (preClickDom) preClickDom.style.background = "";
    e.target.style.background = "#f39800";
    setPreClickDom(e.target);
    setAddImg(key);
  };
  useEffect(() => {
    getSchoolInfoAsync();
  }, []);
  return (
    <SidebarStyled>
      <div className="main_left">
        <img src="App_logo with text_01.png" className="logo" alt="" />
        {UserProfile()}
        <ul className="usermenu">
          {authority.skillSubmission && (
            <Link to={AppRoute.SkillSubmission}>
              <li onClick={(e) => sidebarClick(e, "SkillSubmission")}>
                {addImg === "SkillSubmission" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>NavSkillSubmission</Trans>
              </li>
            </Link>
          )}
          {authority.skillSubmissHistory && (
            <Link to={AppRoute.SkillSubmissHistory}>
              <li onClick={(e) => sidebarClick(e, "SkillSubmissHistory")}>
                {addImg === "SkillSubmissHistory" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>NavSkillSubmissHistory</Trans>
              </li>
            </Link>
          )}
          {authority.skillSharing && (
            <Link to={AppRoute.SkillSharing}>
              <li onClick={(e) => sidebarClick(e, "SkillSharing")}>
                {addImg === "SkillSharing" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>NavSkillSharing</Trans>
              </li>
            </Link>
          )}
          {authority.skillReview && (
            <Link to={AppRoute.SkillReview}>
              <li onClick={(e) => sidebarClick(e, "SkillReview")}>
                {addImg === "SkillReview" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>NavSkillReview</Trans>
              </li>
            </Link>
          )}
          {authority.robotAssignment && (
            <Link to={AppRoute.RobotAssignment}>
              <li onClick={(e) => sidebarClick(e, "RobotAssignment")}>
                {addImg === "RobotAssignment" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>NavRobotAssignment</Trans>
              </li>
            </Link>
          )}
          {authority.VideoPlayHistoryRobotSelection && (
            <Link to={AppRoute.VideoPlayHistoryRobotSelection}>
              <li onClick={(e) => sidebarClick(e, "VideoPlayHistoryRobotSelection")}>
                {addImg === "VideoPlayHistoryRobotSelection" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>VideoPlayHistory</Trans>
              </li>
            </Link>
          )}
          {authority.dataSchoolManagement && (
            <Link to={AppRoute.DataSchoolManagement}>
              <li onClick={(e) => sidebarClick(e, "DataSchoolManagement")}>
                {addImg === "DataSchoolManagement" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>DataSchoolManagement</Trans>
              </li>
            </Link>
          )}
          {authority.dataUserManagement && (
            <Link to={AppRoute.DataUserManagement}>
              <li onClick={(e) => sidebarClick(e, "DataUserManagement")}>
                {addImg === "DataUserManagement" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>DataUserManagement</Trans>
              </li>
            </Link>
          )}
          {authority.dataRobotManagement && (
            <Link to={AppRoute.DataRobotManagement}>
              <li onClick={(e) => sidebarClick(e, "DataRobotManagement")}>
                {addImg === "DataRobotManagement" && (
                  <img
                    src="icon/right_arrow.png"
                    style={{ width: "25px", margin: "12px 2px 15px 0" }}
                  />
                )}
                <Trans>DataRobotManagement</Trans>
              </li>
            </Link>
          )}
          <a>
            <li style={{ background: "skyblue" }} onClick={onLogout}>
              <Trans>Logout</Trans>
            </li>
          </a>
        </ul>
      </div>
    </SidebarStyled>
  );
};
function mapStateToProps(state) {
  const props = {};
  props[StoreState.LOGIN] = state[StoreState.LOGIN];

  return props;
}
export default withRouter(connect(mapStateToProps)(RcgsSidebar));
