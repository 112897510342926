/* eslint-disable max-classes-per-file */

import PfnKey from "./PfnKey.js";


const _BaseError = class extends Error {
  get name() {
    return this.constructor.name;
  }

  get extra() {
    return this._extra;
  }
  
  set extra(info) {
    this._extra = info;
  }
};

const _BadRequestError     = class extends _BaseError {};

const _ForbiddenError      = class extends _BaseError {};

const _InternalServerError = class extends _BaseError {};

const _NotFoundError       = class extends _BaseError {};

const _UnauthorizedError   = class extends _BaseError {};

const _UnavailableError    = class extends _BaseError {};

const PfnError = Object.freeze({
  BaseError                               : _BaseError,

  BadRequestError                         : _BadRequestError,
  ForbiddenError                          : _ForbiddenError,
  InternalServerError                     : _InternalServerError,
  NotFoundError                           : _NotFoundError,
  UnauthorizedError                       : _UnauthorizedError,
  UnavailableError                        : _UnavailableError,

  /**
   * Each class is an enum value.
   */ 
  ERROR_AXIOS_GENERIC                     : class extends _BaseError           {},

  getAll() {
    const errObj = {};

    const keyList = Object.keys(this).filter(key => key.startsWith("ERROR_"));

    errObj[PfnKey.KEY_ERRORS] =
      keyList.map((key) => {
        const obj = {};

        obj[PfnKey.KEY_ERROR_NAME] = key;

        return obj;
      });

    return errObj;
  }
});

export default PfnError;
