import Api from "./Api.js";
import PfnKey from "../PfnKey.js";
import AxiosHelper from "./AxiosHelper.js";

class SkillManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new SkillManager();
    }
    return this._instance;
  }

  async getSkillCategoriesAsync(jwt) {
    const getSkillCategoriesResponse = await AxiosHelper.axoisGetAsync(
      Api.SKILL_CATEGORIES,
      {},
      jwt
    );

    if (getSkillCategoriesResponse.errorName) {
      console.warn(
        `Get skill categories failed (${getSkillCategoriesResponse.errorName})`
      );
    }

    return getSkillCategoriesResponse;
  }

  async getSkillAsync(jwt, skillId) {
    const getSkillResponse = await AxiosHelper.axoisGetAsync(
      Api.SKILLS + "/" + skillId,
      {},
      jwt
    );

    if (getSkillResponse.errorName) {
      console.warn(`Get skill failed (${getSkillResponse.errorName})`);
    }

    return getSkillResponse;
  }

  async postSkillAsync(
    jwt,
    skillName,
    categoryList,
    robotList,
    skillVideoLinks,
    // videoFileList,
    coverImage,
    qrCodeImage
  ) {
    const jsonDataObject = {};
    jsonDataObject[PfnKey.KEY_SKILL_NAME] = skillName;
    jsonDataObject[PfnKey.KEY_CATEGORY_ITEM_LIST] = categoryList;
    jsonDataObject[PfnKey.KEY_ROBOT_UUID_LIST] = robotList;
    jsonDataObject["skillVideoLinks"] = skillVideoLinks;
    jsonDataObject["skillDiagram"] = coverImage;
    const jsonDataString = {};
    jsonDataString[PfnKey.KEY_DATA] = jsonDataObject;

    let formData = new FormData();
    formData.append(
      PfnKey.KEY_SKILL_DATA_JSON_STRING,
      JSON.stringify(jsonDataString)
    );
    // videoFileList.forEach(function (item, index) {
    //   formData.append(
    //     PfnKey.KEY_SKILL_VIDEO + "_0" + index, item);
    // });
    // formData.append(PfnKey.KEY_SKILL_DIAGRAM + "_00", coverImage);
    if (qrCodeImage !== null) {
      formData.append(PfnKey.KEY_SKILL_VIDEO + "_00_qrImage", qrCodeImage);
    }

    const postResponse = await AxiosHelper.axoisPostFilesAsync(
      Api.SKILLS,
      formData,
      jwt
    );

    if (postResponse.errorName) {
      console.warn(
        "post Skill failed " +
          JSON.stringify(postResponse.errorInfo) +
          " : " +
          skillName
      );
    } else {
      console.log(`post Skill succeeded : ${skillName}`);
    }

    return postResponse;
  }

  async putSkillAsync(
    jwt,
    skillUuid,
    skillName,
    categoryList,
    robotList,
    videoFileList,
    coverImage,
    qrCodeImage,
    newComment,
    materialList
  ) {
    console.log(`put SkillUuid : ${skillUuid}`);

    const jsonDataObject = {};
    if (skillName !== null) {
      jsonDataObject[PfnKey.KEY_SKILL_NAME] = skillName;
    }
    if (categoryList !== null) {
      jsonDataObject[PfnKey.KEY_CATEGORY_ITEM_LIST] = categoryList;
    }
    if (robotList !== undefined) {
      jsonDataObject[PfnKey.KEY_ROBOT_UUID_LIST] = robotList;
    }
    if (newComment !== null) {
      jsonDataObject[PfnKey.KEY_SKILL_NEW_COMMENT] = newComment;
    }
    if (materialList != null) {
      jsonDataObject[PfnKey.KEY_SKILL_MATERIAL_LIST] = materialList;
    }

    const jsonDataString = {};
    jsonDataString[PfnKey.KEY_DATA] = jsonDataObject;

    let formData = new FormData();
    formData.append(
      PfnKey.KEY_SKILL_DATA_JSON_STRING,
      JSON.stringify(jsonDataString)
    );
    //console.log(PfnKey.KEY_SKILL_DATA_JSON_STRING + ': ' + JSON.stringify(jsonDataString));

    if (videoFileList !== null) {
      videoFileList.forEach(function (item, index) {
        if (item !== null) {
          formData.append(PfnKey.KEY_SKILL_VIDEO + "_0" + index, item);
        }
      });
    }
    if (coverImage !== null) {
      formData.append(PfnKey.KEY_SKILL_DIAGRAM + "_00", coverImage);
    }
    if (qrCodeImage !== null) {
      formData.append(PfnKey.KEY_SKILL_VIDEO + "_00_qrImage", qrCodeImage);
    }

    const putResponse = await AxiosHelper.axoisPutFilesAsync(
      Api.SKILLS + "/" + skillUuid,
      formData,
      jwt
    );

    if (putResponse.errorName) {
      console.warn(
        "put Skill failed " +
          JSON.stringify(putResponse.errorInfo) +
          " : " +
          skillUuid
      );
    } else {
      console.log(`put Skill succeeded : ${skillUuid}`);
    }

    return putResponse;
  }

  async putSkillCommentAsync(jwt, skillUuid, newComment) {
    console.log(`put SkillUuid : ${skillUuid}`);

    const jsonDataObject = {};
    if (newComment !== null) {
      jsonDataObject[PfnKey.KEY_SKILL_NEW_COMMENT] = newComment;
    }

    const jsonDataString = {};
    jsonDataString[PfnKey.KEY_DATA] = jsonDataObject;

    let formData = new FormData();
    formData.append(
      PfnKey.KEY_SKILL_DATA_JSON_STRING,
      JSON.stringify(jsonDataString)
    );
    console.log(
      PfnKey.KEY_SKILL_DATA_JSON_STRING + ": " + JSON.stringify(jsonDataString)
    );

    const putResponse = await AxiosHelper.axoisPutFilesAsync(
      Api.SKILLS + "/" + skillUuid,
      formData,
      jwt
    );

    if (putResponse.errorName) {
      console.warn(
        "put skill comment failed " +
          JSON.stringify(putResponse.errorInfo) +
          " : " +
          skillUuid
      );
    } else {
      console.log(`put skill comment succeeded : ${skillUuid}`);
    }

    return putResponse;
  }

  async putQrCodeAsync(jwt, skillUuid, qrCodeImage) {
    console.log(`put SkillUuid : ${skillUuid}`);

    let formData = new FormData();
    if (qrCodeImage !== null) {
      formData.append(PfnKey.KEY_SKILL_VIDEO + "_00_qrImage", qrCodeImage);
    }

    const putResponse = await AxiosHelper.axoisPutFilesAsync(
      Api.SKILLS + "/" + skillUuid,
      formData,
      jwt
    );

    if (putResponse.errorName) {
      console.warn(
        "put qrCode to Skill failed " +
          JSON.stringify(putResponse.errorInfo) +
          " : " +
          skillUuid
      );
    } else {
      console.log(`put qrCode to Skill succeeded : ${skillUuid}`);
    }

    return putResponse;
  }

  async putSkillMaterialListAsync(jwt, skillUuid, materialList, qrCodeImage) {
    console.log(`put SkillUuid : ${skillUuid}`);

    const jsonDataObject = {};
    if (materialList != null) {
      jsonDataObject[PfnKey.KEY_SKILL_MATERIAL_LIST] = materialList;
    }

    const jsonDataString = {};
    jsonDataString[PfnKey.KEY_DATA] = jsonDataObject;

    let formData = new FormData();
    formData.append(
      PfnKey.KEY_SKILL_DATA_JSON_STRING,
      JSON.stringify(jsonDataString)
    );
    console.log(
      PfnKey.KEY_SKILL_DATA_JSON_STRING + ": " + JSON.stringify(jsonDataString)
    );
    if (qrCodeImage !== null) {
      formData.append(PfnKey.KEY_SKILL_VIDEO + "_00_qrImage", qrCodeImage);
    }

    const putResponse = await AxiosHelper.axoisPutFilesAsync(
      Api.SKILLS + "/" + skillUuid,
      formData,
      jwt
    );

    if (putResponse.errorName) {
      console.warn(
        "put skill material list failed " +
          JSON.stringify(putResponse.errorInfo) +
          " : " +
          skillUuid
      );
    } else {
      console.log(`put skill material list succeeded : ${skillUuid}`);
    }

    return putResponse;
  }
}

export default SkillManager;
