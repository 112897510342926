import React from "react";
import styled from "styled-components";

const ContentMainStyled = styled.div`
  padding: 20px;
  max-width: 100%;
  .title {
    font-weight: 600;
    margin: 30px 0;
  }
`;

const ContentMain = () => {
  return (
    <ContentMainStyled>
      <h2 className="title">計劃理念</h2>
      <p>
        禮賢會恩慈學校獲創科生活基金撥款港幣2,758,326，推展「為智障學生而設的生活技能機械人」計劃。生活技能機械人設有多元化的學習軟件及配套，透過機械人的指導及陪伴，期望能提升學生不同方面的生活能力，及充實生活閒暇。本校期望每位學生都擁有一部生活技能機械人，讓機械人於校內及家中成為學生的智能助教及陪伴者。以下是機械人的主要功能：
      </p>
      <p>
        QR生活技能學習平台、機械人聊天室、隨意聊天、非華語學中文、恩慈原創學習Apps、恩慈電視台、音樂播放室、智能提醒等。
      </p>
      <p>
        作為智障學校教師，首重提升學生的獨立生活能力。本校特意設立「生活技能跨校資源共享平台」，邀請所有參與學校分享「衣、食、住、行」範疇的生活技能教材，營造教學資源共享文化。
      </p>
      <img src="img_01.png" className="" alt="" style={{width: '100%'}}/>
    </ContentMainStyled>
  );
};

export default ContentMain;
