const AppRoute = Object.freeze({
  Login           : "/",
  Main            : "/main",
  ForgetPassword  : "/forgetPassword",
  ResetPassword   : "/passwordRecovery",

  RobotAssignment : "/robotAssignment",
  
  SkillSharing    : "/skillSharing",
  SkillSharingSelection : "/skillSharingSelection",
  SkillSubmission : "/skillSubmission",
  SkillSubmissHistory : "/skillSubmissHistory",
  SkillReview     : "/skillReview",
  VideoPlayHistory: "/videoPlayHistory",
  VideoPlayHistoryRobotSelection: "/videoPlayHistoryRobotSelection",
  VideoPlayHistorySkillSelection: "/videoPlayHistorySkillSelection",

  DataSchoolManagement: "/schoolManagement",
  DataUserManagement: "/userManagement",
  DataRobotManagement: "/robotManagement",
  CheckHref: '/checkHref'
});

export default AppRoute;
