const HttpUtils = Object.freeze({  
  Scheme : Object.freeze({
    HTTP : "http",
    HTTPS: "https"
  }),

  Status : Object.freeze({
    OK                            : 200,
    CREATED                       : 201,
    ACCEPTED                      : 202,
    NO_CONTENT                    : 204,
    BAD_REQUEST                   : 400,
    UNAUTHORIZED                  : 401,
    FORBIDDEN                     : 403,
    NOT_FOUND                     : 404,
    INTERNAL_SERVER_ERROR         : 500,
    SERVICE_UNAVAILABLE           : 503,
  }),

  is2XX(code) {
    return Number.isInteger(code) && 199 < code && code < 300;
  },
  
  getMethodName (method) {
    let name = "";
  
    if (method) {
      do {
        if (method.get) {
          name = "GET";
          break;
        }
        if (method.post) {
          name = "POST";
          break;
        }
        if (method.put) {
          name = "PUT";
          break;
        }
        if (method.delete) {
          name = "DELETE";
          break;
        }
      // eslint-disable-next-line no-constant-condition
      } while (false);
    } 
  
    return name;
  },
});

export default HttpUtils;
