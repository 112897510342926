import Axios from "axios";

import HttpUtils from "../utils/HttpUtils.js";
import PfnKey from "../PfnKey.js";
import PfnError from "../PfnError.js";
import Response from "./Response.js";


async function axiosCallAsync(fnAsync) {
  let res = null;
  try {
    res = await fnAsync();
  } catch (e) {
    console.error(e);

    if (e && e.response) {
      res = e.response;
    }
  }

  const retResponse = (res && HttpUtils.is2XX(res.status)) ?
    new Response(res.data[PfnKey.KEY_DATA], null) :
    (() => {
      const payload = res ? res.data : null;    
      const errorName = payload ? payload[PfnKey.KEY_ERROR_NAME] : null;
      return new Response(null, errorName ? errorName : PfnError.ERROR_AXIOS_GENERIC.name);
    })();
  
  return retResponse;
}

function createAuthHeader(jwt) {
  return jwt ? { Authorization : `Bearer ${jwt}`} : {}
}

const AxiosHelper  = Object.freeze({
  async axoisDeleteAsync(url, params, jwt) {
    const config = { headers: createAuthHeader(jwt)};
    if (params) config.data = params;

    return await axiosCallAsync(async () => await Axios.delete(url, config));
  },

  /**
   * @param {*} url 
   * @param {*} params 
   * @returns   Non-NULL response
   */
  async axoisPostAsync(url, params, jwt) {
    const config = { headers: createAuthHeader(jwt)};

    return await axiosCallAsync(async () => await Axios.post(url, params, config));
  },

  async axoisPostFilesAsync(url, params, jwt) {
    const config = {
      headers: {
        Authorization : `Bearer ${jwt}`,
        'Content-Type': `multipart/form-data; boundary=${params._boundary}`
      }
    };

    return await axiosCallAsync(async () => await Axios.post(url, params, config));
  },

  async axoisPutAsync(url, params, jwt) {
    const config = { headers: createAuthHeader(jwt)};

    return await axiosCallAsync(async () => await Axios.put(url, params, config));
  },

  async axoisPutFilesAsync(url, params, jwt) {
    const config = {
      headers: {
        Authorization : `Bearer ${jwt}`,
        'Content-Type': `multipart/form-data; boundary=${params._boundary}`
      }
    };

    return await axiosCallAsync(async () => await Axios.put(url, params, config));
  },

  async axoisGetAsync(url, params, jwt) {
    const config = {
      headers: createAuthHeader(jwt),
      params
    };

    return await axiosCallAsync(async () => await Axios.get(url, config));
  }
});

export default AxiosHelper;
