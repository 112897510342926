import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../withRouter";
import { styled } from "styled-components";
import ContentPlayHistoryRobotSelection from "../ContentPlayHistoryRobotSelection";
import ContentPlayHistorySkillSelection from "../ContentPlayHistorySkillSelection";
const nameData = ["衣", "食", "住", "行"];
const VideoPlayBox = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  h2 {
    margin: 10px 0 20px 0;
    font-weight: 600;
  }
  .selectitems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .active {
      filter: unset;
      transform: unset;
      img {
        width: 100%;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }
    div {
      filter: brightness(0.5) blur(0.5px) opacity(80%);
      cursor: pointer;
      transform: scale(0.85);
      transition: 0.5s;
      img {
        width: 100%;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }
  }
`;
const VideoPlayPagination = styled.div`
  padding: 30px 0;
  position: absolute;
  bottom: 0px;
  width: 240px;
  left: calc(50% - 120px);
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      text-align: center;
      display: inline-block;
      text-decoration: unset;
    }
    .is-active {
      background-color: #00a0e9;
      border-radius: 100%;
      color: #fff;
    }
  }
`;
const Top = ({ item, setItem, page, setPage }) => {
  return (
    <div className="selectitems">
      <div
        className={item === "機械人" ? "active" : ""}
        onClick={() => {
          setItem("機械人");
          setPage("1");
        }}
      >
        <img src="img-05.png" alt="" />
      </div>
      <div
        className={item === "生活技能" ? "active" : ""}
        onClick={() => {
          setItem("生活技能");
          setPage("3");
        }}
      >
        <img src="img-06.png" alt="" />
      </div>
    </div>
  );
};

const Pagination = () => {
  const n = 8;
  return (
    <VideoPlayPagination>
      <ul>
        {[...Array(n)].map((elementInArray, index) => (
          <Link className={index === 0 ? "is-active" : ""} key={index}>
            <li>{index + 1}</li>
          </Link>
        ))}
      </ul>
    </VideoPlayPagination>
  );
};

const ContentPlayHistoryMain = () => {
  const [item, setItem] = useState("機械人");
  const [page, setPage] = useState("1");

  return (
    <VideoPlayBox>
      <h2>影片觀看次數({item})</h2>
      <Top item={item} setItem={setItem} page={page} setPage={setPage} />
      {(() => {
        switch (page) {
          case "2":
            // return <RobotPlayList page={page} setPage={setPage} />;
            return <ContentPlayHistoryRobotSelection />;
          case "3":
            // return <OtherList page={page} setPage={setPage} />;
            return <ContentPlayHistorySkillSelection />;
          default:
            // return <RobotList page={page} setPage={setPage} />;
            return <ContentPlayHistoryRobotSelection />;
        }
      })()}
      <Pagination />
    </VideoPlayBox>
  );
};

export default withRouter(ContentPlayHistoryMain);
