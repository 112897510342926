import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationZhHant from './locales/zh-Hant/translation.json';

// the translations
const translationRes = {
  'en': {
    translations: translationEn
  },
  'zh-Hant': {
    translations: translationZhHant
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'zh-Hant',
    resources: translationRes,
    fallbackLng: "en",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we do not use keys in form messages.welcome    
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react!!
    }
  });

export default i18n;
