import Jsqr from "jsqr";


const QrcodeUtils = Object.freeze({
  async decodeImageSafeAsync(file) {
    let result = null;

    try {
      result = await this.decodeImageAsync(file);
    } catch (e) {
      // Ignore;
      console.log(e);
    }

    return result;
  },

  async decodeImageAsync (file) {
    let result = null;
    
    if (file) {
      const dataUrl = await readAsDataUrlAsync(file);

      if (dataUrl) {
        const imgData = await getImageDataAsync(dataUrl, document.createElement("canvas"));

        if (imgData) {
          const decoded = Jsqr(imgData.data, imgData.width, imgData.height);

          result = decoded && decoded.data;
        }
      }
    }

    return result;    
  }
})

async function readAsDataUrlAsync(file) {
  const dataUrl = await new Promise((resolve) => {
    const reader = new FileReader();

    reader.onabort = (ev) => {
      resolve(null);
    }

    reader.onerror = (ev) => {
      resolve(null);
    }

    reader.onload = (ev) => {
      if (reader.readyState === reader.DONE) {
        resolve(reader.result);
      }    
    };

    reader.readAsDataURL(file);  
  });
  
  return dataUrl;
}

async function getImageDataAsync(dataUrl, canvas) {
  const img = new Image();  
  const ctx = canvas.getContext("2d");

  return await new Promise((resolve) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.fillStyle = "white";
      ctx.fillRect(0,0, img.width, img.height);
      ctx.drawImage(img, 0, 0);

      resolve(ctx.getImageData(0, 0, canvas.width, canvas.height));
    };
    img.src = dataUrl;
  });  
}

export default QrcodeUtils;
