import React from 'react';
import Action from '../Action';
import ContentMain from '../../component/ContentMain';
import ContentPlayHistoryRobotSelection from '../../component/ContentPlayHistoryRobotSelection';
import ContentPlayHistoryPerRobot from '../../component/ContentPlayHistoryPerRobot';
import ContentPlayHistorySkillSelection from '../../component/ContentPlayHistorySkillSelection';
import ContentSharingMain from '../../component/ContentSharingMain';
import ContentSharingSelection from '../../component/ContentSharingSelection';
import ContentUpload from '../../component/ContentUpload';
import ContentUploadHistory from '../../component/ContentUploadHistory';
import ContentReview from '../../component/ContentReview';
import PfnKey from '../../PfnKey';
import RobotAssignmentMain from '../../component/RobotAssignmentMain';
import Sidebar from '../../component/Sidebar';
import ReactUtils from '../../utils/ReactUtils';
import ContentPlayHistoryMain from '../../component/ContentPlayHistoryMain';
import DataSchoolManagement from '../../component/DataSchoolManagement';
import DataUserManagement from '../../component/DataUserManagement';
import DataRobotManagement from '../../component/DataRobotManagement';
import styled from 'styled-components';

const BodyBox = styled.div`
.main {
    display: grid;
    width: 80vw;
    border-radius: 12px;
    margin: 20px auto;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    grid-template-columns: auto 2fr;
    /* background: url("http://localhost:3000/background/grey.png"); */
    background: white;
    .rightContent{
      width: 60vw;
      margin: 0 2vw;
    }
  }
  .MainRight {
    display: flex;
    padding: 20px;
    max-width: 100%;
  }
  .footer {
    font-size: small;
    text-align: center;
    color: #fff;

    span:not(:first-child) {
      padding-left: 20px;
    }
  }
`
const Main = (props) => {
  const { action } = props
  const robotUuid = ReactUtils.useQuery().get(PfnKey.KEY_ROBOT_UUID);
  const categoryUuid = ReactUtils.useQuery().get(PfnKey.KEY_CATEGORY_UUID);

  const params = {};
  params[PfnKey.KEY_ROBOT_UUID] = robotUuid;
  params[PfnKey.KEY_CATEGORY_UUID] = categoryUuid;

  var content;
  switch (action) {
    case Action.RobotAssignment:
      content = <RobotAssignmentMain />
      break;
    
    case Action.SkillSubmission:
      content = <ContentUpload />
      break;

    case Action.SkillSubmissHistory:
      content = <ContentUploadHistory />
      break;

    case Action.SkillSharing:
      content = <ContentSharingMain />
      break;

    case Action.SkillSharingSelection:
      content = categoryUuid ? <ContentSharingSelection {...params} /> : <ContentSharingSelection />
      break;

    case Action.SkillReview:
      content = <ContentReview />
      break;

    case Action.VideoPlayHistory:
      content = <ContentPlayHistoryMain /> 
      break;

    case Action.VideoPlayHistoryRobotSelection:
      content =  robotUuid ? <ContentPlayHistoryPerRobot {...params}/> : <ContentPlayHistoryRobotSelection />
      break;

    case Action.VideoPlayHistorySkillSelection:
      content =  <ContentPlayHistorySkillSelection />
      break;

    case Action.DataSchoolManagement:
      content =  <DataSchoolManagement />
      break;

    case Action.DataUserManagement:
      content =  <DataUserManagement />
      break;

    case Action.DataRobotManagement:
      content =  <DataRobotManagement />
      break;
      
    default:
      content = <ContentMain />
      break;
  }
  const Footer = () => (
    <div className="footer">
      <span>計劃統籌及推展機構：禮賢會恩慈學校</span>
      <div>技術研發機構：香港應用科技研究院、播閃機械人有限公司 </div>
      <div>Copyright © 2023 Rhenish Church Grace School</div>
    </div>
  );
  return (
    <BodyBox>
    <div className='main'>
          <Sidebar/>
          <div className='rightContent'>
          {content}
          </div>
    </div>
    {Footer()}
    </BodyBox>
  )
}

export default Main;
