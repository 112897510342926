const PfnKey = Object.freeze({
  KEY_DATA                   : "data",
  KEY_JWT                    : "jwt",

  KEY_HEADERS                : "header",
  KEY_AUTHORIZATION          : "Authorization",
  
  KEY_ERROR_NAME             : "errorName",
  KEY_ERRORS                 : "errors",

  KEY_LOCALE                 : "locale",

  KEY_LOGIN_NAME             : "loginName",
  KEY_PASSWORD               : "password",

  KEY_ROBOT_SELECTION        : "robotSelection",
  KEY_ROBOT_UUID             : "robotUuid",
  KEY_ROLE                   : "role",
  KEY_SCHOOL_UUID            : "schoolUuid",

  KEY_STATUS                 : "status",

  KEY_VENDOR_SERIAL          : "vendorSerial",

  KEY_SCHOOL_NAME            : "schoolName",
  KEY_PICTURE_URL            : "pictureUrl",
  KEY_WATERMARK              : "watermark",

  KEY_CREATE_DATE            : "createDate",
  KEY_SKILL_NAME             : "skillName",
  KEY_CATEGORY_NAME          : "categoryName",
  KEY_CATEGORY_UUID          : "categoryUuid",

  KEY_CATEGORY_ITEM_LIST     : "categoryItemList",
  KEY_ROBOT_UUID_LIST        : "robotUuidList",
  KEY_SKILL_VIDEO            : "skillVideo",
  KEY_SKILL_DIAGRAM          : "skillDiagram",
  KEY_SKILL_DATA_JSON_STRING : "skillDataJsonString",
  KEY_SKILL_NEW_COMMENT      : "newComment",
  KEY_SKILL_MATERIAL_LIST    : "materialList",
  KEY_SKILL_MATERIAL_UUID    : "materialUuid",
  KEY_SKILL_MATERIAL_LINK    : "externalLink",

  KEY_SKILL_UUID             : "skillUuid",
  KEY_SKILL_STATUS           : "skillStatus",
  KEY_SKILL_SECTION          : "skillSection",

  KEY_PARAM_LIMIT            : "limit",
  KEY_PARAM_LOCALE           : "locale",
  KEY_PARAM_OFFSET           : "offset",
  KEY_PARAM_ROLE             : "role",
  KEY_PARAM_PARTIAL_ROBOT_NAME : "partialRobotName",
  KEY_PARAM_PARTIAL_SCHOOL_NAME  : "partialSchoolName",
  KEY_PARAM_PARTIAL_USER_NAME  : "partialUserName",
  KEY_PARAM_PARTIAL_VENDOR_SERIAL  : "partialVendorSerial",
  KEY_PARAM_NO_ROBOT         : "noRobot",
  KEY_PARAM_NO_SCHOOL        : "noSchool",
  KEY_PARAM_NO_USER          : "noUser",
  KEY_PARAM_WITH_ROBOT       : "withRobot",
  KEY_PARAM_WITH_SCHOOL      : "withSchool",
  KEY_PARAM_WITH_USER        : "withUser",

  KEY_PARAM_ROBOT_NAME       : "robotName",
  KEY_PARAM_VENDOR_SERIAL    : "vendorSerial",

  KEY_USER_DISPLAY_NAME      : "displayName",
  KEY_USER_UUID              : "userUuid",
  KEY_USER_EMAIL             : "email",

  KEY_GROUP_BY               : "groupBy"
});

export default PfnKey;
