import axios from "axios";

const client = axios.create({
   baseURL: '/api',
   timeout: 10000
})

export const request = (url, config) => {
 return new Promise((resolve)=>{
     client({url, ...config})
     .then((res)=>{
      const response =  res.data
      resolve(response)
     })
     .catch((err)=>{
      resolve(err)
     })
  })
}