import Api from "./Api.js";
import PfnKey from "../PfnKey.js";
import SkillStatus from '../backend/SkillStatus.js';
import AxiosHelper from "./AxiosHelper.js";

class SkillStatusManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new SkillStatusManager();
    }
    return this._instance;
  }

  async getSkillStatusAsync(jwt) {
    const getSkillStatusResponse =
      await AxiosHelper.axoisGetAsync(Api.SKILL_STATUS, {}, jwt);

    if (getSkillStatusResponse.errorName) {
      console.warn(`Get skill status failed (${getSkillStatusResponse.errorName})`);
    }

    return getSkillStatusResponse;
  }

  async getApprovedSkillByCategoryAsync(jwt, categoryUuid) {
    let params = {};
    params[PfnKey.KEY_SKILL_SECTION] = 'skillDiagram';
    params[PfnKey.KEY_SKILL_STATUS]  = SkillStatus.READY_READY;
    params[PfnKey.KEY_CATEGORY_UUID] = categoryUuid;

    const getReadySkillByCategoryResponse =
      await AxiosHelper.axoisGetAsync(Api.SKILLS, params, jwt);

    if (getReadySkillByCategoryResponse.errorName) {
      console.warn(`Get skill failed (${getReadySkillByCategoryResponse.errorName})`);
    }

    return getReadySkillByCategoryResponse;
  }

  async putSkillStatusAsync(jwt, skillUuid, skillStatus) {
    console.log(`put SkillStatus for : ${skillUuid}`);

    let params = {};
    params[PfnKey.KEY_SKILL_UUID] = skillUuid;
    params[PfnKey.KEY_SKILL_STATUS] = skillStatus;

    const response =
      await AxiosHelper.axoisPutAsync(Api.SKILL_STATUS, params, jwt);

    if (response.errorName) {
      console.warn(`Update skill failed (${response.errorName})`);
    }

    return response;
  }
}

export default SkillStatusManager;
