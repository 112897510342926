import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { withRouter } from "../../withRouter";
import AppRoute from "../../AppRoute";
import PfnKey from "../../PfnKey";
import ContentSharingSelection from "../ContentSharingSelection";
const SkillSharingBox = styled.div`
.title{
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 32px;
}
.selections{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  > div {
    filter: brightness(0.5) blur(0.5px) opacity(80%);
    cursor: pointer;
    transform: scale(0.85);
    transition: 0.5s;
  }
  .active {
    filter: unset;
    transform: scale(0.95);
    transition: 0.5s;
  }
  img {
    width: 100%;
  }
}
`
const ContentSharingMain = () => {
  const [item, setItem] = useState("衣");
  const [categoryUuid, setCategoryUuid] = useState("catUuidClothing")
  const showVideoList = (uuid, item) => {
    setItem(item);
    setCategoryUuid(uuid)
  };
  const TopSelect = (item) => {
    return (
      <div className="selections">
        <div
          className={item === "衣" ? "active" : ""}
          onClick={() => showVideoList("catUuidClothing", "衣")}
        >
          <img src="/06-03.png" alt="" />
        </div>
        <div
          className={item === "食" ? "active" : ""}
          onClick={() => showVideoList("catUuidFood", "食")}
        >
          <img src="/06-04.png" alt="" />
        </div>
        <div
          className={item === "住" ? "active" : ""}
          onClick={() => showVideoList("catUuidHousing", "住")}
        >
          <img src="/06-05.png" alt="" />
        </div>
        <div
          className={item === "行" ? "active" : ""}
          onClick={() => showVideoList("catUuidTransportation", "行")}
        >
          <img src="/06-06.png" alt="" />
        </div>
      </div>
    );
  };
  return (
    <SkillSharingBox>
      <h2 className="title">友校成果分享 ({item})</h2>
      {TopSelect(item)}
      {/* <TopBar />
      <BoxTemp />
      <Pagination /> */}
      <ContentSharingSelection
      categoryUuid={categoryUuid}
      />
    </SkillSharingBox>
  );
};
export default ContentSharingMain;
// class ContentSharingMain extends React.Component {

//   render() {
//     return(
//       <div className='h-100'>
//         <div className="h-100 pb-5">
//           <Container >
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center'}}>
//               <img src="sharing_main_title.png" alt="" height="250px" />
//             </div>
//             <Row className='justify-content-center' style={{ paddingRight: '100px', paddingLeft: '100px'}}>
//               <Col className='pb-5 px-1 mx-0' md={3}>
//                 <a href='#' onClick={() => this.showListSelection('catUuidClothing')}>
//                   <img src="sharing_main_catUuidClothing.png" alt="" width="100%" />
//                 </a>
//               </Col>
//               <Col className='pt-5 px-1 mx-0' md={3}>
//                 <a href='#' onClick={() => this.showListSelection('catUuidFood')}>
//                   <img src="sharing_main_catUuidFood.png" alt="" width="100%" />
//                 </a>
//               </Col>
//               <Col className='pb-5 px-0 mx-0' md={3}>
//                 <a href='#' onClick={() => this.showListSelection('catUuidHousing')}>
//                   <img src="sharing_main_catUuidHousing.png" alt="" width="100%" />
//                 </a>
//               </Col>
//               <Col className='pt-5 px-0 mx-0' md={3}>
//                 <a href='#' onClick={() => this.showListSelection('catUuidTransportation')}>
//                   <img src="sharing_main_catUuidTransportation.png" alt="" width="100%" />
//                 </a>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>
//     )
//   }

//   showListSelection(catUuid) {
//     const params = {};

//     params[PfnKey.KEY_CATEGORY_UUID] = catUuid;

//     this.props.navigate(AppRoute.SkillSharingSelection + '?' + new URLSearchParams(params));
//   }
// }

// export default withRouter(ContentSharingMain);
