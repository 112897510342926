import { configureStore } from '@reduxjs/toolkit';

import LoginStateSlice from './LoginStateSlice.js';
import SchoolStateSlice from './SchoolStateSlice.js';
import StoreState from './StoreState.js';


const reducer = {};

reducer[StoreState.LOGIN] = LoginStateSlice.reducer
reducer[StoreState.SCHOOL] = SchoolStateSlice.reducer

export default configureStore({ reducer });
