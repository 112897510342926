import React from "react";
import $ from "jquery";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import SkillManager from "../../backend/SkillManager";
import RobotManager from "../../backend/RobotManager";
import SkillStatusManager from "../../backend/SkillStatusManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import SchoolStateSlice from "../../redux/SchoolStateSlice";
import StoreState from "../../redux/StoreState";
import SkillStatus from "../../backend/SkillStatus";
import QrCodeUtils from "../../utils/QrcodeUtils";
import PfnKey from "../../PfnKey";
import {request} from "../../request"
import { Table, Modal, Radio, Button , message} from "antd";
import { FormOutlined, CheckCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import "./index.css";
const ReviewTable = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #f39800;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #f6ede4;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
  .table-operate {
    .delete-btn {
      background: rgb(232, 56, 40);
      color: #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      border: unset;
      border-radius: 6px;
      transition: 0.5s;
      cursor: pointer;
      height: 24px;
      width: 50px;
      font-size: 14px;
    }
    .delete-btn:hover{
      transform: scale(1.1);
    }
  }
`;
const Forcebox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .force_list {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin: 10px 0;
    gap: 10px;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const UploadStep = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .uploadbox {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #fff;
    .step {
      position: absolute;
      padding: 5px;
      background: rgba(133, 51, 51, 0.2);
    }
  }
`;
class ContentReview extends React.Component {
  constructor() {
    super();

    this.commentLength = 0;
    this.state = {
      show: false,
      disabledCommentText: true,
      disabledSubmit: true,
      disabledUpload: true,
      hiddenQrCodeError: true,
      hiddenVideoLinkError: true,
      disabledUploadQrCode: true,
      skillCategories: {},
      skillRecordList: [],
      editSkill: null,
      skillQrCodeFile: null,
      skillQrCodeImg: null,
      checkSkillCategory: [],
    };
    this.JWT = null;
  }

  componentDidMount() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this.JWT = jwt;
    this._getSkillRecords(jwt);
    this._getSkillCategories(jwt);
    // if($("#submitButton")[0].disable){
    //   $("#submitButton")[0].style.background = "#B6CC9B"
    // }
    // if( $("#approveButton")[0].disable){
    //   $("#approveButton")[0].style.background = "#69bfe7"
    // }
  }

  onCheckboxClick(e) {
    e.preventDefault();
    return false;
  }

  getOptions(catId, name, values) {
    const editSkill = this.state.editSkill;
    const optionId = catId + "-";
    var isOptionChecked = this.state.checkSkillCategory.includes(catId);
    var categoryInput = [];

    categoryInput.push(
      <div className="form-check" key={catId}>
        <input
          id={catId}
          name={catId}
          type="checkbox"
          defaultChecked={isOptionChecked}
          onClick={(e) => this.onCheckboxClick(e)}
        ></input>
        <label
          className="modal-options"
          style={{ padding: "2px", display: "inline-block" }}
        >
          {name}
        </label>
      </div>
    );

    for (var v in values) {
      var checked = false;
      if (
        editSkill !== null &&
        editSkill.categoryItemList.includes(values[v].uid)
      ) {
        checked = true;
      }

      categoryInput.push(
        <div className="form-check" key={v}>
          <input
            className="modal-custom-checkbox"
            id={optionId + v}
            name={values[v].uid}
            type="checkbox"
            defaultChecked={checked}
            onClick={(e) => this.onCheckboxClick(e)}
          ></input>
          &nbsp;
          <label
            className="modal-options"
            style={{ padding: "2px", display: "inline-block" }}
          >
            {values[v].name}
          </label>
        </div>
      );
    }

    return (
      <Col xs={3} key={catId}>
        {categoryInput}
      </Col>
    );
  }

  statusStringFormatter(cell, row, rowIndex) {
    return (
      <a
        href="\#"
        role="button"
        onClick={(e) =>
          this.handleEditAction(e, cell.skillUuid, cell.skillStatus)
        }
      >
        {cell.statusNameToInternal}
      </a>
    );
  }

  statusStringSorting(a, b, order) {
    if (order === "desc") {
      return a.statusNameToInternal.localeCompare(b.statusNameToInternal);
    } else {
      return b.statusNameToInternal.localeCompare(a.statusNameToInternal);
    }
  }
  deleteSkill(skillUuid, data) {
    const onOK = async() => {
     await request("/delete/skill", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.JWT}`,
        },
        data: {
          skillUuid: skillUuid,
        },
      }).then((res) => {
        if (res.code == 200) {
          message.success("Success!");
          this._getSkillRecords(this.JWT)
        } else {
          message.error(res.msg);
        }
      });
    }
    Modal.confirm({
      title: '刪除生活技能影片',
      width: 400,
      content: (
        <div style={{marginTop: '20px', fontSize: '16px'}}>
          <div>
            確定刪除生活技能影片<span style={{color: 'red', fontWeight: 600}}>{data.name}</span>?
          </div>
        </div>
      ),
      onOk: onOK
    })

  }
  async _getSkillRecords(jwt) {
    var skillList = [];
    const skillStatusResponse =
      await SkillStatusManager.getInstance().getSkillStatusAsync(jwt);

    for (var skill of skillStatusResponse.data) {
      skillList.push({
        id: skill.skillUuid,
        name: skill.skillName,
        status: skill.skillStatus,
        statusString: skill,
        updateTime: new Date(skill.updateTime).toLocaleString(i18n.language),
        statusNameToInternal: skill.statusNameToInternal,
      });
    }

    this.setState({
      skillRecordList: skillList,
    });
  }

  async _getSkill(jwt, skillId, skillStatus) {
    const skillResponse = await SkillManager.getInstance().getSkillAsync(
      jwt,
      skillId
    );

    const skill = skillResponse.data;
    var skillCoverFile = null;
    var skillQrCodeFile = null;
    var skillVideoFiles = [];
    var skillVideoExtLinks = [];
    var skillVideoUuid = [];
    for (var section of skill.sectionList) {
      if (
        section.sectionName === "skillDiagram" &&
        section.materialList.length > 0
      ) {
        skillCoverFile = section.materialList[0].internalPath;
      } else if (section.sectionName === "skillVideo") {
        for (var material of section.materialList) {
          skillVideoFiles[material.partsSeq] = material.internalPath;
          skillVideoExtLinks[material.partsSeq] = material.externalLink;
          skillVideoUuid[material.partsSeq] = material.materialUuid;

          if (material.partsSeq === 0) {
            skillQrCodeFile =
              material.qrFilePath === "" ? null : material.qrFilePath;
          }
        }
      }
    }

    const checkCategory = [];
    const map = this.state.skillCategories;
    Object.keys(map).forEach((key) => {
      for (var item of map[key].items) {
        if (skill.categoryItemList.includes(item.uid)) {
          if (!checkCategory.includes(map[key].uid)) {
            checkCategory.push(map[key].uid);
          }
        }
      }
    });
    this.setState({
      disabledCommentText: skillStatus === SkillStatus.READY_READY,
      disabledSubmit: skillStatus === SkillStatus.SUCCESS_SUCCESS,
      disabledUpload:
        skillStatus !== SkillStatus.SUCCESS_SUCCESS &&
        skillStatus !== SkillStatus.SUCCESS_CDN,
      disabledUploadQrCode: true,
      editSkill: {
        id: skillId,
        name: skill.skillName,
        commentList: skill.commentList,
        categoryItemList: skill.categoryItemList,
        robotUuidList: skill.robotUuidList,
        skillCoverFile: skillCoverFile,
        skillQrCodeFile: skillQrCodeFile,
        skillVideoFiles: skillVideoFiles,
        skillVideoExtLinks: skillVideoExtLinks,
        skillVideoUuid: skillVideoUuid,
        skillStatus: skillStatus,
      },
      skillQrCodeImg: skillQrCodeFile,
      checkSkillCategory: checkCategory,
      hiddenVideoLinkError: true,
      show: true,
    });
  }

  handleModalHide = () => {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this._getSkillRecords(jwt);

    this.setShow(false);
  };

  handleEditAction = (e, sid, status) => {
    e.preventDefault();
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this._getSkill(jwt, sid, status);
  };

  async _getSkillCategories(jwt) {
    var categoryArray = {};
    const schoolInfoResponse =
      await SkillManager.getInstance().getSkillCategoriesAsync(jwt);
    if (Object.keys(schoolInfoResponse.data).length > 1) {
      schoolInfoResponse.data.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });
    }

    for (var category of schoolInfoResponse.data) {
      const name = category.categoryName;
      const itemList = category.itemList;
      itemList.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });

      var data = [];
      for (var item of itemList) {
        data.push({
          uid: item.itemUuid,
          name: item.itemName,
        });
      }
      categoryArray[name] = {
        uid: category.categoryUuid,
        items: data,
      };
    }

    this.setState({
      skillCategories: categoryArray,
    });
  }

  async _getRobots(jwt) {
    const getRobotsResponse = await RobotManager.getInstance().getRobotsAsync(
      jwt,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "NORMAL",
      null,
      null
    );
    const robotData = getRobotsResponse.data;

    this.robotList = robotData;
  }
  RadioGroupChange(value, key) {}
  renderSkillCategory() {
    const { checkSkillCategory, editSkill } = this.state;
    const imgObj = {
      衣: "06-03.png",
      食: "06-04.png",
      住: "06-05.png",
      行: "06-06.png",
    };
    const map = this.state.skillCategories;
    const checkBoxs = Object.keys(map).map((key) => {
      const option = [];
      map[key].items.forEach((item) => {
        option.push({
          label: item.name,
          value: item.uid,
        });
      });
      let RadioVal = "";
      if (map[key].uid === checkSkillCategory[0])
        RadioVal = editSkill.categoryItemList[0];
      return (
        <div key={map[key].uid} className="force_list">
          <div>
            <img src={imgObj[key]} style={{ width: "100%" }} />
          </div>
          <Radio.Group
            options={option}
            onChange={(e) =>
              this.RadioGroupChange(e.target.value, map[key].uid)
            }
            value={RadioVal}
            style={{ width: "200px", display: "grid" }}
            disabled
          />
        </div>
      );
    });
    return checkBoxs;
  }

  renderCommentText() {
    var commentString = [];
    const editSkill = this.state.editSkill;
    if (editSkill != null && "commentList" in editSkill) {
      const comments = editSkill.commentList;
      if (comments != null) {
        comments.sort(function (a, b) {
          return a.pageSeq > b.pageSeq;
        });

        var i = 0;
        for (var comment of comments.reverse()) {
          var content = [];
          var subStrings = comment.comment.split("\n");
          var len = 1;
          for (var ss of subStrings) {
            content.push(ss);
            if (len !== subStrings.length) {
              content.push(<br key={"c" + i++} />);
            }
            len++;
          }
          commentString.push(
            <div
              className="form-control border rounded mb-1"
              id="comment-history"
              key={"c" + i++}
            >
              <Trans>CommentAt</Trans>
              {new Date(comment.createDate).toLocaleString(i18n.language) + ","}
              <div style={{ padding: "4px", paddingLeft: "1em" }}>
                {content}
              </div>
            </div>
          );
        }
      }
    }
    this.commentLength = 0;
    return commentString;
  }

  handleCommentText(event) {
    var text = event.target.value;
    if (text !== null && text.length <= this.commentLength) {
      event.target.value = event.target.defaultValue;

      if (!this.state.disabledSubmit) {
        this.setState({
          disabledSubmit: true,
        });
      }
    } else {
      if (this.state.disabledSubmit) {
        this.setState({
          disabledSubmit: false,
        });
      }
    }
  }

  handleClearQrCodeImage(e, id) {
    e.stopPropagation();

    $("#qrCodePreview").attr("hidden", true);
    $("#qrCodeHint").removeAttr("hidden");
    // clear previous upload QR Code image
    $("#" + id).val("");

    this.setState({
      skillQrCodeFile: null,
      skillQrCodeImg: null,
      hiddenQrCodeError: true,
      disabledUploadQrCode: false,
    });
  }

  handleChangeQrCodeImage(e) {
    if (e.target.files[0] !== undefined) {
      $("#qrCodePreview").removeAttr("hidden");
      $("#qrCodeHint").attr("hidden", true);

      const imagefile = e.target.files[0];
      QrCodeUtils.decodeImageSafeAsync(imagefile).then((result) => {
        if (result != null) {
          this.setState({
            skillQrCodeFile: imagefile,
            skillQrCodeImg: URL.createObjectURL(imagefile),
            hiddenQrCodeError: true,
            disabledUploadQrCode: false,
          });
        } else {
          $("#qrCodePreview").attr("hidden", true);
          $("#qrCodeHint").removeAttr("hidden");

          this.setState({
            skillQrCodeFile: null,
            skillQrCodeImg: null,
            hiddenQrCodeError: false,
            disabledUploadQrCode: true,
          });
        }
      });
    }
  }

  setShow(s) {
    this.setState({ show: s });
  }

  tableRowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: rowIdx % 2 === 0 ? "white" : "var(--table-bg-pink)",
      borderTop: "0px solid white",
      borderBottom: "0px solid white",
      height: "40px",
    };
  }

  loadVideos() {
    var $source = $("#fullVideoPreview");
    if ($source[0].src !== "") {
      $source.parent()[0].load();
    }
  }

  // updateUploadButton() {
  //   const editSkill = this.state.editSkill;
  //   if (!this.state.disabledUpload && editSkill !== null) {
  //     var i = 0,
  //       enableUpload = true;
  //     for (var video in editSkill.skillVideoFiles) {
  //       const videoLink = $("#step" + i + "VideoLink").val();
  //       if (videoLink === "") {
  //         enableUpload = false;
  //       }
  //       i++;
  //     }

  //     if (enableUpload) {
  //       this.setState({
  //         disabledUpload: false,
  //       });
  //     }
  //   }
  // }
  renderVideoSteps = () => {
    const { editSkill } = this.state;
    const skillVideoFiles =
      editSkill != null ? editSkill.skillVideoFiles : null;
    const n = 8;
    return (
      <UploadStep>
        {[...Array(n)].map((elementInArray, index) => {
          const indexSource = index + 1;
          return (
            <div className="uploadbox" key={index}>
              <div className="step">
                {" "}
                <Trans>{`UploadMovieStep${indexSource}`}</Trans>
              </div>
              {!(
                skillVideoFiles !== null && skillVideoFiles.length > indexSource
              ) && <img src="img_03.png" style={{ width: "250px" }} />}
              <video
                id="step1VideoView"
                width="130px"
                controls
                hidden={
                  !(
                    skillVideoFiles !== null &&
                    skillVideoFiles.length > indexSource
                  )
                }
              >
                <source
                  id="step1VideoPreview"
                  src={
                    skillVideoFiles !== null &&
                    skillVideoFiles.length > indexSource
                      ? skillVideoFiles[indexSource]
                      : ""
                  }
                />
              </video>
            </div>
          );
        })}
      </UploadStep>
    );
  };
  render() {
    const schoolName = SchoolStateSlice.getSchoolName(
      this.props[StoreState.SCHOOL]
    );
    const skillList = this.state.skillRecordList;
    const editSkill = this.state.editSkill;
    const skillCoverImg = editSkill != null ? editSkill.skillCoverFile : null;
    const skillVideoFiles =
      editSkill != null ? editSkill.skillVideoFiles : null;
    const skillVideoLinks =
      editSkill != null ? editSkill.skillVideoExtLinks : null;
    const hiddenPrevComment =
      editSkill != null && editSkill.commentList != null
        ? editSkill.commentList.length === 0
        : true;
    const antdColumns = [
      {
        title: "生活技能名稱",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "日期及時間",
        dataIndex: "updateTime",
        key: "updateTime",
      },
      {
        title: "進度",
        dataIndex: "statusNameToInternal",
        key: "statusNameToInternal",
        render: (value, item) => {
          if (value === "批改") {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    background: "#1c971c",
                    color: "#fff",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                    border: "unset",
                    borderRadius: "6px",
                    transition: "0.5s",
                    cursor: "pointer",
                    height: "24px",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                    padding: "0 10px",
                  }}
                  onClick={(e) =>
                    this.handleEditAction(e, item.id, item.status)
                  }
                  icon={<FormOutlined className="btnIcon" />}
                >
                  {value}
                </Button>
              </div>
            );
          } else if (
            value === "已批改" ||
            value === "審批成功" ||
            value === "已上載至Youku"
          ) {
            return (
              <div
                onClick={(e) => this.handleEditAction(e, item.id, item.status)}
              >
                {value}
              </div>
            );
          } else if (value === "已上架") {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  icon={<CheckCircleOutlined />}
                  style={{
                    background: "#f6ede4",
                    color: "black",
                    boxShadow: "none",
                    border: "unset",
                    borderRadius: "6px",
                    transition: "0.5s",
                    cursor: "pointer",
                    height: "24px",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                    padding: "0 10px",
                  }}
                  onClick={(e) =>
                    this.handleEditAction(e, item.id, item.status)
                  }
                >
                  {value}
                </Button>
              </div>
            );
          }
        },
      },
      {
        title: "操作",
        dataIndex: "id",
        render: (value, data) => (
          <div className="table-operate">
            <button className="delete-btn" onClick={() => this.deleteSkill(value, data)}>刪除</button>
          </div>
        ),
      },
    ];
    return (
      <div className="h-100">
        <Modal
          id="uploadModal"
          size="xl"
          open={this.state.show}
          onOk={() => {
            this.loadVideos();
          }}
          footer={null}
          width={1200}
          style={{ top: 0 }}
          wrapClassName="recodeReviewModal"
          destroyOnClose
          onCancel={() => {
            this.handleModalHide();
          }}
          title={
            <div style={{ fontSize: "32px" }}>
              <Trans>SkllReviewModalTitle</Trans>
            </div>
          }
        >
          <div className="modal-body">
            <div className="pageTitle">
              <Trans>UploadSkillName</Trans>
            </div>
            <input
              id="inputSkillName"
              className="inputSkill"
              type="text"
              value={editSkill != null ? editSkill.name : ""}
              onChange={() => {
                return false;
              }}
            ></input>

            <div className="pageTitle">
              <Trans>UploadSkillCategory</Trans>
            </div>

            <Forcebox>{this.renderSkillCategory()}</Forcebox>

            {/* <div className="pageTitle">
              <Trans>UploadSkillVideo</Trans>
            </div>
            <div>
              {!(skillVideoFiles !== null && skillVideoFiles.length > 0) && (
                <img src="img_03.png" style={{ width: "250px" }} />
              )}
              <video
                id="fullVideoView"
                width="140px"
                controls
                hidden={
                  !(skillVideoFiles !== null && skillVideoFiles.length > 0)
                }
              >
                <source
                  id="fullVideoPreview"
                  src={
                    skillVideoFiles !== null && skillVideoFiles.length > 0
                      ? skillVideoFiles[0]
                      : ""
                  }
                />
              </video>
            </div> */}
            <div className="pageTitle">
              <Trans>UploadStepsHints</Trans>
            </div>
            {this.renderVideoSteps()}
            <div>
              <div className="pageTitle">
                <Trans>UploadSkillImage</Trans>
              </div>
              <div className="uploadstep">
                <div className="uploadbox">
                  <img
                    src={skillCoverImg || "img_03.png"}
                    alt="img"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="pageTitle">
                <Trans>UploadSkillReviewComment</Trans>
              </div>
              <div
                style={{ maxHeight: "200px", overflowY: "auto" }}
                id="comment-history"
                hidden={hiddenPrevComment}
              >
                {this.renderCommentText()}
              </div>
              <textarea
                className="form-control"
                id="comment-text"
                rows="5"
              ></textarea>
              <div className="submit-opinion">
                <button
                  id="submitButton"
                  className="btn-left"
                  onClick={() => this.submitComment()}
                  disabled={this.state.disabledCommentText}
                >
                  <Trans>SubmitComment</Trans>
                </button>
                <button
                  id="approveButton"
                  className="btn-right btn-left"
                  onClick={() => this.approvedSkill()}
                  disabled={
                    editSkill == null
                      ? false
                      : !(editSkill.skillStatus === SkillStatus.SUBMIT_REVIEW)
                  }
                >
                  <Trans>SubmitApproved</Trans>
                </button>
              </div>
            </div>

            <div>
              <div className="pageTitle">
                <Trans>UploadSkillQrCode</Trans>
              </div>

              <button
                className="upload-two-code"
                disabled={
                  editSkill == null
                    ? false
                    : editSkill.skillStatus === SkillStatus.READY_READY
                }
                onClick={(e) => {
                  $("#qrCodeImageFile").trigger("click");
                }}
              >
                <div className="upload-content-wrap">
                  <span
                    className="close"
                    hidden={
                      this.state.skillQrCodeImg === null ||
                      (editSkill != null &&
                        editSkill.skillStatus === SkillStatus.READY_READY)
                    }
                    onClick={(e) =>
                      this.handleClearQrCodeImage(e, "qrCodeImageFile")
                    }
                  >
                    &times;
                  </span>
                  <div className="file-upload-container">
                    <div className="file-upload-hint">
                      <Trans>UploadQrCode</Trans>
                    </div>
                    <img
                      id="qrCodePreview"
                      src={this.state.skillQrCodeImg}
                      alt="img"
                      style={{
                        padding: "0px",
                        margin: "0px",
                        width: "140px",
                      }}
                      hidden={this.state.skillQrCodeImg === null}
                    />
                    <FontAwesomeIcon
                      id="qrCodeHint"
                      width="130px"
                      icon={faCloudArrowUp}
                      className="fa-5x"
                      hidden={this.state.skillQrCodeImg !== null}
                    />
                  </div>
                </div>
              </button>
              <Form.Control
                type="file"
                id="qrCodeImageFile"
                accept=".jpeg, .jpg, .png"
                onChange={this.handleChangeQrCodeImage.bind(this)}
                hidden
              />
            </div>

            <div className="qrCodeBtnBox">
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
                hidden={this.state.hiddenQrCodeError}
              >
                <Trans>InvalidQrCode</Trans>
              </span>

              <button
                id="uploadQrCodeButton"
                className="submit-tow-code"
                onClick={() => this.uploadQrCode()}
                disabled={this.state.disabledUploadQrCode}
              >
                <Trans>UpdateQrCode</Trans>
              </button>
            </div>

            {/* <div className="pageTitle">
              <Trans>UploadYoukuLinks</Trans>
            </div>

            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieFull</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step0VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 0
                      ? skillVideoLinks[0]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep1</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step1VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length === 1
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 1
                      ? skillVideoLinks[1]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep2</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step2VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 2
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 2
                      ? skillVideoLinks[2]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep3</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step3VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 3
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 3
                      ? skillVideoLinks[3]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep4</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step4VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 4
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 4
                      ? skillVideoLinks[4]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep5</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step5VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 5
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 5
                      ? skillVideoLinks[5]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep6</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step6VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 6
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 6
                      ? skillVideoLinks[6]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep7</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step7VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 7
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 7
                      ? skillVideoLinks[7]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row>
            <Row style={{ height: "40px" }}>
              <Col xs={2}></Col>
              <Col xs={1}>
                <div
                  className="modal-field-hint rounded-pill"
                  style={{
                    textAlign: "center",
                    background: "var(--modal-title-pink)",
                    color: "white",
                    padding: "6px",
                  }}
                >
                  <Trans>UploadMovieStep8</Trans>
                </div>
              </Col>
              <Col xs={9}>
                <input
                  id="step8VideoLink"
                  className="modal-field-hint form-control rounded-pill"
                  type="text"
                  disabled={
                    skillVideoFiles !== null && skillVideoFiles.length <= 8
                  }
                  defaultValue={
                    skillVideoLinks !== null && skillVideoLinks.length > 8
                      ? skillVideoLinks[8]
                      : ""
                  }
                  onChange={() => this.updateUploadButton()}
                ></input>
              </Col>
            </Row> */}
            <Row className="pt-3 pb-3">
              {/* <Col xs={3}></Col>
              <Col xs={5}>
                <div
                  className="modal-field-error"
                  hidden={this.state.hiddenVideoLinkError}
                >
                  <Trans>InvalidVideoLink</Trans>
                </div>
              </Col> */}
              <Col xs={2}>
                <button
                  id="saveButton"
                  className="review-modal-btn rounded-pill border-light"
                  onClick={() => this.uploadVideoLink()}
                  disabled={this.state.disabledUpload}
                >
                  <Trans>SaveAndMakeAvailable</Trans>
                </button>
              </Col>
              <Col xs={2}>
                <button
                  id="saveButton"
                  className="review-modal-btn rounded-pill border-light"
                  onClick={() => this.disableSkill()}
                  disabled={
                    editSkill == null
                      ? false
                      : !(editSkill.skillStatus === SkillStatus.READY_READY)
                  }
                >
                  <Trans>DisableSkill</Trans>
                </button>
              </Col>
            </Row>
          </div>
        </Modal>
        <div className="h-100">
          <div className="skillReviewTitle">
            <Trans>NavSkillReview</Trans>
          </div>
          <ReviewTable>
            <Table dataSource={skillList} columns={antdColumns} />
          </ReviewTable>
        </div>
      </div>
    );
  }

  async submitComment(e) {
    // #B6CC9B
    //#69bfe7
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    const commentString = $("#comment-text").val();
    if (commentString.length <= this.commentLength) {
      return true;
    }
    const newComment = commentString.slice(this.commentLength) + "\n";

    // show status message and disabled buttons
    $("#submitButton").attr("disabled", true);
    // $("#submitButton")[0].style.background = "#B6CC9B"
    $("#approveButton").attr("disabled", true);
    $("#skillSubmissionStatus").removeAttr("hidden");
    const editSkill = this.state.editSkill;
    const putSkillResponse =
      await SkillManager.getInstance().putSkillCommentAsync(
        jwt,
        editSkill.id,
        newComment
      );

    const updateStatusResponse =
      await SkillStatusManager.getInstance().putSkillStatusAsync(
        jwt,
        editSkill.id,
        SkillStatus.REWORK_REVIEW_DONE
      );

    // reset buttons and remove status message
    $("#approveButton").attr("disabled", false);
    $("#skillSubmissionStatus").attr("hidden", true);

    if (putSkillResponse.errorName) {
      alert(putSkillResponse.errorName);
    } else if (updateStatusResponse.errorName) {
      alert(updateStatusResponse.errorName);
    }
  }

  async approvedSkill(e) {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    // show status message and disabled buttons
    $("#submitButton").attr("disabled", true);
    $("#approveButton").attr("disabled", true);
    // $("#submitButton")[0].style.background = "#B6CC9B";
    // $("#approveButton")[0].style.background = "#69bfe7"
    $("#skillSubmissionStatus").removeAttr("hidden");

    const editSkill = this.state.editSkill;
    const updateStatusResponse =
      await SkillStatusManager.getInstance().putSkillStatusAsync(
        jwt,
        editSkill.id,
        SkillStatus.SUCCESS_SUCCESS
      );

    // reset buttons and remove status message
    $("#skillSubmissionStatus").attr("hidden", true);

    if (!updateStatusResponse.errorName) {
      this.setState({
        disabledUpload: false,
      });
    } else {
      alert(updateStatusResponse.errorName);
    }
  }

  async uploadQrCode() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    const editSkill = this.state.editSkill;
    const putSkillResponse = await SkillManager.getInstance().putQrCodeAsync(
      jwt,
      editSkill.id,
      this.state.skillQrCodeFile != null ? this.state.skillQrCodeFile : ""
    );

    if (!putSkillResponse.errorName) {
      this.setState({
        disabledUploadQrCode: true,
      });
    } else if (putSkillResponse.errorName) {
      alert(putSkillResponse.errorName);
    }
  }

  async uploadVideoLink() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    const editSkill = this.state.editSkill;
    var materialList = [];
    for (var videoUuid in editSkill.skillVideoUuid) {
      // const inputLink = $("#step" + videoUuid + "VideoLink").val();
      const jsonMaterialObject = {};
      jsonMaterialObject[PfnKey.KEY_SKILL_MATERIAL_UUID] =
        editSkill.skillVideoUuid[videoUuid];
      jsonMaterialObject[PfnKey.KEY_SKILL_MATERIAL_LINK] = "";

      // if (inputLink.trim().length > 0) {
      //   materialList.push(jsonMaterialObject);
      // }
    }

    // if (materialList.length !== editSkill.skillVideoFiles.length) {
    //   this.setState({
    //     hiddenVideoLinkError: false,
    //   });
    //   return;
    // }
    // show status message and disabled buttons
    $("#skillSubmissionStatus").removeAttr("hidden");
    if (!this.state.hiddenVideoLinkError) {
      this.setState({
        hiddenVideoLinkError: true,
      });
    }

    const putSkillResponse =
      await SkillManager.getInstance().putSkillMaterialListAsync(
        jwt,
        editSkill.id,
        materialList,
        editSkill.skillQrCodeFile === this.state.skillQrCodeImg
          ? null
          : this.state.skillQrCodeFile != null
          ? this.state.skillQrCodeFile
          : ""
      );

    const updateStatusResponse =
      await SkillStatusManager.getInstance().putSkillStatusAsync(
        jwt,
        editSkill.id,
        SkillStatus.READY_READY
      );

    // reset buttons and remove status message
    $("#skillSubmissionStatus").attr("hidden", true);

    if (!putSkillResponse.errorName && !updateStatusResponse.errorName) {
      this.handleModalHide();
    } else if (putSkillResponse.errorName) {
      alert(putSkillResponse.errorName);
    } else if (updateStatusResponse.errorName) {
      alert(updateStatusResponse.errorName);
    }
  }

  async disableSkill() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    const editSkill = this.state.editSkill;
    const updateStatusResponse =
      await SkillStatusManager.getInstance().putSkillStatusAsync(
        jwt,
        editSkill.id,
        SkillStatus.SUCCESS_CDN
      );

    if (!updateStatusResponse.errorName) {
      this.setState({
        disabledUpload: false,
        disabledCommentText: false,
        disabledUploadQrCode: false,
      });
    }

    this._getSkill(jwt, editSkill.id, SkillStatus.SUCCESS_CDN);
  }
}

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(ContentReview);
