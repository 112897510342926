import React, { useState } from "react";
import { connect } from "react-redux";

// import '../App.css';
import AppRoute from "../../AppRoute";
import LoginManager from "../../backend/LoginManager";
import PfnKey from "../../PfnKey";
import LoginStateSlice from "../../redux/LoginStateSlice";
import StoreState from "../../redux/StoreState";
import { withRouter } from "../../withRouter";
import styled from "styled-components";
import { message } from "antd";
const LoginStyled = styled.div`
  .login {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background: url("http://localhost:3000/background/grey.png");
    justify-content: space-evenly;
    background: white;
    img {
      max-width: 500px;
    }
  }
  .login_form {
    width: 100%;
    margin-top: 20px;
    position: relative;

    input {
      font-family: inherit;
      width: 100%;
      outline: none;
      background-color: #fff;
      border-radius: 4px;
      border: none;
      display: block;
      padding: 0.9rem 0.7rem;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      font-size: 17px;
      color: #4a4a4a;
      text-indent: 40px;
    }

    .form-field {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 1rem;
      position: relative;

      &:before {
        font-size: 20px;
        position: absolute;
        left: 15px;
        top: 17px;
        color: #888888;
        content: " ";
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .user::before {
      background-image: url(user-icon.png);
      width: 20px;
      height: 20px;
      top: 15px;
    }

    .pwd::before {
      background-image: url(lock-icon.png);
      width: 16px;
      height: 16px;
    }

    .btn {
      width: 100%;
      margin: 0 auto;
      padding: 10px 30px;
      text-align: center;
      background: #00592a;
      color: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      font-size: 17px;

      &:nth-child(2) {
        margin-left: 20px;
        background: #8b2600;
      }
    }

    .btnset {
      display: flex;
    }
  }
  .form-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;

    &:before {
      font-size: 20px;
      position: absolute;
      left: 15px;
      top: 17px;
      color: #888888;
      content: " ";
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .footer{
    font-size: small;
    text-align: center;
    color: #fff;
  }
`;
const Login = (props) => {
  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");

  const userNameChange = (event) => {
    setUserName(event.target.value);
  };
  const passwordChange = (event) => {
    setPassWord(event.target.value);
  };

  const userLoginCheck = async (username, password) => {
    const loginMgr = LoginManager.getInstance();

    const loginResponse = await loginMgr.loginAsync(username, password);

    const error = loginResponse.errorName;

    let jwt = null;
    let role = null;
    let schoolUuid = null;

    if (!error) {
      jwt = loginResponse.data[PfnKey.KEY_JWT];
      role = loginResponse.data[PfnKey.KEY_ROLE];
      schoolUuid = loginResponse.data[PfnKey.KEY_SCHOOL_UUID];
    }

    props.dispatch(
      LoginStateSlice.slice.actions.setState(
        LoginStateSlice.createState(jwt, role, schoolUuid)
      )
    );

    if (!error) {
      message.success('登錄成功!')
      props.navigate(AppRoute.Main);
    } else {
      message.error('帳號名稱有錯誤/密碼錯誤')
    }
  };
  const submit = () => {
    userLoginCheck(userName, passWord);
  };
  const forgetPasswordClick = () => {
    props.navigate(AppRoute.ForgetPassword);
  };
  const Footer = () => (
    <div className="footer">
      <span>計劃統籌及推展機構：禮賢會恩慈學校</span>
      <div>技術研發機構：香港應用科技研究院、播閃機械人有限公司 </div>
      <div>Copyright © 2023 Rhenish Church Grace School</div>
    </div>
  );
  return (
    <LoginStyled>
      <div className="login">
        <img src="./App_logo with text_01.png" alt="" />

        <div className="login_form ">
          <div className="form-field user">
            <input
              type="email"
              placeholder="登入名稱"
              required
              onChange={userNameChange}
              value={userName}
            />
          </div>
          <div className="form-field pwd">
            <input
              type="password"
              placeholder="請輸入密碼"
              required
              onChange={passwordChange}
              value={passWord}
            />{" "}
          </div>
          <div className="form-field">
            <button className="btn" onClick={submit}>
              登入
            </button>
            <button
              to="/ForgotPassword"
              className="btn"
              onClick={forgetPasswordClick}
            >
              忘記密碼
            </button>
          </div>
        </div>
  
      </div>
      {Footer()}
    </LoginStyled>
  );
};
// class Login extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       username: "",
//       password: "",
//       errorName: "",
//     }
//   }

//   onFormSubmit(event) {
//     console.log('onFormSubmit', event)
//     event.preventDefault();
//     this._userLoginAsync(this.state.username, this.state.password);
//   }

//   onPasswordChange(event) {
//     console.log('onPasswordChange', event)
//     this.setState({password: event.target.value});

//     if (this.state.errorName !== "") {
//       this.setState({errorName: ""})
//     }
//   }

//   onUsernameChange(event) {
//     console.log('onUsernameChange', event)
//     this.setState({username: event.target.value});

//     if (this.state.errorName !== "") {
//       this.setState({errorName: ""})
//     }
//   }

//   render() {
//     const bgPath = `images/login_bg.png`;
//     const styles = {
//       container: {
//         backgroundImage: `url(${bgPath})`,
//         backgroundPosition: 'center',
//         backgroundSize: 'cover',
//         backgroundRepeat: 'no-repeat',
//         width: '100vw',
//         height: '100vh'
//       }
//     };

//     const form = this._createForm();

//     return (
//       <Container fluid style={styles.container}>
//         <Row className='justify-content-center align-items-center' style={{height: '100vh'}}>
//           <Col md={6}>
//             {form}
//           </Col>
//         </Row>
//       </Container>
//     );
//   }

//   _createForm() {
//     return (
//       <React.Fragment>
//     <Form onSubmit={(evt) => {this.onFormSubmit(evt)}}>
//       <Stack gap={3}>
//         <Form.Group as={Row} className="justify-content-center">
//           <Col className="mb-5" style={{display: "flex", justifyContent: "center"}}>
//             [Logo Place Holder]
//           </Col>
//         </Form.Group>
//         <Form.Group as={Row} className="justify-content-center">
//           <Form.Label column md={2} style={{fontWeight: "bold"}}>
//             <div className="modal-field-name">
//               <Trans>Username</Trans>
//             </div>
//           </Form.Label>
//           <Col md={8}>
//             <Form.Control type="text"
//               className="modal-text-input form-control rounded-pill"
//               value={this.state.username}
//               onChange={(evt) => {this.onUsernameChange(evt)}}/>
//           </Col>
//         </Form.Group>
//         <Form.Group as={Row} className="justify-content-center">
//           <Form.Label column md={2} style={{fontWeight: "bold"}}>
//             <div className="modal-field-name">
//               <Trans>Password</Trans>
//             </div>
//           </Form.Label>
//           <Col md={8}>
//             <Form.Control type="password"
//               className="modal-text-input form-control rounded-pill"
//               value={this.state.password}
//               onChange={(evt) => {this.onPasswordChange(evt)}}/>
//           </Col>
//         </Form.Group>
//         <Form.Group as={Row} className="justify-content-center">
//           <Form.Label column md={7} style={{fontWeight: "bold", textAlign: "center", color: "red"}}>
//             <span>{this.state.errorName}11asdada1</span>
//           </Form.Label>
//           <Col md={3} style={{display: "flex", justifyContent: "right"}}>
//             <Button variant="primary" type="submit"
//               className="logout-btn rounded-pill border-light">
//               <Trans>Login</Trans>
//             </Button>
//           </Col>
//         </Form.Group>
//         <Form.Group as={Row} className="justify-content-center">
//           <Form.Label column md={2} style={{fontWeight: "bold"}}/>
//           <Col md={8} style={{display: "flex", justifyContent: "right", fontWeight: "bold"}}>
//             <Button variant="link" onClick={() => this._onForgetPasswordClick()}>
//               <Trans>ForgetPassword</Trans>
//             </Button>
//           </Col>
//         </Form.Group>
//       </Stack>
//     </Form>
//     </React.Fragment>
//     );
//   }

//   _onForgetPasswordClick() {
//     this.props.navigate(AppRoute.ForgetPassword);
//   }

//   // eslint-disable-next-line no-unused-vars
//   async _userLoginAsync(username, password) {
//     const loginMgr = LoginManager.getInstance();

//     const loginResponse = await loginMgr.loginAsync(this.state.username, this.state.password);

//     const error = loginResponse.errorName;

//     let jwt = null;
//     let role = null;
//     let schoolUuid = null;

//     if (!error) {
//       jwt = loginResponse.data[PfnKey.KEY_JWT];
//       role = loginResponse.data[PfnKey.KEY_ROLE];
//       schoolUuid = loginResponse.data[PfnKey.KEY_SCHOOL_UUID];
//     }

//     this.props.dispatch(LoginStateSlice.slice.actions.setState(LoginStateSlice.createState(jwt, role, schoolUuid)));

//     if (!error) {
//       this.props.navigate(AppRoute.Main);
//     } else {
//       this.setState({
//         errorName: <Trans>LoginFailed</Trans>
//       })
//     }
//   }
// }

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];

  return props;
}

export default withRouter(connect(mapStateToProps)(Login));
