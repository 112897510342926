import Api from "./Api.js";
import AxiosHelper from "./AxiosHelper.js";
import PfnKey from "../PfnKey.js";

class SchoolManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new SchoolManager();
    }
    return this._instance;
  }

  async getSchoolsAsync(
    schoolUuid,
    jwt,
    partialSchoolName = null,
    locale = null,
    limit = null,
    offset = null
  ) {
    console.log(`Get school : ${schoolUuid}, arg:`);

    let route = "";
    if (schoolUuid) {
      route += `/${schoolUuid}`;
    }

    let arg = {};
    if (partialSchoolName) arg[PfnKey.KEY_PARAM_PARTIAL_SCHOOL_NAME] = partialSchoolName;
    if (locale) arg[PfnKey.KEY_PARAM_LOCALE] = locale;
    if (limit || limit === 0) arg[PfnKey.KEY_PARAM_LIMIT] = limit;
    if (offset || offset === 0) arg[PfnKey.KEY_PARAM_OFFSET] = offset;
    
    console.log(arg);

    const getSchoolsResponse = await AxiosHelper.axoisGetAsync(Api.SCHOOLS + route, arg, jwt);
    /*
    let query = "";
    if (partialSchoolName) {
      query += (query ? `&${PfnKey.KEY_PARAM_PARTIAL_SCHOOL_NAME}=${partialSchoolName}` : `${PfnKey.KEY_PARAM_PARTIAL_SCHOOL_NAME}=${partialSchoolName}` )
    }
    if (locale) {
      query += (query ? `&${PfnKey.KEY_PARAM_LOCALE}=${locale}` : `${PfnKey.KEY_PARAM_LOCALE}=${locale}` )
    }
    if (limit || limit === 0) {
      query += (query ? `&${PfnKey.KEY_PARAM_LIMIT}=${limit}` : `${PfnKey.KEY_PARAM_LIMIT}=${limit}` )
    }
    if (offset || offset === 0) {
      query += (query ? `&${PfnKey.KEY_PARAM_OFFSET}=${offset}` : `${PfnKey.KEY_PARAM_OFFSET}=${offset}` )
    }

    const getSchoolsResponse = await AxiosHelper.axoisGetAsync(Api.SCHOOLS + route + (query ? `?${query}` : ""), null, jwt);
    */

    if (getSchoolsResponse.errorName) {
      console.warn(`Get school failed (${getSchoolsResponse.errorName}): ${schoolUuid}`);
    }

    return getSchoolsResponse;
  }

  async postSchoolAsync(
    jwt,
    schoolName,
    pictureFile,
    watermarkFile,
    locale = null
  ) {
    let arg = {};
    if (locale) arg[PfnKey.KEY_PARAM_LOCALE] = locale;

    let formData = new FormData();
    formData.append(
      PfnKey.KEY_SCHOOL_NAME, schoolName);
    if (pictureFile) {
      formData.append(
        PfnKey.KEY_PICTURE_URL, pictureFile);
    }
    if (watermarkFile) {
      formData.append(
        PfnKey.KEY_WATERMARK, watermarkFile);
    }

    const postResponse =
      await AxiosHelper.axoisPostFilesAsync(Api.SCHOOLS, formData, jwt);
    if (postResponse.errorName) {
      console.warn('post School failed ' + JSON.stringify(postResponse.errorInfo) + ' : ' + schoolName);
    } else {
      console.log(`post School succeeded : ${schoolName}`);
    }

    return postResponse;
  }

  async putSchoolAsync(
    schoolUuid,
    jwt,
    schoolName,
    pictureFile,
    watermarkFile,
    locale = null
  ) {
    let route = "";
    if (schoolUuid) {
      route += `/${schoolUuid}`;
    }

    let arg = {};
    if (locale) arg[PfnKey.KEY_PARAM_LOCALE] = locale;

    let formData = new FormData();
    formData.append(
      PfnKey.KEY_SCHOOL_NAME, schoolName);
    if (pictureFile !== null) {
      formData.append(
        PfnKey.KEY_PICTURE_URL, pictureFile);
    }
    if (watermarkFile !== null) {
      formData.append(
        PfnKey.KEY_WATERMARK, watermarkFile);
    }

    const putResponse =
      await AxiosHelper.axoisPutFilesAsync(Api.SCHOOLS + route, formData, jwt);
    if (putResponse.errorName) {
      console.warn('put School failed ' + JSON.stringify(putResponse.errorInfo) + ' : ' + schoolName);
    } else {
      console.log(`put School succeeded : ${schoolName}`);
    }

    return putResponse;
  }
}

export default SchoolManager;
