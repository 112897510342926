import HttpUtils from "../utils/HttpUtils.js";

class Api {
  constructor() { 
    this._origin = HttpUtils.Scheme.HTTPS + "://localhost"
    this._rootPath = "/api";
  }

  get API_ROOT() {
    return this._origin + this._rootPath;
  }

  get LOGS() {
    return this.API_ROOT + "/logs";
  }

  get LOGIN() {
    return this.API_ROOT + "/login";
  }

  get FORGET_PASSWORD() {
    return this.API_ROOT + "/forgetPassword";
  }

  get RESET_PASSWORD() {
    return this.API_ROOT + "/resetPassword";
  }

  get ROBOTS() {
    return this.API_ROOT + "/robots";
  }

  get ROBOT_ASSIGNMENT() {
    return this.API_ROOT + "/robotAssignment";
  }

  get SCHOOLS() {
    return this.API_ROOT + "/schools";
  }

  get SKILL_CATEGORIES() {
    return this.API_ROOT + "/skillCategories";
  }

  get SKILL_STATUS() {
    return this.API_ROOT + "/skillStatus";
  }

  get SKILL_MATERIALS() {
    return this.API_ROOT + "/skillMaterials";
  }

  get SKILLS() {
    return this.API_ROOT + "/skills";
  }

  get USERS() {
    return this.API_ROOT + "/users";
  }

  setOrigin(origin) {
    this._origin = origin;
  }
}

export default new Api();
