import PfnKey from '../PfnKey.js';
import BaseStateSlice from './BaseStateSlice.js';


class SchoolStateSlice extends BaseStateSlice {
  createState(schoolName, pictureUrl) {
    const state = {};

    if (schoolName) {
      state[PfnKey.KEY_SCHOOL_NAME] = schoolName;
    }
    if (pictureUrl) {
      state[PfnKey.KEY_PICTURE_URL] = pictureUrl;
    }    

    return state;
  }

  getSchoolName(state) {
    return state ? state[PfnKey.KEY_SCHOOL_NAME] : null;
  }

  getPictureUrl(state) {
    return state ? state[PfnKey.KEY_PICTURE_URL] : null;
  }
}

export default new SchoolStateSlice();
