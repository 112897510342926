import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppStore from './redux/AppStore';
import Api from './backend/Api';
import AppRoute from './AppRoute';
import Login from "./pages/Login";
import Main from './pages/Main';
import Action from './pages/Action';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import CheckHref from './pages/CheckHref';

const App = () => {
  const location = useLocation(); 
  const navigate = useNavigate()
  const origin = window.location.origin;

  console.log(`Setting API origin: ${origin}`);

  Api.setOrigin(origin);
  const { t } = useTranslation();
  const checkJwt = () =>{
    const token = AppStore.getState().loginState.jwt
    const pathName = location.pathname
    const ignore = ["/passwordRecovery", "/checkHref"]
    if(!ignore.includes(pathName)){
       if(!token){
        navigate('/', {replace: true})
       }
    }
  }
  useEffect(()=>{
    checkJwt()
  }, [])
  document.title = t('AppTitle');
    return (
    <Routes>
      <Route exact path={AppRoute.Login} element={<Login />} />
      <Route path={AppRoute.Main} element={<Main />} />
      <Route path={AppRoute.ForgetPassword} element={<ForgetPassword />} />
      <Route path={AppRoute.ResetPassword} element={<ResetPassword />} />
      <Route path={AppRoute.RobotAssignment} element={<Main action={Action.RobotAssignment}/>} />
      <Route path={AppRoute.SkillSubmission} element={<Main action={Action.SkillSubmission}/>} />
      <Route path={AppRoute.SkillSubmissHistory} element={<Main action={Action.SkillSubmissHistory}/>} />
      <Route path={AppRoute.SkillReview} element={<Main action={Action.SkillReview}/>} />
      <Route path={AppRoute.SkillSharing} element={<Main action={Action.SkillSharing}/>} />
      <Route path={AppRoute.SkillSharingSelection} element={<Main action={Action.SkillSharingSelection}/>} />
      <Route path={AppRoute.VideoPlayHistory} element={<Main action={Action.VideoPlayHistory}/>} />
      <Route path={AppRoute.VideoPlayHistoryRobotSelection} element={<Main action={Action.VideoPlayHistoryRobotSelection}/>} />
      <Route path={AppRoute.VideoPlayHistorySkillSelection} element={<Main action={Action.VideoPlayHistorySkillSelection}/>} />
      <Route path={AppRoute.DataSchoolManagement} element={<Main action={Action.DataSchoolManagement}/>} />
      <Route path={AppRoute.DataUserManagement} element={<Main action={Action.DataUserManagement}/>} />
      <Route path={AppRoute.DataRobotManagement} element={<Main action={Action.DataRobotManagement}/>} />
      <Route path={AppRoute.CheckHref} element={<CheckHref/>}/>
    </Routes>
  )}

export default App;
