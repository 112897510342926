import React from "react";
import {
  Spinner,
} from "react-bootstrap";


class SpinnerBar extends React.Component {
  render() {
    return (
      <div className="mx-auto">
        <Spinner animation="border" variant="primary" />
        <Spinner animation="border" variant="secondary" />
        <Spinner animation="border" variant="success" />
        <Spinner animation="border" variant="danger" />
        <Spinner animation="border" variant="warning" />
        <Spinner animation="border" variant="info" />
      </div>
    )
  }
}

export default SpinnerBar;
