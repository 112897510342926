import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from '../../withRouter';
import PasswordManager from '../../backend/PasswordManager';
import styles from './index.module.css';
import styled from "styled-components";
const ForgetPasswordBox = styled.div`
    .login_form {
      width: 100%;
      margin-top: 20px;
      position: relative;

      input {
        font-family: inherit;
        width: 100%;
        outline: none;
        background-color: #fff;
        border-radius: 4px;
        border: none;
        display: block;
        padding: 0.9rem 0.7rem;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        font-size: 17px;
        color: #4a4a4a;
        text-indent: 40px;
      }

      .form-field {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;

        &:before {
          font-size: 20px;
          position: absolute;
          left: 15px;
          top: 17px;
          color: #888888;
          content: " ";
          display: block;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      .user::before {
        background-image: url(http://localhost:3000/user-icon.png);
        width: 20px;
        height: 20px;
        top: 15px;
      }

      .pwd::before {
        background-image: url(http://localhost:3000/lock-icon.png);
        width: 16px;
        height: 16px;
      }

      .btn {
        width: 100%;
        margin: 0 auto;
        padding: 10px 30px;
        text-align: center;
        background: #00592a;
        color: #fff;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        font-size: 17px;

        &:nth-child(2) {
          margin-left: 20px;
          background: #8b2600;
        }
      }

      .btnset {
        display: flex;
      }
    }
    .login{
      display: flex;
      width: fit-content;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      padding:20px;
      margin: 20px auto;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      background: url("http://localhost:3000/background/grey.png");
      justify-content: space-evenly;
      background: white;
      img {
       max-width: 500px;
  
      }
  }
`
const ForgotPassword = () => {
  const [ActionState, setActionState] = useState("email");
  const [username, setUsername] = useState('')

  const SuccessAction = () =>
  (
    <Link to="/">
      <img src="./img-08.png" alt="" />
    </Link>
  )


  const ChangePassword = () => {
    return (
      <>
        <img src="./App_logo with text_01.png" alt="" />
        <p>請輸入新設定的密碼</p>
        <div className="login_form ">
          <div className="form-field pwd">
            <input type="password" placeholder="請輸入新密碼" required />
          </div>
          <div className="form-field pwd">
            <input type="password" placeholder="請重新輸入密碼" required />
          </div>
          <div className="btnset">
            <Link className="btn" onClick={() => setActionState("success")}>
              確定
            </Link>
          </div>
        </div>
      </>
    );
  };
  const submitEmailClick = async () => {
    setActionState("back")
    const passwordMgr = PasswordManager.getInstance();
    const forgetpwdResponse = await passwordMgr.forgetPasswordAsync(username);
    const error = forgetpwdResponse.errorName;
    console.log('error', error)
  }
  const SendEmail = () => {
    return (
      <>
        <img src="./App_logo with text_01.png" alt="" />
        <p>請輸入貴校的電子郵，將寄送設定密碼的電郵</p>
        <div className="login_form ">
          <div className="form-field user">
            <input
              type="email"
              placeholder="請輸入 貴校的登入名稱(一般是貴校的電郵地址)"
              required
              defaultValue={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </div>
          <div className="btnset">
            <Link className="btn" onClick={submitEmailClick}>
              發送
            </Link>
            <Link to="/" className="btn">
              取消
            </Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <ForgetPasswordBox>
      <div className="login">
        {ActionState === 'email' && (
          SendEmail()
        )}
        {ActionState === 'back' && (
          SuccessAction()
        )}
      </div>
    </ForgetPasswordBox>
  );
};

export default withRouter(ForgotPassword);

