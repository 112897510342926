import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import StoreState from "../../redux/StoreState";
import LoginStateSlice from "../../redux/LoginStateSlice";
import styled from "styled-components";
import { Dropdown, Button, Table, message } from "antd";
import { request } from "../../request";

const ContentPlayHistorySkillSelectionBox = styled.div`
  .headerBar {
    margin-top: 30px;
    margin-bottom: 30px;
    .skillSelectionSort {
      display: inline-block;
    }
    .revealRadius {
      display: inline-block;
    }
    .upArrow {
      background: #057dd3;
      color: #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      border: unset;
      padding: 5px 10px;
      border-radius: 6px;
      transition: 0.5s;
      cursor: pointer;
      margin-left: 20px;
      img {
        width: 18px;
        margin-bottom: 2px;
      }
    }
    .upArrow:hover{
      transform: scale(0.9);
    }
    .downArrow {
      background: #10b46a;
      color: #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      border: unset;
      padding: 5px 10px;
      border-radius: 6px;
      transition: 0.5s;
      cursor: pointer;
      margin-left: 20px;
      img {
        width: 18px;
        margin-bottom: 2px;
      }
    }
    .downArrow:hover{
      transform: scale(0.9);
    }
    .sortSelect {
      display: inline-block;
      background: #eb9300;
      img {
        width: 18px;
      }
    }
    .antdDropDown {
      background: #f39800;
      color: white;
      padding: 0 8px;
      :hover {
        color: white;
        border: none;
      }
    }
  }
  .tableBox {
    .ant-table {
      font-weight: 600;
      width: 60vw;
      .ant-table-thead {
        .ant-table-cell {
          /* text-align: center; */
          background: #f39800;
          padding: 8px 8px !important;
          border: 2px solid white;
          border-radius: 0;
          color: white;
          &:before {
            display: none;
          }
          .ant-table-filter-column {
            .ant-table-filter-trigger {
              color: white;
            }
          }
        }
      }
      .ant-table-tbody {
        /* text-align: center; */
        .ant-table-row {
          .ant-table-cell {
            background: #f6ede4;
            border: 2px solid white;
            padding: 7px 0 !important;
          }
          &:hover {
          }
        }
      }
    }
    .ant-pagination {
      .ant-pagination-item {
        min-width: 30px !important;
        height: 30px !important;
        border-radius: 100% !important;
      }
      .ant-pagination-item-active {
        background-color: #1677ff;
        border-color: #1677ff;
        color: #fff;
      }
    }
  }
`;
const ContentPlayHistorySkillSelection = () => {
  const loginState = useSelector((state) => state[StoreState.LOGIN]);
  const [categoryName, setCategoryName] = useState("所有範圍");
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dropMenuVal, setDropMenuVal] = useState("all");
  const [tableLoading, setTableLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'viewCount',
    });
  };
  const setAgeSortAse = () => {
    setSortedInfo({
      order: 'ascend',
      columnKey: 'viewCount',
    });
  }
  const handleMenuClick = (val) => {
    const key = val.key;
    setCurrent(1);
    setDropMenuVal(key);
    if (key === "catUuidClothing") {
      setCategoryName("衣");
    } else if (key === "catUuidFood") {
      setCategoryName("食");
    } else if (key === "catUuidHousing") {
      setCategoryName("住");
    } else if (key === "catUuidTransportation") {
      setCategoryName("行");
    } else if (key === "all") {
      setCategoryName("所有範圍");
    }
  };
  const renderSkillCategory = (key) => {
    if (key === "catUuidClothing") {
      return "衣";
    } else if (key === "catUuidFood") {
      return "食";
    } else if (key === "catUuidHousing") {
      return "住";
    } else if (key === "catUuidTransportation") {
      return "行";
    }
  };
  const getTableList = () => {
    setTableLoading(true)
    if (dropMenuVal === "all") {
      request("/getViewList", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
        },
        data: {
          page: current,
          pageSize: 10,
        },
      }).then((res) => {
        if (res.code == 200) {
          setTableData(res.data);
          setTotal(res.total);
        }else {
          message.error(res.msg)
        }
        setTableLoading(false)
      });
    } else {
      request("/getViewList", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${LoginStateSlice.getJwt(loginState)}`,
        },
        data: {
          page: current,
          pageSize: 10,
          categoryUuid: dropMenuVal,
        },
      }).then((res) => {
        if (res.code == 200) {
          setTableData(res.data);
          setTotal(res.total);
        }else {
          message.error(res.msg)
        }
        setTableLoading(false)
      });
    }
  };
  useEffect(() => {
    getTableList();
  }, [current, dropMenuVal]);
  const colums = [
    {
      dataIndex: "categoryUuid",
      title: "類別",
      align: "center",
      render: (val) => renderSkillCategory(val),
      ellipsis: true,
    },
    {
      dataIndex: "itemName",
      title: "範疇",
      align: "center",
      ellipsis: true,
    },
    {
      dataIndex: "skillName",
      title: "生活技能名稱",
      align: "center",
      ellipsis: true,
    },
    {
      dataIndex: "viewCount",
      title: "觀看次數",
      align: "center",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.viewCount - b.viewCount,
      sortOrder: sortedInfo.columnKey === 'viewCount' ? sortedInfo.order : null,
      ellipsis: true,
    },
  ];
  return (
    <ContentPlayHistorySkillSelectionBox>
      <div className="headerBar">
        <Dropdown
          className="antdDropDown"
          menu={{
            items: [
              {
                key: "all",
                label: "所有範圍",
              },
              {
                key: "catUuidClothing",
                label: "衣",
              },
              {
                key: "catUuidFood",
                label: "食",
              },
              {
                key: "catUuidHousing",
                label: "住",
              },
              {
                key: "catUuidTransportation",
                label: "行",
              },
            ],
            onClick: handleMenuClick,
            selectable: true,
          }}
          placement="bottom"
          arrow
        >
          <Button>
            <img
              src="/icon/sort.png"
              style={{
                width: "18px",
                marginBottom: "2px",
                marginRight: "5px",
              }}
            />
            {categoryName}
          </Button>
        </Dropdown>
        <button
          className="upArrow"
          onClick={setAgeSort}
        >
          <img src="/icon/up_arrow.png" />
          最多觀看
        </button>
        <button
          className="downArrow"
          onClick={setAgeSortAse}
        >
          <img src="/icon/down_arrow.png" />
          最少觀看
        </button>
      </div>
      <div className="tableBox">
        <Table
          dataSource={tableData}
          columns={colums}
          loading={tableLoading}
          onChange={handleChange}
          showSorterTooltip={false}
          pagination={{
            total: total,
            current: current,
            pageSize: 10,
            showSizeChanger: false,
            onChange: (page) => setCurrent(page),
          }}
        />
      </div>
    </ContentPlayHistorySkillSelectionBox>
  );
};
function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(ContentPlayHistorySkillSelection);
