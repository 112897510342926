import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import SchoolManager from "../../backend/SchoolManager";
import UserManager from "../../backend/UserManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import StoreState from "../../redux/StoreState";
import { Table, Modal, Button, Form, Input, Select, Radio, Space, message } from "antd";
import {request} from "../../request/index"
import styled from "styled-components";
import "./index.css";

const JulyTable = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #f39800;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #f6ede4;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
`;
const OperationButtons = styled.div`
  .enableBtn {
    background: #0367ac;
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    height: 24px;
    width: 50px;
    font-size: 14px;
    &:hover {
      transform: scale(0.9);
    }
  }
  .updateBtn {
    background: rgb(34, 150, 34);
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    height: 24px;
    width: 50px;
    font-size: 14px;
    margin-right: 20px;
    &:hover {
      transform: scale(0.9);
    }
  }
  .deleteBtn {
    background: rgb(232, 56, 40);
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border: unset;
    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    height: 24px;
    width: 50px;
    font-size: 14px;
    &:hover {
      transform: scale(0.9);
    }
  }
`;
const HeaderBar = styled.div`
  .addUserBtn {
    background: #999999;
    text-align: center;
    width: 100px;
    border: 0;
    border-radius: 10px;
    transition: 0.5s;
    color: white;
    text-align: center;
    padding: 2px 0;
    font-size: 14px;
    margin-bottom: 12px;
    margin-left: calc( 60vw - 100px );
    .btnIcon {
      height: 14px;
      margin-right: 6px;
      margin-bottom: 2px;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
`;
const DataUserManagement = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [choiceTableItem, setChoiceTableItem] = useState({});
  const [form] = Form.useForm();
  const [reRender, setReRender] = useState(1);

  const _getUserList = async (jwt) => {
    let schoolList = [],
      userList = [];
    const getSchoolResponse = await SchoolManager.getInstance().getSchoolsAsync(
      null,
      jwt
    );
    for (let school of getSchoolResponse.data) {
      schoolList.push({
        id: school.schoolUuid,
        name: school.schoolName,
      });
    }

    const getUserResponse = await UserManager.getInstance().getUsersAsync(jwt);
    for (let user of getUserResponse.data) {
      if (showUserOfRole(user.role)) {
        const schoolName = getSchoolName(schoolList, user.schoolUuid);
        userList.push({
          id: user.userUuid,
          name: user.displayName,
          schoolUuid: user.schoolUuid,
          school: schoolName,
          role: user.role,
          displayRole: user.role,
          email: user.email,
          action: user,
        });
      }
    }
    setSchoolList(schoolList);
    setUserList(userList);
  };
  const showUserOfRole = (role) => {
    if (
      role === UserManager.UserRole.AUDITOR ||
      role === UserManager.UserRole.SCHOOL ||
      role === UserManager.UserRole.STUDENT
    ) {
      return true;
    }
    return false;
  };

  const getSchoolName = (schoolList, sid) => {
    let school = schoolList.find((s) => s.id === sid);
    if (school !== undefined) {
      return school.name;
    }
    return "";
  };

  const getSchoolOptionList = () => {
    const optionList = schoolList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    return optionList;
  };

  const modalSubmit = (value) => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    if (!editUser) {
      form
        .validateFields()
        .then(async (values) => {
          // const postUserResponse =
          //   await UserManager.getInstance().postUserAsync(
          //     jwt,
          //     values.schoolname,
          //     values.userrole,
          //     values.password,
          //     values.usename,
          //     values.loginEmail
          //   );
          await  request('users', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${jwt}`
              },
              data: {
                displayName: values.usename,
                email: values.loginEmail,
                password: values.password,
                role: values.userrole,
                schoolUuid: values.schoolname
              }
            }).then(res=>{
              if(res.code && res.code == 401){
                message.error(res.msg)
              }else if(res.code && res.code == 200){
                message.success('新增用戶成功！')
              }else {
                message.error('error')
              }
            })
          form.resetFields();
          setReRender((pre) => pre + 1);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
      setIsModalOpen(false);
    } else {
      form
        .validateFields()
        .then(async (values) => {
          const postUserResponse = await UserManager.getInstance().putUserAsync(
            choiceTableItem.userUuid,
            jwt,
            choiceTableItem.schoolUuid,
            values.userrole,
            values.usename,
            values.loginEmail
          );
          if (postUserResponse.errorName) {
            message.error(postUserResponse.errorName);
          }
          form.resetFields();
          setReRender((pre) => pre + 1);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
      setIsModalOpen(false);
    }
  };
  const modalSubmitFails = (value) => {
    console.log("fails", value);
  };
  const updateModal = async (value) => {
    setIsModalOpen(true);
    setEditUser(true);
    form.setFieldsValue({
      loginEmail: value.email,
      usename: value.name,
      schoolname: value.school,
      userrole: value.role,
    });
    setChoiceTableItem({
      schoolUuid: value.schoolUuid,
      userUuid: value.id,
      role: value.role,
    });
  };
  const updateUserStatus = async (item, boolean) => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    const putUserResponse =
      await UserManager.getInstance().updateUserStatusAsync(
        item.id,
        jwt,
        boolean
      );
    if (putUserResponse.errorName) {
      message.error(putUserResponse.errorName);
    } else {
      setReRender((pre) => pre + 1);
    }
  };
  const openAddModal = () => {
    setIsModalOpen(true);
    setEditUser(false);
    // form.setFieldValue({
    //   loginEmail: '',
    //   usename: ''
    // })
  };
  const Antdcolumns = [
    {
      title: "用戶名稱",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "學校名稱",
      dataIndex: "school",
      key: "school",
    },
    {
      title: "用戶身份",
      dataIndex: "role",
      key: "role",
      render: (val, item) => {
        return <Trans>{val}</Trans>;
      },
    },
    {
      title: "登入名稱/電子郵件",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "編輯用戶",
      dataIndex: "operation",
      key: "operation",
      render: (val, item) => {
        return (
          <OperationButtons key={item.id}>
            <button className="updateBtn" onClick={() => updateModal(item)}>
              修改
            </button>
            {item.action.status == "NORMAL" && (
              <button
                className="deleteBtn"
                onClick={() => updateUserStatus(item, false)}
              >
                停用
              </button>
            )}
            {item.action.status == "DISABLED" && (
              <button
                className="enableBtn"
                onClick={() => updateUserStatus(item, true)}
              >
                啟用
              </button>
            )}
          </OperationButtons>
        );
      },
    },
  ];
  const validateMessages = {
    required: "請輸入${label}！",
    types: {
      email: "${label}不是郵件格式!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  useEffect(() => {
    const loginState = props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    _getUserList(jwt);
  }, [reRender]);
  return (
    <div>
      <Modal
        title="用戶資料管理"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={800}
        style={{ borderRadius: "50px" }}
        wrapClassName="rewriteModalStyle"
        closeIcon={null}
        destroyOnClose={true}
      >
        <Form
          name="addUser"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          preserve={false}
          onFinish={modalSubmit}
          onFinishFailed={modalSubmitFails}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="登入名稱/電子郵件"
            name="loginEmail"
            rules={[{ type: "email", required: true }]}
          >
            <Input autocomplete="off" maxLength={30}/>
          </Form.Item>
          {!editUser && (
            <Form.Item
              label="登入密碼"
              name="password"
              rules={[{ required: true, message: "請輸入密碼!" }]}
            >
              <Input autocomplete="off"/>
            </Form.Item>
          )}
          <Form.Item
            label="用戶名稱"
            name="usename"
            rules={[{ required: true, message: "請輸入用戶名稱!" }]}
          >
            <Input maxLength={20}/>
          </Form.Item>
          <Form.Item
            label="學校名稱"
            name="schoolname"
            rules={[{ required: true, message: "請輸入學校名稱!" }]}
            required
          >
            <Select options={getSchoolOptionList()} disabled={editUser} />
          </Form.Item>
          <Form.Item
            label="用戶身份"
            name="userrole"
            initialValue="AUDITOR"
            required
          >
            {editUser ? (
              <div style={{ marginLeft: "10px" }}>
                <Trans>{choiceTableItem.role}</Trans>
              </div>
            ) : (
              <Radio.Group validateFields="AUDITOR">
                <Space direction="vertical">
                  <Radio value="AUDITOR">教材審判員</Radio>
                  <Radio value="SCHOOL">學校</Radio>
                  <Radio value="STUDENT">學生</Radio>
                </Space>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="">
            <div className="modalBtnBox">
              <Button htmlType="submit" className="saveBtn">
                儲存
              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                className="cancelBtn"
              >
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <div className="userMangeTitle">用戶管理</div>
        <HeaderBar>
          <div onClick={openAddModal} className="addUserBtn">
            <img src="icon/add.png" className="btnIcon"></img>
            <span><Trans>AddUser</Trans></span>
          </div>
        </HeaderBar>
        <JulyTable>
          <Table dataSource={userList} columns={Antdcolumns} />
        </JulyTable>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(DataUserManagement);
