const Action = {
  RobotAssignment   : 'RobotAssignment',
  SkillSubmission   : 'SkillSubmission',
  SkillSubmissHistory : 'SkillSubmissHistory',
  SkillReview       : 'SkillReview',
  SkillSharing      : 'SkillSharing',
  SkillSharingSelection : 'SkillSharingSelection',
  VideoPlayHistory  : 'VideoPlayHistory',  
  VideoPlayHistoryRobotSelection  : 'VideoPlayHistoryRobotSelection',
  VideoPlayHistorySkillSelection  : 'VideoPlayHistorySkillSelection',
  DataSchoolManagement : 'DataSchoolManagement',
  DataUserManagement   : 'DataUserManagement',
  DataRobotManagement   : 'DataRobotManagement'
}

export default Action;
