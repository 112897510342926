import Api from "./Api.js";
import PfnKey from "../PfnKey.js";
import AxiosHelper from "./AxiosHelper.js";

class LoginManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new LoginManager();
    }
    return this._instance;
  }

  async loginAsync(username, password) {
    console.log(`Logging in : ${username}`);

    const params = {};
    params[PfnKey.KEY_LOGIN_NAME] = username;
    params[PfnKey.KEY_PASSWORD] = password;

    const loginResponse = await AxiosHelper.axoisPostAsync(Api.LOGIN, params);

    if (loginResponse.errorName) {
      console.warn(`Login failed (${loginResponse.errorName}): ${username}`);
    } else {
      console.log(`Login succeeded : ${username}`);      
    }

    return loginResponse;
  }
}

export default LoginManager;
