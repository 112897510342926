import React from "react";
import $ from "jquery";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import SkillManager from "../../backend/SkillManager";
import RobotManager from "../../backend/RobotManager";
import SkillStatusManager from "../../backend/SkillStatusManager";
import SkillMaterialManager from "../../backend/SkillMaterialManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import SchoolStateSlice from "../../redux/SchoolStateSlice";
import StoreState from "../../redux/StoreState";
import SkillStatus from "../../backend/SkillStatus";
import { Table, Modal, message, Spin, Progress, Button } from "antd";
import styled from "styled-components";
import OSS from "ali-oss";
import { request } from "../../request";
const AntdTable = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #00a0e9;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #d3edfb;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
  .table-operate {
    .delete-btn {
      background: rgb(232, 56, 40);
      color: #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      border: unset;
      border-radius: 6px;
      transition: 0.5s;
      cursor: pointer;
      height: 24px;
      width: 50px;
      font-size: 14px;
    }
  }
`;
const ContentBox = styled.div`
  .pageTitle {
    font-size: large;
    color: #fff;
    margin: 20px 0;
    padding: 8px;
    border-radius: 5px;
    background: #00a0e9;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .inputSkill {
    height: 40px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px;
    padding-left: 10px;
  }

  .uploadstep {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .uploadbox {
      position: relative;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      color: #fff;
    }
  }

  .submit-opinion {
    display: flex;
    justify-content: right;

    .btn-left {
      background: #b6cc9b;
      color: #fff;
      font-weight: bold;
      padding: 8px 36px;
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.5s;
      border: 0;
      margin-top: 30px;
    }

    .btn-right {
      margin-left: 30px;
      background: rgb(0, 160, 233);
    }
  }

  .upload-two-code {
    color: black;
    background-color: white;
    font-weight: bold;
    border: 1px solid #dee2e6;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 20px 4px 2px 4px;
    width: 200px;
    height: 150px;

    .upload-content-wrap {
      position: relative;
      vertical-align: top;
      padding: 2px;
      margin: 2px;
      .close {
        position: absolute;
        display: inline-block;
        width: 25px;
        height: 25px;
        padding-left: 2px;
        line-height: 0.7;
        color: floralwhite;
        text-shadow: 1px 1px #282c34;
        border-radius: 50%;
        background-color: rgb(99, 99, 99, 0.5);
        font-size: 28px;
        top: 2px;
        right: 2px;
        z-index: 100;
      }
    }

    .file-upload-container {
      position: relative;
      text-align: center;
      color: gray;

      .file-upload-hint {
        position: absolute;
        top: -20px;
        left: 40px;
        color: black;
      }
    }
  }
  .submit-tow-code {
    background: rgb(0, 160, 233);
    color: #fff;
    font-weight: bold;
    width: 125px;
    height: 38px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.5s;
    border: 0;
    margin-top: 30px;
    margin-left: calc(100% - 130px);
  }
  .pageline {
    height: 1px;
    background: #ccc;
    margin: 30px 0;
  }
  .btnBox {
    display: flex;
    justify-content: center;
    .submitBtn {
      background: #b6cc9b;
      color: #fff;
      font-weight: bold;
      /* padding: 8px 36px; */
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      border: 0;
      transition: 0.5s;
      /* margin-right: 30px; */
      width: 100px;
      height: 40px;
      margin-right: 15px;
      margin-bottom: 20px;
    }
    .cancelBtn {
      margin-left: 15px;
    }
  }
`;
const UploadStep = styled.div`
  display: flex;
  flex-wrap: wrap;
  .uploadbox {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #fff;
    flex-basis: 25%;
    box-sizing: border-box;
    .step {
      position: absolute;
      padding: 5px;
      background: rgba(133, 51, 51, 0.2);
      width: 50px;
      height: 30px;
      z-index: 10;
    }
    .stepbox {
      .container {
        padding: 0;
        .thumbnail {
          display: flex;
          justify-content: center;
        }
        .play {
          position: absolute;
          /* display: inline-block; */
          width: 48px;
          height: 48px;
          /* padding-left: 1px; */
          /* line-height: 0.7; */
          color: floralwhite;
          text-shadow: 2px 2px #282c34;
          border-radius: 50%;
          background-color: rgb(99, 99, 99, 0.5);
          font-size: 24px;
          top: 15px;
          /* right: 40px; */
          z-index: 100;
          /* text-align: center; */
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }
`;
const ModalHead = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    inset-inline-end: 17px;
    z-index: 1010;
    padding: 0;
    top: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    position: absolute;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
    font-size: 30px;
  }
  .align-bottom {
    margin-top: 30px;
  }
`;
class ContentUploadHistory extends React.Component {
  static FIELD_NAME = "Name";
  static FIELD_CATAGORY = "Category";
  static FIELD_VIDEO = "Video";
  static FIELD_IMAGE = "Image";
  static FIELD_QRCODE = "QrCode";
  static FIELD_ROBOT = "Robot";

  constructor() {
    super();

    this.updateFields = "";
    this.updateVideoDetails = [];
    this.robotList = [];
    this.state = {
      show: false,
      readOnly: false,
      disabledSubmit: true,
      searchBox: null,
      showRobotList: false,
      skillCategories: {},
      skillRecordList: [],
      editSkill: null,
      checkApplyAllRobot: null,
      checkSkillCategory: [],
      skillCoverImg: null,
      upLoadProgress: 0,
      upLoadTitle: "",
      ProgressOpen: false,
      spinning: false,
    };
    this.JWT = null;
  }

  componentDidMount() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this.JWT = jwt;
    this._getSkillRecords(jwt);
    this._getSkillCategories(jwt);
    this._getRobots(jwt);
  }

  handleKeyEvent = (event) => {
    var searchKey = event.target.value;
    const searchBoxSuffix = "-search-result";
    const searchBoxId = event.target.id + searchBoxSuffix;
    if (event.type === "keyup") {
      if (searchKey !== "") {
        this.searchRobot(searchBoxId, searchKey);
      } else {
        $("#" + searchBoxId).css("display", "none");
      }
    }
  };

  handleBlur = (event) => {
    const searchBoxSuffix = "-search-result";
    const searchBoxId = event.target.id + searchBoxSuffix;
    setTimeout(function () {
      $("#" + searchBoxId).css("display", "none");
    }, 200);
  };

  handleModalHide = () => {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this._getSkillRecords(jwt);

    this.setShow(false);
  };

  getOptions(catId, name, values) {
    const editSkill = this.state.editSkill;
    const optionId = catId + "-";
    var isOptionChecked = this.state.checkSkillCategory.includes(catId);
    var categoryInput = [];

    categoryInput.push(
      <div className="form-check" name="skill-category" key={catId}>
        <input
          id={catId}
          name={catId}
          type="checkbox"
          checked={isOptionChecked}
          onChange={(e) => {
            $('[id^="' + optionId + '"]').attr("disabled", !e.target.checked);

            var checkCategory = this.state.checkSkillCategory;
            if (e.target.checked) {
              checkCategory = [catId];
              editSkill.categoryItemList = [];
            } else {
              const index = checkCategory.indexOf(catId);
              if (index > -1) {
                checkCategory.splice(index, 1);
                editSkill.categoryItemList = [];
              }

              for (var v in values) {
                if (
                  editSkill !== null &&
                  editSkill.categoryItemList.includes(values[v].uid)
                ) {
                  const index = editSkill.categoryItemList.indexOf(
                    values[v].uid
                  );
                  if (index > -1) {
                    editSkill.categoryItemList.splice(index, 1);
                  }
                }
              }
            }

            this.setState({
              checkSkillCategory: checkCategory,
              disabledSubmit: this.validateInputFields(),
              editSkill: editSkill,
            });
            this.setUpdateFields(ContentUploadHistory.FIELD_CATAGORY);
          }}
        ></input>
        <label
          className="modal-options"
          style={{ padding: "2px", display: "inline-block" }}
        >
          {name}
        </label>
      </div>
    );

    for (var v in values) {
      var checked = false;
      if (
        editSkill !== null &&
        editSkill.categoryItemList.includes(values[v].uid)
      ) {
        checked = true;
      }

      categoryInput.push(
        <div className="form-check" name="skill-category" key={v}>
          <input
            className="modal-custom-checkbox"
            id={optionId + v}
            name={values[v].uid}
            type="checkbox"
            disabled={!isOptionChecked}
            checked={checked}
            onChange={(e) => {
              if (e.target.checked) {
                editSkill.categoryItemList = [e.target.name];
                this.setState({
                  disabledSubmit: this.validateInputFields(),
                  editSkill: editSkill,
                });
              } else {
                const index = editSkill.categoryItemList.indexOf(e.target.name);
                if (index > -1) {
                  editSkill.categoryItemList.splice(index, 1);
                  this.setState({
                    disabledSubmit: this.validateInputFields(),
                    editSkill: editSkill,
                  });
                }
              }
              this.setUpdateFields(ContentUploadHistory.FIELD_CATAGORY);
            }}
          ></input>
          &nbsp;
          <label
            className="modal-options"
            style={{ padding: "2px", display: "inline-block" }}
          >
            {values[v].name}
          </label>
        </div>
      );
    }

    return (
      <Col xs={3} key={catId}>
        {categoryInput}
      </Col>
    );
  }

  async _getSkillRecords(jwt) {
    var skillList = [];
    const skillStatusResponse =
      await SkillStatusManager.getInstance().getSkillStatusAsync(jwt);

    for (var skill of skillStatusResponse.data) {
      const skillUuid = skill.skillUuid;
      const readOnly =
        skill.skillStatus !== SkillStatus.SUBMIT_REVIEW &&
        skill.skillStatus !== SkillStatus.REWORK_REVIEW_DONE;
      skillList.push({
        id: skill.skillUuid,
        name: skill.skillName,
        status: skill.skillStatus,
        statusString:
          skill.skillStatus !== SkillStatus.SUBMIT_REVIEW ? (
            <a
              href="\#"
              role="button"
              onClick={(e) => this.handleEditAction(e, skillUuid, readOnly)}
            >
              {skill.statusNameToClient}
            </a>
          ) : (
            skill.statusNameToClient
          ),
        updateTime: new Date(skill.updateTime).toLocaleString(i18n.language),
      });
    }
    this.setState({
      skillRecordList: skillList,
    });
  }

  async _getSkill(jwt, skillId, readOnly) {
    const skillResponse = await SkillManager.getInstance().getSkillAsync(
      jwt,
      skillId
    );

    const skill = skillResponse.data;
    var skillCoverFile = null;
    var skillVideoFiles = [],
      skillMaterialIds = [];
    for (var section of skill.sectionList) {
      if (
        section.sectionName === "skillDiagram" &&
        section.materialList.length > 0
      ) {
        skillCoverFile = section.materialList[0].internalPath;
      } else if (section.sectionName === "skillVideo") {
        for (var material of section.materialList) {
          skillVideoFiles[material.partsSeq] = material.internalPath;
          skillMaterialIds[material.partsSeq] = material.materialUuid;
        }
      }
    }

    const checkCategory = [];
    const map = this.state.skillCategories;
    Object.keys(map).forEach((key) => {
      for (var item of map[key].items) {
        if (skill.categoryItemList.includes(item.uid)) {
          if (!checkCategory.includes(map[key].uid)) {
            checkCategory.push(map[key].uid);
          }
        }
      }
    });

    this.setState({
      editSkill: {
        id: skillId,
        name: skill.skillName,
        commentList: skill.commentList,
        categoryItemList: skill.categoryItemList,
        robotUuidList: skill.robotUuidList,
        skillCoverFile: skillCoverFile,
        skillVideoFiles: skillVideoFiles,
        skillMaterialIds: skillMaterialIds,
      },
      checkSkillCategory: checkCategory,
      checkApplyAllRobot: skill.robotUuidList == null,
      readOnly: readOnly,
      show: true,
    });
    this.updateFields = "";
    this.updateVideoDetails.length = 0;
  }

  setUpdateFields(fieldName) {
    if (!this.updateFields.includes(fieldName)) {
      this.updateFields += fieldName;
    }
  }

  handleEditAction = (e, sid, readOnly) => {
    e.preventDefault();

    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this._getSkill(jwt, sid, readOnly);
  };

  async _getSkillCategories(jwt) {
    var categoryArray = {};
    const schoolInfoResponse =
      await SkillManager.getInstance().getSkillCategoriesAsync(jwt);
    if (Object.keys(schoolInfoResponse.data).length > 1) {
      schoolInfoResponse.data.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });
    }

    for (var category of schoolInfoResponse.data) {
      const name = category.categoryName;
      const itemList = category.itemList;
      itemList.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });

      var data = [];
      for (var item of itemList) {
        data.push({
          uid: item.itemUuid,
          name: item.itemName,
        });
      }
      categoryArray[name] = {
        uid: category.categoryUuid,
        items: data,
      };
    }

    this.setState({
      skillCategories: categoryArray,
    });
  }

  async _getRobots(jwt) {
    const getRobotsResponse = await RobotManager.getInstance().getRobotsAsync(
      jwt,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "NORMAL",
      null,
      null
    );
    const robotData = getRobotsResponse.data;

    this.robotList = robotData;
  }

  renderSkillCategory() {
    const map = this.state.skillCategories;

    var htmlStr = [];
    Object.keys(map).forEach((key) => {
      htmlStr.push(this.getOptions(map[key].uid, key, map[key].items));
    });
    return (
      <Row id="skillCategoryRow" style={{ fontSize: "12px" }}>
        {htmlStr}
      </Row>
    );
  }

  renderCommentText() {
    var commentString = [];
    const editSkill = this.state.editSkill;
    if (editSkill != null && "commentList" in editSkill) {
      const comments = editSkill.commentList;
      if (comments != null) {
        comments.sort((a, b) => a.pageSeq > b.pageSeq);
        return comments.map((item, index) => (
          <div
            className="form-control border rounded mb-1"
            id="comment-history"
            key={index}
          >
            <Trans>CommentAt</Trans>
            {new Date(item.createDate).toLocaleString(i18n.language) + ","}
            <div style={{ padding: "4px", paddingLeft: "1em" }}>
              {item.comment}
            </div>
          </div>
        ));
      }
    }
    return commentString;
  }

  setShow(s) {
    this.setState({ show: s });
  }

  uncheckPartialRobotOption() {
    $("#uploadPartialRobotOption").prop("checked", false);
    this.setState({
      disabledSubmit: this.validateInputFields(),
      checkApplyAllRobot: true,
    });
    this.setUpdateFields(ContentUploadHistory.FIELD_ROBOT);
  }

  setRobotListShow() {
    if ($("#uploadPartialRobotOption").is(":checked")) {
      $("#uploadAllRobotOption").prop("checked", false);
      const status = this.state.showRobotList;
      this.setState({
        disabledSubmit: this.validateInputFields(),
        showRobotList: !status,
        checkApplyAllRobot: false,
      });
    }
    console.log("state", this.state, "status", status);
    this.setUpdateFields(ContentUploadHistory.FIELD_ROBOT);
  }

  setRobotSelected(elementId, robotSerial, robotId) {
    console.log("Robot selected: " + elementId, robotId);
    $("#" + elementId).val(robotSerial);

    var index = elementId.replace(/[^0-9]/g, "");

    var updateSkill = this.state.editSkill;
    updateSkill.robotUuidList[index] = robotId;

    this.setState({
      editSkill: updateSkill,
      searchBox: null,
    });
  }

  searchRobot(elementId, searchKey) {
    const searchBoxSuffix = "-search-result";
    const inputElement = elementId.replace(searchBoxSuffix, "");
    if (searchKey === null) {
      const robotSerial = $("#" + inputElement).val();
      var robotId = this.getRobotId(robotSerial);
      if (robotId !== undefined) {
        var index = inputElement.replace(/[^0-9]/g, "");
        var updateSkill = this.state.editSkill;
        updateSkill.robotUuidList[index] = undefined;

        this.setState({
          editSkill: updateSkill,
        });
      }
    }

    this.setState({
      searchBox: {
        id: elementId,
        key: searchKey,
      },
    });

    $("#" + elementId).css("display", "block");
  }

  renderSearchBox(elementId, searchKey) {
    var data = [];
    if (searchKey !== null) {
      const selectedRobots = this.state.editSkill.robotUuidList;
      for (var robot of this.robotList) {
        const robotSerial = robot.vendorSerial;
        const robotId = robot.robotUuid;
        if (
          robotId.includes(searchKey) &&
          selectedRobots !== null &&
          !selectedRobots.includes(robotId)
        ) {
          data.push(
            <tr
              key={robotId}
              className="m-2"
              style={{ height: "20px" }}
              onClick={() =>
                this.setRobotSelected(elementId, robotSerial, robotId)
              }
            >
              <td>{robotSerial}</td>
            </tr>
          );
        }
      }
    }
    return data;
  }

  handleChangeUploadVideo(i, videoLabel, e) {
    if (e.target.files[0] !== undefined) {
      $("#" + videoLabel + "View").removeAttr("hidden");
      $("#" + videoLabel + "Hint").attr("hidden", true);
      $("#" + videoLabel + "Next").attr("hidden", true);

      var updateSkill = this.state.editSkill;
      updateSkill.skillVideoFiles[i] = e.target.files[0];
      this.setState({
        disabledSubmit: this.validateInputFields(),
        editSkill: updateSkill,
      });
      this.setUpdateFields(ContentUploadHistory.FIELD_VIDEO);
      if (!this.updateVideoDetails.includes(i)) {
        this.updateVideoDetails.push(i);
      }

      setTimeout(function () {
        var $source = $("#" + videoLabel + "Preview");
        $source[0].src = URL.createObjectURL(e.target.files[0]);
        $source.parent()[0].load();
      }, 1000);
    }
  }

  handleChangeCoverImage(e) {
    if (e.target.files[0] !== undefined) {
      var updateSkill = this.state.editSkill;
      updateSkill.skillCoverFile = e.target.files[0];
      this.setState({
        disabledSubmit: this.validateInputFields(),
        skillCoverImg: URL.createObjectURL(e.target.files[0]),
        editSkill: updateSkill,
      });
      this.setUpdateFields(ContentUploadHistory.FIELD_IMAGE);
    }
  }

  handleClearUploadVideo(i, videoLabel, e) {
    e.stopPropagation();

    $("#" + videoLabel + "View").attr("hidden", true);
    $("#" + videoLabel + "Hint").removeAttr("hidden");
    $("#" + videoLabel + "Next").removeAttr("hidden");
    // clear previous upload video
    $("#" + videoLabel + "File").val("");

    var updateSkill = this.state.editSkill;
    updateSkill.skillVideoFiles[i] = "";
    this.setState({
      disabledSubmit: this.validateInputFields(),
      editSkill: updateSkill,
    });
    this.setUpdateFields(ContentUploadHistory.FIELD_VIDEO);
    if (!this.updateVideoDetails.includes(i)) {
      this.updateVideoDetails.push(i);
    }
    const skill = this.state.editSkill;
    this.setState({
      editSkill: skill,
      disabledSubmit: this.validateInputFields(),
    });
    setTimeout(function () {
      var $source = $("#" + videoLabel + "Preview");
      $source[0].removeAttribute("src"); // empty source
      $source.parent()[0].load();
    }, 500);
  }

  addNewSelectedRobot(robotId) {
    var updateSkill = this.state.editSkill;
    var robotList = updateSkill.robotUuidList;
    if (robotList === undefined) {
      updateSkill["robotUuidList"] = [robotId];
    } else {
      robotList.push(robotId);
    }

    this.setState({
      editSkill: updateSkill,
    });
  }

  getRobotId(serial) {
    var robot = this.robotList.find((s) => s.vendorSerial === serial);
    if (robot !== undefined) {
      return robot.robotUuid;
    }
    return undefined;
  }

  getRobotSerial(robotId) {
    var robot = this.robotList.find((s) => s.robotUuid === robotId);
    if (robot !== undefined) {
      return robot.vendorSerial;
    }
    return undefined;
  }

  renderRobotList() {
    var robotListHtmlStr = [];
    if (this.state.editSkill !== null) {
      const robotList = this.state.editSkill.robotUuidList;
      const searchKey = this.state.searchBox;
      for (var index in robotList) {
        var robotId = robotList[index];
        if (robotId !== undefined) {
          const elementId = "input-robot-" + index;
          const searchBoxSuffix = "-search-result";

          var suggestedRobotList = [];
          if (
            searchKey !== null &&
            searchKey.id === elementId + searchBoxSuffix
          ) {
            suggestedRobotList = this.renderSearchBox(elementId, searchKey.key);
          }

          robotListHtmlStr.push(
            <Row className="pb-3" key={elementId}>
              <input
                className="modal-text-input form-control rounded-pill"
                id={elementId}
                type="search"
                autoComplete="off"
                defaultValue={this.getRobotSerial(robotId)}
                onClick={(e) => {
                  var mouseEventX =
                    e.nativeEvent.offsetX - $("#" + elementId).width();
                  if (mouseEventX > 0 && mouseEventX < 10) {
                    this.searchRobot(elementId + searchBoxSuffix, null);
                  } else {
                    this.searchRobot(elementId + searchBoxSuffix, "");
                  }
                }}
                onKeyUp={this.handleKeyEvent}
                onBlur={this.handleBlur}
              />
              <table id={elementId + searchBoxSuffix}>
                <tbody>{suggestedRobotList}</tbody>
              </table>
            </Row>
          );
        }
      }
    }
    return robotListHtmlStr;
  }

  validateInputFields() {
    const editSkill = this.state.editSkill;
    const checkVideoFiles = editSkill.skillVideoFiles.filter((item) => {
      return !!item;
    });
    if (
      $("#inputSkillName").val().length > 0 &&
      editSkill.skillCoverFile != null &&
      checkVideoFiles.length > 3 &&
      $("#categoryCheckboxes input:enabled:checked").length > 1 &&
      ($("#uploadAllRobotOption").is(":checked") ||
        $("#uploadPartialRobotOption").is(":checked"))
    ) {
      return false;
    }
    return true;
  }

  updateTaskName(name) {
    console.log("New name: " + name);
    var skill = this.state.editSkill;
    skill.name = name;

    this.setState({
      editSkill: skill,
      disabledSubmit: this.validateInputFields(),
    });
    this.setUpdateFields(ContentUploadHistory.FIELD_NAME);
  }

  tableRowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: rowIdx % 2 === 0 ? "white" : "var(--table-bg-green)",
      borderTop: "0px solid white",
      borderBottom: "0px solid white",
      height: "40px",
    };
  }

  loadVideos() {
    var $source = $("#fullVideoPreview");
    if ($source[0].src !== "") {
      $source.parent()[0].load();
    }
  }
  // deleteSkill(skillUuid, data) {
  //   console.log('skillUuid', skillUuid, 'data', data)
  //   const onOK = async() => {
  //    await request("/delete/skill", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${this.JWT}`,
  //       },
  //       data: {
  //         skillUuid: skillUuid,
  //       },
  //     }).then((res) => {
  //       if (res.code == 200) {
  //         message.success("Success!");
  //         this._getSkillRecords(this.JWT)
  //       } else {
  //         message.error(res.msg);
  //       }
  //     });
  //   }
  //   Modal.confirm({
  //     title: '刪除生活技能影片',
  //     width: 400,
  //     content: (
  //       <div style={{marginTop: '20px', fontSize: '16px'}}>
  //         <div>
  //           確定刪除生活技能影片<span style={{color: 'red', fontWeight: 600}}>{data.name}</span>?
  //         </div>
  //       </div>
  //     ),
  //     onOk: onOK
  //   })

  // }
  getVideoFile(videoFiles, i) {
    if (
      videoFiles !== null &&
      videoFiles[i] !== undefined &&
      videoFiles[i] !== ""
    ) {
      return videoFiles[i];
    }
    return undefined;
  }
  renderVideoSteps = () => {
    const n = 8;
    const skillList = this.state.skillRecordList;
    const editSkill = this.state.editSkill;
    const skillCoverImg =
      this.state.skillCoverImg != null
        ? this.state.skillCoverImg
        : editSkill != null
        ? editSkill.skillCoverFile
        : null;
    const skillVideoFiles =
      editSkill != null ? editSkill.skillVideoFiles : null;
    return (
      <UploadStep>
        <Row style={{ pointerEvents: this.state.readOnly ? "none" : "unset" }}>
          {[...Array(n)].map((item, index) => {
            const stepIndex = index + 1;
            return (
              <div className="uploadbox" key={index}>
                <div className="step">
                  <Trans>{`UploadMovieStep${stepIndex}`}</Trans>
                </div>
                <button
                  className="video-upload-btn rounded-0"
                  onClick={(e) => {
                    $(`#step${stepIndex}VideoFile`).trigger("click");
                  }}
                >
                  <div className="upload-content-wrap">
                    <span
                      className="close"
                      hidden={
                        this.state.readOnly ||
                        this.getVideoFile(skillVideoFiles, stepIndex) ===
                          undefined
                      }
                      onClick={this.handleClearUploadVideo.bind(
                        this,
                        stepIndex,
                        `step${stepIndex}Video`
                      )}
                    >
                      &times;
                    </span>
                    <div className="file-upload-container">
                      <div className="file-upload-hint">
                        <Trans>{`UploadMovieStep${stepIndex}`}</Trans>
                      </div>
                      <FontAwesomeIcon
                        id={`step${stepIndex}VideoHint`}
                        width="130px"
                        icon={faCloudArrowUp}
                        className="fa-5x"
                        hidden={
                          this.getVideoFile(skillVideoFiles, stepIndex) !==
                          undefined
                        }
                      />
                      <video
                        id={`step${stepIndex}VideoView`}
                        width="130px"
                        controls
                        hidden={
                          this.getVideoFile(skillVideoFiles, stepIndex) ===
                          undefined
                        }
                      >
                        <source
                          id={`step${stepIndex}VideoPreview`}
                          src={this.getVideoFile(skillVideoFiles, stepIndex)}
                        />
                      </video>
                    </div>
                  </div>
                </button>

                <Col id={`step${stepIndex}VideoNext`}>
                  <FontAwesomeIcon icon={faArrowRight} className="fa-2x pt-4" />
                </Col>

                <Form.Control
                  type="file"
                  id={`step${stepIndex}VideoFile`}
                  accept=".mp4"
                  hidden
                  onChange={this.handleChangeUploadVideo.bind(
                    this,
                    stepIndex,
                    `step${stepIndex}Video`
                  )}
                />
              </div>
            );
          })}
        </Row>
      </UploadStep>
    );
  };
  render() {
    const schoolName = SchoolStateSlice.getSchoolName(
      this.props[StoreState.SCHOOL]
    );

    const options = {
      paginationSize: 3,
      pageStartIndex: 1,
      withFirstAndLast: false, // Hide the going to First and Last page button
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "<<",
      prePageText: "<",
      nextPageText: ">",
      lastPageText: ">>",
      showTotal: false,
      disablePageTitle: true,
    };
    const columns = [
      {
        title: "生活技能名稱",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "日期及時間",
        dataIndex: "updateTime",
        key: "updateTime",
      },
      {
        title: "進度",
        dataIndex: "statusString",
        key: "statusString",
        render: (val, item) => {
          return val;
        },
      },
      // {
      //   title: "操作",
      //   dataIndex: "id",
      //   render: (value, data) => (
      //     <div className="table-operate">
      //       <button
      //         className="delete-btn"
      //         onClick={() => this.deleteSkill(value, data)}
      //       >
      //         刪除
      //       </button>
      //     </div>
      //   ),
      // },
    ];
    const skillList = this.state.skillRecordList;
    const editSkill = this.state.editSkill;
    const skillCoverImg =
      this.state.skillCoverImg != null
        ? this.state.skillCoverImg
        : editSkill != null
        ? editSkill.skillCoverFile
        : null;
    const skillVideoFiles =
      editSkill != null ? editSkill.skillVideoFiles : null;

    return (
      <div className="h-100">
        <Modal
          id="robotListModal"
          open={this.state.showRobotList}
          onCancel={() => {
            this.setRobotListShow();
          }}
          style={{ top: "25%" }}
          destroyOnClose
          footer={null}
        >
          <div
            className="modal-content p-4"
            style={{ textAlign: "center", overflowY: "auto", height: "400px" }}
          >
            <Container>
              <Row className="mt-4 pb-3">
                <div className="modal-field-name">
                  <Trans>UploadInputRobotUuidHint</Trans>
                </div>
              </Row>
              {this.renderRobotList()}
              <Row className="pb-3">
                <button
                  className="btn btn-light"
                  onClick={() => this.addNewSelectedRobot("")}
                >
                  +
                </button>
              </Row>
              <Row className="pb-3">
                <button
                  className="modal-btn rounded-pill border-light"
                  onClick={() => this.setRobotListShow()}
                >
                  <Trans>Confirm</Trans>
                </button>
              </Row>
            </Container>
          </div>
        </Modal>
        <Modal
          id="uploadModal"
          size="xl"
          open={this.state.show}
          destroyOnClose
          onOk={() => {
            this.loadVideos();
          }}
          width="60vw"
          onCancel={() => {
            this.handleModalHide();
          }}
          footer={null}
          keyboard={false}
        >
          <div className="modal-content">
            <ModalHead>
              <span className="align-bottom">{schoolName}</span>
              <button
                type="button"
                className="close"
                hidden
                onClick={() => this.handleModalHide()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHead>
            <Spin
              spinning={this.state.spinning}
              size="large"
              style={{ bottom: "10%", top: "auto" }}
              tip={`${this.state.upLoadTitle}:${this.state.upLoadProgress}%`}
            >
              <div className="modal-body">
                <Container id="uploadContent">
                  <ContentBox>
                    <Row
                      style={{
                        pointerEvents: this.state.readOnly ? "none" : "unset",
                      }}
                    >
                      <div className="pageTitle">
                        <Trans>UploadSkillName</Trans>
                      </div>

                      <input
                        id="inputSkillName"
                        className="inputSkill"
                        type="text"
                        value={editSkill != null ? editSkill.name : ""}
                        onChange={(e) => this.updateTaskName(e.target.value)}
                      ></input>
                    </Row>
                    <Row
                      style={{
                        pointerEvents: this.state.readOnly ? "none" : "unset",
                      }}
                    >
                      <div className="pageTitle">
                        <Trans>UploadSkillCategory</Trans>
                      </div>
                      <Container id="categoryCheckboxes">
                        {this.renderSkillCategory()}
                      </Container>
                    </Row>
                    <div className="pageTitle">
                      <Trans>UploadStepsHints</Trans>
                    </div>
                    {this.renderVideoSteps()}
                    <Row
                      style={{
                        pointerEvents: this.state.readOnly ? "none" : "unset",
                      }}
                    >
                      <div className="pageTitle">
                        <Trans>UploadSkillImage</Trans>
                      </div>
                      <Col>
                        <button
                          className="video-upload-btn rounded-0"
                          onClick={(e) => {
                            $("#file-upload-image").trigger("click");
                          }}
                        >
                          <div className="file-upload-container">
                            <div className="file-upload-hint">
                              <Trans>UploadCoverImage</Trans>
                            </div>
                            <img
                              src={skillCoverImg}
                              alt="img"
                              style={{
                                padding: "0px",
                                margin: "0px",
                                width: "140px",
                              }}
                              hidden={skillCoverImg === null}
                            />
                            <FontAwesomeIcon
                              width="130px"
                              icon={faCloudArrowUp}
                              className="fa-5x"
                              hidden={skillCoverImg !== null}
                            />
                          </div>
                        </button>
                        <Form.Control
                          type="file"
                          id="file-upload-image"
                          accept=".jpeg, .jpg, .png"
                          onChange={this.handleChangeCoverImage.bind(this)}
                          hidden
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <div className="pageTitle">
                        <Trans>UploadSkillComment</Trans>
                      </div>

                      <Col>
                        <div
                          id="comment-history"
                          style={{
                            paddingTop: "4px",
                            maxHeight: "260px",
                            overflowY: "auto",
                          }}
                        >
                          {this.renderCommentText()}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      className="upload-modal-row justify-content-md-center mt-4 pt-3 pb-3"
                      style={{
                        pointerEvents: this.state.readOnly ? "none" : "unset",
                      }}
                    >
                      <Col xs={3}></Col>
                      <Col xs={6}>
                        <div className="modal-field-name pb-3">
                          <input
                            className="form-check-input"
                            id="uploadAllRobotOption"
                            type="checkbox"
                            onChange={() => this.uncheckPartialRobotOption()}
                            checked={this.state.checkApplyAllRobot}
                          ></input>
                          <label
                            className="form-check-label"
                            style={{
                              paddingLeft: "8px",
                              display: "inline-block",
                            }}
                          >
                            <Trans>UploadSkillVideoAgreement</Trans>
                          </label>
                        </div>
                        <div className="modal-field-name pb-3">
                          <input
                            className="form-check-input"
                            id="uploadPartialRobotOption"
                            type="checkbox"
                            onChange={() => this.setRobotListShow()}
                            checked={!this.state.checkApplyAllRobot}
                          ></input>
                          <label
                            className="form-check-label"
                            style={{
                              paddingLeft: "8px",
                              display: "inline-block",
                            }}
                          >
                            <Trans>UploadPartialRobotOption</Trans>
                          </label>
                        </div>
                      </Col>
                      <Col xs={2}></Col>
                    </Row>
                    {/* <Row className="pb-3" id="skillSubmissionStatus" hidden>
                      <Col xs={4}></Col>
                      <Col xs={2}>
                        <div>
                          <Spinner animation="border" role="status" />
                          <span className="p-2">
                            <Trans>UploadingSkillMaterial</Trans>
                          </span>
                        </div>
                      </Col>
                      <Col xs={2}></Col>
                      <Col xs={2}></Col>
                    </Row> */}
                    <Row className="pt-3 pb-3">
                      <Col xs={4}></Col>
                      <Col xs={2}>
                        <button
                          id="submitButton"
                          className="modal-btn rounded-pill border-light"
                          onClick={() => this.submitSkill()}
                          // disabled={this.state.disabledSubmit}
                        >
                          <Trans>Submit</Trans>
                        </button>
                      </Col>
                      <Col xs={2}>
                        <button
                          id="cancelButton"
                          className="modal-btn rounded-pill border-light"
                          onClick={() => this.handleModalHide()}
                        >
                          <Trans>Cancel</Trans>
                        </button>
                      </Col>
                      <Col xs={2}></Col>
                    </Row>
                  </ContentBox>
                </Container>
                <Container id="resultContent" hidden>
                  <div style={{ textAlign: "center", padding: "200px" }}>
                    <div className="modal-field-name mb-3">
                      <Trans>UploadSkillMaterialSuccessfully</Trans>
                    </div>
                    <button
                      className="modal-btn rounded-pill border-light mb-3"
                      onClick={() => this.handleModalHide()}
                    >
                      <Trans>GoToMain</Trans>
                    </button>
                    <div className="modal-field-hint" style={{ color: "red" }}>
                      <Trans>RedirectBackToMainPage</Trans>
                    </div>
                  </div>
                </Container>
              </div>
            </Spin>
          </div>
        </Modal>
        <div className="h-100 pb-5">
          <Container>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src="/images/upload_history_title.png"
                alt=""
                height="150px"
              />
            </div>
            <Row>
              <AntdTable>
                <Table dataSource={skillList} columns={columns} />
              </AntdTable>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  async submitSkill() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    // show status message and disabled buttons
    $("#submitButton").attr("disabled", true);
    $("#cancelButton").attr("disabled", true);
    // $("#skillSubmissionStatus").removeAttr("hidden");

    const editSkill = this.state.editSkill;
    var robotList = [];
    if (
      this.updateFields.includes(ContentUploadHistory.FIELD_ROBOT) &&
      this.state.checkApplyAllRobot === false
    ) {
      if (
        $("#uploadPartialRobotOption").is(":checked") &&
        editSkill.robotUuidList
      ) {
        const selectedRobotList = editSkill.robotUuidList;
        for (i = 0; i < selectedRobotList.length; i++) {
          if (
            selectedRobotList[i] !== "" &&
            selectedRobotList[i] !== undefined
          ) {
            robotList.push(selectedRobotList[i]);
          }
        }
      }
    }
    if (!robotList.length && !this.state.checkApplyAllRobot) {
      message.error("請選擇機器人唯一識別碼");
      $("#submitButton").attr("disabled", false);
      $("#cancelButton").attr("disabled", false);
      // $("#skillSubmissionStatus").attr("hidden", true);
      return;
    }
    this.setState({ spinning: true });
    const client = new OSS({
      accessKeySecret: "Sv2MIjQucpGG2RBNJZ92bTU5pCThDi",
      bucket: "talkbo-enci",
      region: "oss-cn-hongkong",
      accessKeyId: "LTAI5t7stBYpycYHk8vGvXhm",
      timeout: "3600000",
    });
    const partSize = 1024 * 1024 * 5;
    const ossDirectory =
      // eslint-disable-next-line no-undef
      process.env.NODE_ENV === "development" ? "devVideo" : "video";
    console.log("skillVideoFiles", editSkill.skillVideoFiles);
    var updateVideoFiles = [editSkill.skillVideoFiles.length];
    if (this.updateFields.includes(ContentUploadHistory.FIELD_VIDEO)) {
      for (var i = 0; i < editSkill.skillVideoFiles.length; i++) {
        if (this.updateVideoDetails.includes(i)) {
          this.setState({ upLoadTitle: editSkill.skillVideoFiles[i].name });
          await client
            .multipartUpload(
              `${ossDirectory}/${editSkill.skillVideoFiles[i].name}`,
              editSkill.skillVideoFiles[i],
              {
                parallel: editSkill.skillVideoFiles[i].size / partSize,
                partSize: partSize,
                progress: (p, cpt, res) => {
                  this.setState({ upLoadProgress: (p * 100).toFixed(1) });
                },
              }
            )
            .then((res) => {
              if (res.res.status == 200) {
                const aliossLink = res.res.requestUrls[0] || "";
                const targetIndex = aliossLink.indexOf("?uploadId");
                console.log("aliossLink", aliossLink);
                if (targetIndex !== -1) {
                  updateVideoFiles[i] = aliossLink.split("?")[0];
                } else {
                  updateVideoFiles[i] = aliossLink;
                }
              }
            });
          if (editSkill.skillVideoFiles[i] === "") {
            SkillMaterialManager.getInstance().deleteMaterialAsync(
              jwt,
              editSkill.skillMaterialIds[i]
            );
          }
        } else {
          updateVideoFiles[i] = null;
        }
      }
    }

    const postSkillResponse = await SkillManager.getInstance().putSkillAsync(
      jwt,
      editSkill.id,
      this.updateFields.includes(ContentUploadHistory.FIELD_NAME)
        ? editSkill.name
        : null,
      this.updateFields.includes(ContentUploadHistory.FIELD_CATAGORY)
        ? editSkill.categoryItemList
        : null,
      this.updateFields.includes(ContentUploadHistory.FIELD_ROBOT)
        ? this.state.checkApplyAllRobot
          ? null
          : robotList
        : undefined,
      this.updateFields.includes(ContentUploadHistory.FIELD_VIDEO)
        ? updateVideoFiles
        : null,
      this.updateFields.includes(ContentUploadHistory.FIELD_IMAGE)
        ? editSkill.skillCoverFile
        : null,
      null,
      null,
      null
    );

    const updateStatusResponse =
      await SkillStatusManager.getInstance().putSkillStatusAsync(
        jwt,
        editSkill.id,
        SkillStatus.SUBMIT_REVIEW
      );
    if (updateStatusResponse.errorName !== null) {
      console.log("Error in update skill status");
    }

    // reset buttons and remove status message
    $("#submitButton").attr("disabled", false);
    $("#cancelButton").attr("disabled", false);
    // $("#skillSubmissionStatus").attr("hidden", true);

    if (!postSkillResponse.errorName) {
      $("#resultContent").removeAttr("hidden");
      $("#uploadContent").attr("hidden", true);
      this.setState({ spinning: false });
      setTimeout(
        function () {
          this.handleModalHide();
        }.bind(this),
        10000
      );
    } else {
      this.setState({ spinning: false });
      alert(postSkillResponse.errorName);
    }
  }
}

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(ContentUploadHistory);
