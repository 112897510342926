import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SpinnerBar from "../SpinnerBar";
import RobotManager from "../../backend/RobotManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import StoreState from "../../redux/StoreState";
import PfnKey from "../../PfnKey";
import { withRouter } from "../../withRouter";
import styled from "styled-components";
import ContentPlayHistorySkillSelection from "../ContentPlayHistorySkillSelection";
import { Pagination } from "antd";
const RobotSelectionBox = styled.div`
  margin-top: 40px;

  height: 480px;
  .robotContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .box {
      display: flex;
      height: 80px;
      border: 1px solid #ccc;
      background: #fff;
      justify-content: center;
      align-items: center;
      .item {
        display: flex;
        justify-content: initial;
        align-items: center;
        color: #fff;
        background: #00a0e9;
        font-weight: bold;
        padding: 5px 20px;
        transition: 0.5s;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        max-width: 250px;
        cursor: pointer;
        img {
          width: 30px;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          user-select: none;
        }
      }
    }
  }
`;
const VideoPlayBox = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  h2 {
    margin: 10px 0 20px 0;
    font-weight: 600;
  }
  .selectitems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .active {
      filter: unset;
      transform: unset;
      img {
        width: 100%;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }
    div {
      filter: brightness(0.5) blur(0.5px) opacity(80%);
      cursor: pointer;
      transform: scale(0.85);
      transition: 0.5s;
      img {
        width: 100%;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }
  }
`;
const RoundPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
`;
const ContentPlayHistoryRobotSelection = (props) => {
  const [loading, setLoading] = useState(true);
  const [robotList, setRobotList] = useState([]);
  const [pagRobList, setPagRobList] = useState([]);
  const [item, setItem] = useState("機械人");
  const [page, setPage] = useState("1");
  const [current, setCurrent] = useState(1);
  const _getRobotsAsync = async () => {
    if (loading) {
      const loginState = props[StoreState.LOGIN];

      const res = await RobotManager.getInstance().getRobotsAsync(
        LoginStateSlice.getJwt(loginState)
      );

      if (!res.errorName && res.data) {
        setRobotList(res.data);
        const sliceRobList = res.data.slice(0, 24);
        setPagRobList(sliceRobList);
      }

      setLoading(false);
    }
  };

  const _onCellClick = (robotUuid) => {
    const { pathname } = props.location;
    const params = {};

    params[PfnKey.KEY_ROBOT_UUID] = robotUuid;

    props.navigate(pathname + "?" + new URLSearchParams(params));
  };
  const Top = ({ item, setItem, page, setPage }) => {
    return (
      <div className="selectitems">
        <div
          className={item === "機械人" ? "active" : ""}
          onClick={() => {
            setItem("機械人");
            setPage("1");
          }}
        >
          <img src="img-05.png" alt="" />
        </div>
        <div
          className={item === "生活技能" ? "active" : ""}
          onClick={() => {
            setItem("生活技能");
            setPage("3");
          }}
        >
          <img src="img-06.png" alt="" />
        </div>
      </div>
    );
  };
  const robotSelection = () => (
    <React.Fragment>
      <RobotSelectionBox>
        <div className="robotContainer">
          {pagRobList &&
            pagRobList.map((robot, i) => (
              <div className="box" key={robot.robotUuid}>
                <div
                  className="item"
                  onClick={() => _onCellClick(robot.robotUuid)}
                >
                  <img src="img-07.png" />
                  <span> {robot.robotName}</span>
                </div>
              </div>
            ))}
          {spinnerBar}
        </div>
      </RobotSelectionBox>
      <RoundPagination>
        <Pagination
          total={robotList.length}
          // total={1000}
          current={current}
          onChange={paginationChange}
          pageSize={24}
          showSizeChanger={false}
        />
      </RoundPagination>
    </React.Fragment>
  );
  const paginationChange = (page) => {
    setCurrent(page);
    if (robotList.length) {
      const sliceArr = robotList.slice((page - 1) * 24, 24 * page);
      setPagRobList(sliceArr);
    }
  };
  useEffect(() => {
    _getRobotsAsync();
  }, []);
  const spinnerBar = loading ? <SpinnerBar /> : "";
  return (
    <VideoPlayBox>
      <h2>影片觀看次數({item})</h2>
      <Top item={item} setItem={setItem} page={page} setPage={setPage} />
      {(() => {
        switch (page) {
          case "2":
            return robotSelection();
          case "3":
            return <ContentPlayHistorySkillSelection />;
          default:
            return robotSelection();
        }
      })()}
    </VideoPlayBox>
  );
};
function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];

  return props;
}

export default withRouter(
  connect(mapStateToProps)(ContentPlayHistoryRobotSelection)
);
