import Api from "./Api.js";
import AxiosHelper from "./AxiosHelper.js";
import PfnKey from "../PfnKey.js";

class UserManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new UserManager();
    }
    return this._instance;
  }

  // role definition must be match with the definition in backend
  static get UserRole() {
    return Object.freeze({
      ADMIN     : "ADMIN", 
      AUDITOR   : "AUDITOR", 
      SCHOOL    : "SCHOOL", 
      STUDENT   : "STUDENT", 
      TEACHER   : "TEACHER"
    });
  }

  async getUsersAsync(
    jwt,
    schoolUuid = null,
    role = null,
    partialUserName = null,
    partialSchoolName = null,
    locale = null,
    noRobot = null,
    noSchool = null,
    withRobot = null,
    withSchool = null,
    status = null,
    limit = null,
    offset = null
  ) {
    console.log(`Getting user list, arg:`);

    let arg = {};
    if (schoolUuid) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (role) arg[PfnKey.KEY_PARAM_ROLE] = role;
    if (partialUserName) arg[PfnKey.KEY_PARAM_PARTIAL_USER_NAME] = partialUserName;
    if (partialSchoolName) arg[PfnKey.KEY_PARAM_PARTIAL_SCHOOL_NAME] = partialSchoolName;
    if (locale) arg[PfnKey.KEY_PARAM_LOCALE] = locale;
    if (noRobot !== null) arg[PfnKey.KEY_PARAM_NO_ROBOT] = noRobot;
    if (noSchool !== null) arg[PfnKey.KEY_PARAM_NO_SCHOOL] = noSchool;
    if (withRobot !== null) arg[PfnKey.KEY_PARAM_WITH_ROBOT] = withRobot;
    if (withSchool !== null) arg[PfnKey.KEY_PARAM_WITH_SCHOOL] = withSchool;
    if (status !== null) arg[PfnKey.KEY_STATUS] = status;
    if (limit || limit === 0) arg[PfnKey.KEY_PARAM_LIMIT] = limit;
    if (offset || offset === 0) arg[PfnKey.KEY_PARAM_OFFSET] = offset;
    
    console.log(arg);

    const response = await AxiosHelper.axoisGetAsync(Api.USERS, arg, jwt);

    console.log(`Get user list ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async postUserAsync(
    jwt,
    schoolUuid = null,
    role = null,
    password = null,
    displayName = null,
    email = null
  ) {
    console.log(`Post user, arg:`);

    let arg = {};
    if (schoolUuid) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (role) arg[PfnKey.KEY_PARAM_ROLE] = role;
    if (password) arg[PfnKey.KEY_PASSWORD] = password;
    if (displayName) arg[PfnKey.KEY_USER_DISPLAY_NAME] = displayName;
    if (email) arg[PfnKey.KEY_USER_EMAIL] = email;

    console.log(arg);

    const response = await AxiosHelper.axoisPostAsync(Api.USERS, arg, jwt);

    console.log(`Post user ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async putUserAsync(
    userUuid,
    jwt,
    schoolUuid = null,
    role = null,
    displayName = null,
    email = null
  ) {
    console.log(`Put user, arg:`);
    let route = "";
    if (userUuid) {
      route += `/${userUuid}`;
    }

    let arg = {};
    if (schoolUuid) arg[PfnKey.KEY_SCHOOL_UUID] = schoolUuid;
    if (role) arg[PfnKey.KEY_PARAM_ROLE] = role;
    if (displayName) arg[PfnKey.KEY_USER_DISPLAY_NAME] = displayName;
    if (email) arg[PfnKey.KEY_USER_EMAIL] = email;

    console.log(arg);

    const response = await AxiosHelper.axoisPutAsync(Api.USERS + route, arg, jwt);

    console.log(`Put user ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }

  async updateUserStatusAsync(
    userUuid,
    jwt,
    enabled
  ) {
    console.log(`Put user status, arg:`);
    let route = "";
    if (userUuid) {
      route += `/${userUuid}`;
    }

    let arg = {};
    if (enabled) {
      arg[PfnKey.KEY_STATUS] = "NORMAL";
    } else {
      arg[PfnKey.KEY_STATUS] = "DISABLED";
    }

    console.log(arg);

    const response = await AxiosHelper.axoisPutAsync(Api.USERS + route, arg, jwt);

    console.log(`Update user status ${response.errorName ? ("failed [" + response.errorName + "]") : "succeeded"}!`);

    return response;
  }
}

export default UserManager;
