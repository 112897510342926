import React from "react";
import $ from "jquery";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { Radio } from "antd";
import SkillManager from "../../backend/SkillManager";
import RobotManager from "../../backend/RobotManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import SchoolStateSlice from "../../redux/SchoolStateSlice";
import StoreState from "../../redux/StoreState";
import { styled } from "styled-components";
import { message, Modal as AntdModal, Progress } from "antd";
import AppRoute from "../../AppRoute";
import { withRouter } from "../../withRouter";
import OSS from "ali-oss";
const Forcebox = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .force_list {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin: 10px 0;
    gap: 10px;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const UploadStep = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  .uploadbox {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #fff;
    .step {
      position: absolute;
      padding: 5px;
      background: rgba(133, 51, 51, 0.2);
    }
  }
`;
const ContentBox = styled.div`
  .pageTitle {
    font-size: large;
    color: #fff;
    margin: 20px 0;
    padding: 8px;
    border-radius: 5px;
    background: #00a0e9;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .inputSkill {
    height: 40px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px;
    padding-left: 10px;
  }

  .uploadstep {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .uploadbox {
      position: relative;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      color: #fff;
    }
  }

  .submit-opinion {
    display: flex;
    justify-content: right;

    .btn-left {
      background: #b6cc9b;
      color: #fff;
      font-weight: bold;
      padding: 8px 36px;
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.5s;
      border: 0;
      margin-top: 30px;
    }

    .btn-right {
      margin-left: 30px;
      background: rgb(0, 160, 233);
    }
  }

  .upload-two-code {
    color: black;
    background-color: white;
    font-weight: bold;
    border: 1px solid #dee2e6;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 20px 4px 2px 4px;
    width: 200px;
    height: 150px;

    .upload-content-wrap {
      position: relative;
      vertical-align: top;
      padding: 2px;
      margin: 2px;

      .close {
        position: absolute;
        display: inline-block;
        width: 25px;
        height: 25px;
        padding-left: 2px;
        line-height: 0.7;
        color: floralwhite;
        text-shadow: 1px 1px #282c34;
        border-radius: 50%;
        background-color: rgb(99, 99, 99, 0.5);
        font-size: 28px;
        top: 2px;
        right: 2px;
        z-index: 100;
      }
    }

    .file-upload-container {
      position: relative;
      text-align: center;
      color: gray;

      .file-upload-hint {
        position: absolute;
        top: -20px;
        left: 40px;
        color: black;
      }
    }
  }
  .submit-tow-code {
    background: rgb(0, 160, 233);
    color: #fff;
    font-weight: bold;
    width: 125px;
    height: 38px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.5s;
    border: 0;
    margin-top: 30px;
    margin-left: calc(100% - 130px);
  }
  .pageline {
    height: 1px;
    background: #ccc;
    margin: 30px 0;
  }
  .btnBox {
    display: flex;
    justify-content: center;
    .submitBtn {
      background: #b6cc9b;
      color: #fff;
      font-weight: bold;
      /* padding: 8px 36px; */
      border-radius: 8px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      border: 0;
      transition: 0.5s;
      width: 100px;
      height: 40px;
      /* margin-right: 15px; */
      margin-bottom: 20px;
    }
    .cancelBtn {
      margin-left: 15px;
    }
  }
`;
class ContentUpload extends React.Component {
  constructor() {
    super();

    this.closeModalTask = null;
    this.robotList = [];
    this.state = {
      show: false,
      disabledSubmit: true,
      searchBox: null,
      showRobotList: false,
      selectedRobots: [],
      skillVideoFiles: [],
      skillCoverFile: null,
      skillCoverImg: null,
      skillQrCodeFile: null,
      skillQrCodeImg: null,
      skillCategories: {},
      skillCheckBoxSelect: "",
      upLoadModal: false,
      upLoadTitle: "完整視頻...",
      upLoadProgress: 0,
      skillVideoUrls: [],
    };
  }

  componentDidMount() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    this._getSkillCategories(jwt);
    this._getRobots(jwt);
  }

  handleKeyEvent = (event) => {
    var searchKey = event.target.value;
    const searchBoxSuffix = "-search-result";
    const searchBoxId = event.target.id + searchBoxSuffix;
    if (event.type === "keyup") {
      if (searchKey !== "") {
        this.searchRobot(searchBoxId, searchKey);
      } else {
        $("#" + searchBoxId).css("display", "none");
      }
    }
  };

  handleBlur = (event) => {
    const searchBoxSuffix = "-search-result";
    const searchBoxId = event.target.id + searchBoxSuffix;
    setTimeout(function () {
      $("#" + searchBoxId).css("display", "none");
    }, 200);
  };

  getOptions(catId, name, values) {
    const optionId = catId + "-";
    var categoryInput = [];
    categoryInput.push(
      <div className="form-check" name="skill-category" key={catId}>
        <input
          id={catId}
          name={catId}
          type="checkbox"
          onChange={(e) => {
            $('[id^="' + optionId + '"]').attr("disabled", !e.target.checked);

            var options = document.getElementsByName("skill-category");
            for (var i = 0; i < options.length; i++) {
              var input = options[i].getElementsByTagName("input");
              if (
                input !== undefined &&
                input[0].checked &&
                input[0].name !== catId
              ) {
                input[0].checked = false;
                $('[id^="' + input[0].id + '-"]').attr("disabled", true);
              }
            }

            this.validateInputFields();
          }}
        ></input>
        <label
          className="modal-options"
          style={{ padding: "2px", display: "inline-block" }}
        >
          {name}
        </label>
      </div>
    );
    for (var v in values) {
      categoryInput.push(
        <div className="form-check" name="skill-category" key={v}>
          <input
            className="modal-custom-checkbox"
            id={optionId + v}
            name={values[v].uid}
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                $('[id^="' + optionId + '"]').prop("checked", false);
                $("#" + e.target.id).prop("checked", true);
              }
              this.validateInputFields();
            }}
            disabled
          ></input>
          &nbsp;
          <label
            className="modal-options"
            style={{ padding: "2px", display: "inline-block" }}
          >
            {values[v].name}
          </label>
        </div>
      );
    }
    return (
      <Col xs={3} key={catId}>
        {categoryInput}
      </Col>
    );
  }

  async _getSkillCategories(jwt) {
    var categoryArray = {};
    const schoolInfoResponse =
      await SkillManager.getInstance().getSkillCategoriesAsync(jwt);
    if (Object.keys(schoolInfoResponse.data).length > 1) {
      schoolInfoResponse.data.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });
    }

    for (var category of schoolInfoResponse.data) {
      const name = category.categoryName;
      const itemList = category.itemList;
      itemList.sort(function (a, b) {
        return a.displaySeq > b.displaySeq;
      });

      var data = [];
      for (var item of itemList) {
        data.push({
          uid: item.itemUuid,
          name: item.itemName,
        });
      }
      categoryArray[name] = {
        uid: category.categoryUuid,
        items: data,
      };
    }

    this.setState({
      skillCategories: categoryArray,
    });
  }

  async _getRobots(jwt) {
    const getRobotsResponse = await RobotManager.getInstance().getRobotsAsync(
      jwt,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "NORMAL",
      null,
      null
    );
    const robotData = getRobotsResponse.data;

    this.robotList = robotData;
  }
  RadioGroupChange(value, key) {
    this.setState({ skillCheckBoxSelect: value });
  }
  renderSkillCategory() {
    const imgObj = {
      衣: "06-03.png",
      食: "06-04.png",
      住: "06-05.png",
      行: "06-06.png",
    };
    const map = this.state.skillCategories;
    const checkBoxs = Object.keys(map).map((key) => {
      const option = [];
      map[key].items.forEach((item) => {
        option.push({
          label: item.name,
          value: item.uid,
        });
      });
      return (
        <div key={map[key].uid} className="force_list">
          <div>
            <img src={imgObj[key]} style={{ width: "100%" }} />
          </div>
          <Radio.Group
            options={option}
            value={this.state.skillCheckBoxSelect}
            onChange={(e) =>
              this.RadioGroupChange(e.target.value, map[key].uid)
            }
            style={{ width: "200px", display: "grid" }}
          />
        </div>
      );
    });
    return checkBoxs;
  }

  setShow(s) {
    if (!s && this.closeModalTask !== null) {
      clearTimeout(this.closeModalTask);
      this.closeModalTask = null;
    }
    this.setState({ show: s });
  }

  // resetState() {
  //   if (this.closeModalTask !== null) {
  //     clearTimeout(this.closeModalTask);
  //     this.closeModalTask = null;
  //   }
  //   this.setState({
  //     show: false,
  //     disabledSubmit: true,
  //     searchBox: null,
  //     showRobotList: false,
  //     selectedRobots: [],
  //     skillVideoFiles: [],
  //     skillCoverFile: null,
  //     skillCoverImg: null,
  //     skillQrCodeFile: null,
  //     skillQrCodeImg: null,
  //   });
  // }

  uncheckPartialRobotOption() {
    $("#uploadPartialRobotOption").prop("checked", false);
    this.validateInputFields();
  }

  setRobotListShow() {
    if ($("#uploadPartialRobotOption").is(":checked")) {
      $("#uploadAllRobotOption").prop("checked", false);
      const status = this.state.showRobotList;
      this.setState({
        showRobotList: !status,
      });
    }
    this.validateInputFields();
  }

  setRobotSelected(elementId, robotSerial, robotId) {
    $("#" + elementId).val(robotSerial);

    var index = elementId.replace(/[^0-9]/g, "");
    var robotList = this.state.selectedRobots;
    robotList[index] = robotId;

    this.setState({
      selectedRobots: robotList,
      searchBox: null,
    });
  }

  searchRobot(elementId, searchKey) {
    const searchBoxSuffix = "-search-result";
    const inputElement = elementId.replace(searchBoxSuffix, "");
    if (searchKey === null) {
      const robotSerial = $("#" + inputElement).val();
      var robotId = this.getRobotId(robotSerial);
      if (robotId !== undefined) {
        var index = inputElement.replace(/[^0-9]/g, "");
        var robotList = this.state.selectedRobots;
        robotList[index] = undefined;

        this.setState({
          selectedRobots: robotList,
        });
      }
    }

    this.setState({
      searchBox: {
        id: elementId,
        key: searchKey,
      },
    });

    $("#" + elementId).css("display", "block");
  }

  renderSearchBox(elementId, searchKey) {
    var data = [];
    if (searchKey !== null) {
      const selectedRobots = this.state.selectedRobots;
      for (var robot of this.robotList) {
        const robotSerial = robot.vendorSerial;
        const robotId = robot.robotUuid;
        if (
          robotId.includes(searchKey) &&
          selectedRobots !== null &&
          !selectedRobots.includes(robotId)
        ) {
          data.push(
            <tr
              key={robotId}
              className="m-2"
              style={{ height: "20px" }}
              onClick={() =>
                this.setRobotSelected(elementId, robotSerial, robotId)
              }
            >
              <td>{robotSerial}</td>
            </tr>
          );
        }
      }
    }
    return data;
  }

  handleChangeUploadVideo(i, videoLabel, e) {
    if (e.target.files[0] !== undefined) {
      $("#" + videoLabel + "View").removeAttr("hidden");
      $("#" + videoLabel + "Hint").attr("hidden", true);
      //$('#' + videoLabel + 'Next').attr("hidden", true);

      var videoFiles = this.state.skillVideoFiles;
      videoFiles[i] = e.target.files[0];
      this.setState({
        skillVideoFiles: videoFiles,
      });

      var $source = $("#" + videoLabel + "Preview");
      $source[0].src = URL.createObjectURL(e.target.files[0]);
      $source.parent()[0].load();
      this.validateInputFieldsWithFilelist(
        this.state.skillCoverFile,
        videoFiles
      );
    }
  }

  checkVideoSelected(i) {
    const skillVideoFiles = this.state.skillVideoFiles;
    if (skillVideoFiles !== undefined && skillVideoFiles[i] !== undefined) {
      return true;
    }
    return false;
  }

  handleClearUploadVideo(i, videoLabel, e) {
    e.stopPropagation();

    $("#" + videoLabel + "View").attr("hidden", true);
    $("#" + videoLabel + "Hint").removeAttr("hidden");
    //$('#' + videoLabel + 'Next').removeAttr('hidden');
    // clear previous upload video
    $("#" + videoLabel + "File").val("");

    var videoFiles = this.state.skillVideoFiles;
    videoFiles[i] = undefined;
    this.setState({
      skillVideoFiles: videoFiles,
    });

    var $source = $("#" + videoLabel + "Preview");
    $source[0].src = "";

    this.validateInputFieldsWithFilelist(this.state.skillCoverFile, videoFiles);
  }

  handleClearCoverImage(e, id) {
    e.stopPropagation();

    $("#uploadPreview").attr("hidden", true);
    $("#uploadHint").removeAttr("hidden");
    // clear previous upload cover image
    $("#" + id).val("");

    this.setState({
      skillCoverFile: null,
      skillCoverImg: "",
    });

    this.validateInputFieldsWithFilelist(null, this.state.skillVideoFiles);
  }

  handleChangeCoverImage(e) {
    if (e.target.files[0] !== undefined) {
      $("#uploadPreview").removeAttr("hidden");
      $("#uploadHint").attr("hidden", true);

      this.setState({
        skillCoverFile: e.target.files[0],
        skillCoverImg: URL.createObjectURL(e.target.files[0]),
      });

      this.validateInputFieldsWithFilelist(
        e.target.files[0],
        this.state.skillVideoFiles
      );
    }
  }

  handleClearQrCodeImage(e, id) {
    e.stopPropagation();

    $("#qrCodePreview").attr("hidden", true);
    $("#qrCodeHint").removeAttr("hidden");
    // clear previous upload QR Code image
    $("#" + id).val("");

    this.setState({
      skillQrCodeFile: null,
      skillQrCodeImg: "",
    });
  }

  handleChangeQrCodeImage(e) {
    if (e.target.files[0] !== undefined) {
      $("#qrCodePreview").removeAttr("hidden");
      $("#qrCodeHint").attr("hidden", true);

      this.setState({
        skillQrCodeFile: e.target.files[0],
        skillQrCodeImg: URL.createObjectURL(e.target.files[0]),
      });
    }
  }

  addNewSelectedRobot(robotId) {
    var robotList = this.state.selectedRobots;
    robotList.push(robotId);

    this.setState({
      selectedRobots: robotList,
    });
  }

  getRobotId(serial) {
    var robot = this.robotList.find((s) => s.vendorSerial === serial);
    if (robot !== undefined) {
      return robot.robotUuid;
    }
    return undefined;
  }

  getRobotSerial(robotId) {
    var robot = this.robotList.find((s) => s.robotUuid === robotId);
    if (robot !== undefined) {
      return robot.vendorSerial;
    }
    return undefined;
  }

  renderRobotList() {
    var robotListHtmlStr = [];
    const robotList = this.state.selectedRobots;
    const searchKey = this.state.searchBox;
    robotList.forEach((robotId, index) => {
      if (robotId !== undefined) {
        const elementId = "input-robot-" + index;
        const searchBoxSuffix = "-search-result";

        var suggestedRobotList = [];
        if (
          searchKey !== null &&
          searchKey.id === elementId + searchBoxSuffix
        ) {
          suggestedRobotList = this.renderSearchBox(elementId, searchKey.key);
        }

        robotListHtmlStr.push(
          <Row className="pb-3" key={elementId}>
            <input
              className="modal-text-input form-control rounded-pill"
              id={elementId}
              type="search"
              autoComplete="off"
              defaultValue={this.getRobotSerial(robotId)}
              onClick={(e) => {
                var mouseEventX =
                  e.nativeEvent.offsetX - $("#" + elementId).width();
                if (mouseEventX > 0 && mouseEventX < 10) {
                  this.searchRobot(elementId + searchBoxSuffix, null);
                } else {
                  this.searchRobot(elementId + searchBoxSuffix, "");
                }
              }}
              onKeyUp={this.handleKeyEvent}
              onBlur={this.handleBlur}
            />
            <table id={elementId + searchBoxSuffix}>
              <tbody>{suggestedRobotList}</tbody>
            </table>
          </Row>
        );
      }
    });
    return robotListHtmlStr;
  }

  validateInputFields() {
    if (
      $("#inputSkillName").val().length > 0 &&
      this.state.skillCoverFile != null &&
      this.state.skillVideoFiles.length > 3 &&
      $("#categoryCheckboxes input:enabled:checked").length > 1 &&
      ($("#uploadAllRobotOption").is(":checked") ||
        $("#uploadPartialRobotOption").is(":checked"))
    ) {
      this.setState({ disabledSubmit: false });
    } else if (this.state.disabledSubmit === false) {
      this.setState({ disabledSubmit: true });
    }
  }

  validateInputFieldsWithFilelist(coverFile, videoFiles) {
    var videoCount = 0;
    if (videoFiles !== null) {
      videoCount = videoFiles.reduce((acc, cv) => (cv ? acc + 1 : acc), 0);
    }
    if (
      $("#inputSkillName").val().length > 0 &&
      coverFile != null &&
      videoCount > 3 &&
      $("#categoryCheckboxes input:enabled:checked").length > 1 &&
      ($("#uploadAllRobotOption").is(":checked") ||
        $("#uploadPartialRobotOption").is(":checked"))
    ) {
      this.setState({ disabledSubmit: false });
    } else if (this.state.disabledSubmit === false) {
      this.setState({ disabledSubmit: true });
    }
  }
  renderVideoSteps = () => {
    const n = 8;
    return (
      <UploadStep>
        {[...Array(n)].map((item, index) => {
          const stepIndex = index + 1;
          return (
            <div className="uploadbox" key={index}>
              <div className="step">
                <Trans>{`UploadMovieStep${stepIndex}`}</Trans>
              </div>
              <button
                onClick={(e) => {
                  $(`#step${stepIndex}VideoFile`).trigger("click");
                }}
                style={{ border: 0 }}
              >
                <div className="upload-content-wrap">
                  <span
                    className="close"
                    hidden={!this.checkVideoSelected(stepIndex)}
                    onClick={this.handleClearUploadVideo.bind(
                      this,
                      stepIndex,
                      `step${stepIndex}Video`
                    )}
                  >
                    &times;
                  </span>
                  <div className="file-upload-container">
                    <img
                      src="img_03.png"
                      style={{ width: "250px" }}
                      id={`step${stepIndex}VideoHint`}
                    />
                    <video
                      id={`step${stepIndex}VideoView`}
                      width="130px"
                      controls
                      hidden
                    >
                      <source id={`step${stepIndex}VideoPreview`} src="" />
                    </video>
                  </div>
                </div>
              </button>
              <Form.Control
                type="file"
                id={`step${stepIndex}VideoFile`}
                accept=".mp4"
                hidden
                onChange={this.handleChangeUploadVideo.bind(
                  this,
                  stepIndex,
                  `step${stepIndex}Video`
                )}
              />
            </div>
          );
        })}
      </UploadStep>
    );
  };
  render() {
    const schoolName = SchoolStateSlice.getSchoolName(
      this.props[StoreState.SCHOOL]
    );

    return (
      <div className="h-100">
        <Modal
          id="robotListModal"
          style={{ top: "25%" }}
          show={this.state.showRobotList}
          onHide={() => {
            this.setRobotListShow();
          }}
        >
          <div
            className="modal-content p-4"
            style={{ textAlign: "center", overflowY: "auto", height: "400px" }}
          >
            <Container>
              <Row className="mt-4 pb-3">
                <div className="modal-field-name">
                  <Trans>UploadInputRobotUuidHint</Trans>
                </div>
              </Row>
              {this.renderRobotList()}
              <Row className="pb-3">
                <button
                  className="btn btn-light"
                  onClick={() => this.addNewSelectedRobot("")}
                >
                  +
                </button>
              </Row>
              <Row className="pb-3">
                <button
                  className="modal-btn rounded-pill border-light"
                  onClick={() => this.setRobotListShow()}
                >
                  <Trans>Confirm</Trans>
                </button>
              </Row>
            </Container>
          </div>
        </Modal>
        <AntdModal
          open={this.state.upLoadModal}
          closeIcon={null}
          width={900}
          footer={null}
        >
          <div style={{ height: "300px", position: "relative" }}>
            <div style={{ position: "absolute", width: "100%", top: "50%" }}>
              <div className="progresstitle">
                正在上傳&nbsp;{this.state.upLoadTitle}
              </div>
              <Progress percent={this.state.upLoadProgress} />
            </div>
          </div>
        </AntdModal>
        <ContentBox>
          <div className="pageTitle">
            <Trans>UploadSkillName</Trans>
          </div>
          <input
            id="inputSkillName"
            className="inputSkill"
            type="text"
            onInput={() => this.validateInputFields()}
          ></input>

          <div className="pageTitle">
            <Trans>UploadSkillCategory</Trans>
          </div>

          <Forcebox id="categoryCheckboxes">
            {this.renderSkillCategory()}
          </Forcebox>

          {/* <div className="pageTitle">
            <Trans>UploadSkillVideo</Trans>
          </div>
          <UploadStep>
            <button
              onClick={(e) => {
                $("#fullVideoFile").trigger("click");
              }}
              style={{ border: 0 }}
            >
              <div className="upload-content-wrap">
                <span
                  className="close"
                  hidden={!this.checkVideoSelected(0)}
                  onClick={this.handleClearUploadVideo.bind(
                    this,
                    0,
                    "fullVideo"
                  )}
                >
                  &times;
                </span>
                <div className="file-upload-container">
                  <img
                    src="img_03.png"
                    style={{ width: "250px" }}
                    id="fullVideoHint"
                  />
                  <video id="fullVideoView" width="130px" controls hidden>
                    <source id="fullVideoPreview" src="" />
                  </video>
                </div>
              </div>
            </button>
            <Form.Control
              type="file"
              id="fullVideoFile"
              accept=".mp4"
              hidden
              onChange={this.handleChangeUploadVideo.bind(this, 0, "fullVideo")}
            />
          </UploadStep> */}

          <div className="pageTitle">
            <Trans>UploadStepsHints</Trans>
          </div>
          {this.renderVideoSteps()}
          <div className="pageTitle">
            <Trans>UploadSkillImage</Trans>
          </div>
          <UploadStep>
            <div className="uploadbox">
              <button
                onClick={(e) => {
                  $("#coverImageFile").trigger("click");
                }}
                style={{ border: 0 }}
              >
                <div className="upload-content-wrap">
                  <span
                    className="close"
                    hidden={this.state.skillCoverFile === null}
                    onClick={(e) =>
                      this.handleClearCoverImage(e, "coverImageFile")
                    }
                  >
                    &times;
                  </span>
                  <div className="file-upload-container">
                    <img
                      id="uploadPreview"
                      src={this.state.skillCoverImg}
                      alt="img"
                      style={{
                        padding: "0px",
                        margin: "0px",
                        width: "140px",
                      }}
                      hidden
                    />
                    <img
                      src="img_03.png"
                      style={{ width: "250px" }}
                      id="uploadHint"
                    />
                  </div>
                </div>
              </button>
              <Form.Control
                type="file"
                id="coverImageFile"
                accept=".jpeg, .jpg, .png"
                onChange={this.handleChangeCoverImage.bind(this)}
                hidden
              />
            </div>
          </UploadStep>
          <div className="pageline" />
          <div>
            <input
              id="uploadAllRobotOption"
              type="checkbox"
              onClick={() => this.uncheckPartialRobotOption()}
            ></input>
            <span>
              <Trans>UploadSkillVideoAgreement</Trans>
            </span>
          </div>
          <div>
            <input
              id="uploadPartialRobotOption"
              type="checkbox"
              onClick={() => this.setRobotListShow()}
            ></input>
            <span>
              <Trans>UploadPartialRobotOption</Trans>
            </span>
          </div>
          <div className="pageline" />
          <div className="btnBox">
            <button
              id="submitButton"
              className="submitBtn"
              onClick={() => this.submitSkill()}
              // disabled={this.state.disabledSubmit}
            >
              <Trans>Submit</Trans>
            </button>

            {/* <button
              id="cancelButton"
              className="submitBtn cancelBtn"
              onClick={() => this.resetState()}
            >
              <Trans>Cancel</Trans>
            </button> */}
          </div>

          <Container id="resultContent" hidden>
            <div style={{ textAlign: "center", padding: "200px" }}>
              <div className="modal-field-name mb-3">
                <Trans>UploadSkillMaterialSuccessfully</Trans>
              </div>
              <button
                className="modal-btn rounded-pill border-light mb-3"
                onClick={() => this.setShow(false)}
              >
                <Trans>GoToMain</Trans>
              </button>
              <div className="modal-field-hint" style={{ color: "red" }}>
                <Trans>RedirectBackToMainPage</Trans>
              </div>
            </div>
          </Container>
        </ContentBox>
      </div>
    );
  }
  uploadALioss = async (
    videoFileList,
    jwt,
    skillName,
    skillCheckBoxSelect,
    robotList,
    coverImage
  ) => {
    try {
      const partSize = 1024 * 1024 * 5;
      const skillVideoLinks = [];
      let coverImageOssUrl = "";

      const client = new OSS({
        accessKeySecret: "Sv2MIjQucpGG2RBNJZ92bTU5pCThDi",
        bucket: "talkbo-enci",
        region: "oss-cn-hongkong",
        accessKeyId: "LTAI5t7stBYpycYHk8vGvXhm",
        timeout: "3600000",
      });
      const ossDirectory =
        // eslint-disable-next-line no-undef
        process.env.NODE_ENV === "development" ? "devVideo" : "video";
      const ossDirectoryDiagram =
        // eslint-disable-next-line no-undef
        process.env.NODE_ENV === "development" ? "devDiagram" : "Diagram";
      // 上传生活技能示意图 coverImage
      this.setState({ upLoadModal: true });
      await client
        .put(`${ossDirectoryDiagram}/${coverImage.name}`, coverImage)
        .then((res) => {
          console.log(res, "res");
          if (res.res.status == 200) {
            coverImageOssUrl = res.url;
          }
        });
      if (!coverImageOssUrl) {
        this.setState({ upLoadModal: false });
        return false;
      }

      for (let i = 0; i < videoFileList.length; i++) {
        if (videoFileList[i]) {
          const videoName = `skillVideo_0${i}`;
          const step = i;
          this.setState({ upLoadTitle: videoFileList[i].name });
          await client
            .multipartUpload(
              `${ossDirectory}/${videoFileList[i].name}`,
              videoFileList[i],
              {
                parallel: videoFileList[i].size / partSize,
                partSize: partSize,
                progress: (p, cpt, res) => {
                  this.setState({ upLoadProgress: (p * 100).toFixed(1) });
                },
              }
            )
            .then((res) => {
              if (res.res.status == 200) {
                const aliossLink = res.res.requestUrls[0] || "";
                const targetIndex = aliossLink.indexOf("?uploadId");

                if (targetIndex !== -1) {
                  skillVideoLinks.push({
                    name: videoName,
                    link: aliossLink.split("?")[0],
                    step: step,
                  });
                } else {
                  skillVideoLinks.push({
                    name: videoName,
                    link: res.res.requestUrls[0] || "",
                    step: step,
                  });
                }
              }
            });
        }
      }
      await SkillManager.getInstance()
        .postSkillAsync(
          jwt,
          skillName,
          skillCheckBoxSelect,
          robotList,
          skillVideoLinks,
          coverImageOssUrl,
          null
        )
        .then((res) => {
          const errorName = res._errorName;
          if (!errorName) {
            this.setState({ upLoadModal: false });
            AntdModal.success({
              title: <Trans>UploadSkillMaterialSuccessfully</Trans>,
              content: <Trans>RedirectBackToMainPage</Trans>,
              okText: "返回主頁",
              onOk: () => {
                this.props.navigate(AppRoute.SkillSubmissHistory);
              },
            });

            setTimeout(() => {
              this.props.navigate(AppRoute.SkillSubmissHistory);
              AntdModal.destroyAll();
            }, 10000);
          } else {
            this.setState({ upLoadModal: false });
            message.error("視頻上傳失敗！");
          }
        });
      return true;
    } catch (error) {
      return false;
    }
  };
  async submitSkill() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    var skillName = $("#inputSkillName").val();

    const videoFileList = this.state.skillVideoFiles;
    const checkStepVideoLength = videoFileList.filter((item) => !!item);
    const isCheckBoxEnable =
      $("#uploadAllRobotOption").is(":checked") ||
      $("#uploadPartialRobotOption").is(":checked");
    const isCheckBoxRobot = $("#uploadPartialRobotOption").is(":checked");
    const coverImage = this.state.skillCoverFile;
    const qrCodeImage = this.state.skillQrCodeFiles || null;
    let robotList = [];
    if ($("#uploadPartialRobotOption").is(":checked")) {
      const selectedRobotList = this.state.selectedRobots;
      for (let i = 0; i < selectedRobotList.length; i++) {
        if (selectedRobotList[i] !== "" && selectedRobotList[i] !== undefined) {
          robotList.push(selectedRobotList[i]);
        }
      }
    } else {
      robotList = [];
    }
    const stepVideoAllSize = videoFileList
      .filter((item) => item)
      .reduce((pre, cur) => pre + cur.size, 0);

    // if(videoFileList[0].size > 1024 * 1024 * 6) {
    //    message.error('完整視頻大小不能超過600MB！')
    //    return
    // }
    // if(stepVideoAllSize > 1024 * 1024 * 6) {
    //   message.error('步驟視頻總大小不能超過600MB！')
    //   return
    // }

    if (!skillName) {
      message.error("請填寫生活技能名稱！");
      return;
    } else if (!this.state.skillCheckBoxSelect) {
      message.error("請填寫生活技能範疇！");
      return;
    } else if (checkStepVideoLength.length < 3) {
      message.error("請上傳至少三個步驟影片！");
      return;
    } else if (!coverImage) {
      message.error("請上傳生活技能示意圖！");
      return;
    } else if (!isCheckBoxEnable) {
      message.error("請勾選上載方式");
      return;
    } else if (isCheckBoxRobot && !robotList.length) {
      message.error("請輸入機器人唯一識別碼");
      return;
    }
    this.uploadALioss(
      videoFileList,
      jwt,
      skillName,
      [this.state.skillCheckBoxSelect],
      robotList,
      coverImage
    ).then((res) => {
      console.log(res, "responseRes");
      if (res) {
        message.success("success!");
      } else {
        message.error("上傳失敗，請重試！");
      }
    });
    // const postSkillResponse = await SkillManager.getInstance().postSkillAsync(
    //   jwt,
    //   skillName,
    //   [this.state.skillCheckBoxSelect],
    //   robotList,
    //   // this.state.skillVideoUrls,
    //   videoFileList,
    //   coverImage,
    //   null
    // );
    // if (!postSkillResponse.errorName) {
    //   AntdModal.success({
    //     title: <Trans>UploadSkillMaterialSuccessfully</Trans>,
    //     content: <Trans>RedirectBackToMainPage</Trans>,
    //     okText: "返回主頁",
    //     onOk: () => {
    //       this.props.navigate(AppRoute.SkillSubmissHistory);
    //     },
    //   });
    //   setTimeout(() => {
    //     this.props.navigate(AppRoute.SkillSubmissHistory);
    //     AntdModal.destroyAll();
    //   }, 10000);
    // } else {
    //   message.error("error");
    //   return;
    // }
  }
}

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default withRouter(connect(mapStateToProps)(ContentUpload));
