import Api from "./Api.js";
import PfnKey from "../PfnKey.js";
import AxiosHelper from "./AxiosHelper.js";

class PasswordManager {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new PasswordManager();
    }
    return this._instance;
  }

  async forgetPasswordAsync(username) {
    console.log(`Forget password : ${username}`);

    const params = {};
    params[PfnKey.KEY_USER_EMAIL] = username;

    const forgetPwdResponse = await AxiosHelper.axoisPostAsync(Api.FORGET_PASSWORD, params);

    if (forgetPwdResponse.errorName) {
      console.warn(`Forget password failed (${forgetPwdResponse.errorName}): ${username}`);
    } else {
      console.log(`Forget password succeeded : ${username}`);      
    }

    return forgetPwdResponse;
  }

  async resetPasswordAsync(username, password, jwt) {
    console.log(`Reset password : ${username}`);

    const params = {};
    params[PfnKey.KEY_USER_EMAIL] = username;
    params[PfnKey.KEY_PASSWORD] = password;
    params[PfnKey.KEY_JWT] = jwt;

    const resetPwdResponse = await AxiosHelper.axoisPostAsync(Api.RESET_PASSWORD, params);

    if (resetPwdResponse.errorName) {
      console.warn(`Reset password failed (${resetPwdResponse.errorName}): ${username}`);
    } else {
      console.log(`Reset password succeeded : ${username}`);      
    }

    return resetPwdResponse;
  }
}

export default PasswordManager;
