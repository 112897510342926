import React from "react";
import $ from "jquery";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import SchoolManager from "../../backend/SchoolManager";
import LoginStateSlice from "../../redux/LoginStateSlice";
import StoreState from "../../redux/StoreState";
import styled from "styled-components";
import { Table, Modal, message, Button } from "antd";
import uploadAliOss from "../../utils/uploadAliOss";
import "./index.css";
import { request } from "../../request";
const HeaderBar = styled.div`
  .addUserBtn {
    background: #999999;
    text-align: center;
    width: 100px;
    border: 0;
    border-radius: 10px;
    transition: 0.5s;
    color: white;
    text-align: center;
    padding: 2px 0;
    font-size: 14px;
    margin-bottom: 12px;
    margin-left: calc(60vw - 100px);
    .btnIcon {
      height: 14px;
      margin-right: 6px;
      margin-bottom: 2px;
    }
    &:hover {
      transform: scale(0.9);
    }
  }
`;
const UpdateBtn = styled.button`
  background: #0367ac;
  color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border: unset;
  border-radius: 6px;
  transition: 0.5s;
  cursor: pointer;
  height: 36px;
  width: 100px;
  font-size: 14px;
  &:hover {
    transform: scale(0.9);
  }
`;
const SchoolAntTable = styled.div`
  .ant-table {
    font-weight: 600;
    width: 60vw;
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background: #f39800;
        padding: 8px 0 !important;
        border: 2px solid white;
        border-radius: 0;
        color: white;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      text-align: center;
      .ant-table-row {
        .ant-table-cell {
          background: #f6ede4;
          border: 2px solid white;
          padding: 7px 0 !important;
        }
        &:hover {
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      min-width: 30px !important;
      height: 30px !important;
      border-radius: 100% !important;
    }
    .ant-pagination-item-active {
      background-color: #1677ff;
      border-color: #1677ff;
      color: #fff;
    }
  }
`;
const SchoolModal = styled.div`
  background-color: #ffebc2;
  border-radius: 10px;
  .title {
    text-align: center !important;
    height: 40px;
    background: #ffcc66 !important;
    line-height: 40px;
    border-radius: 10px 10px 0 0;
    font-weight: 600;
  }
  .modal-body {
    background-color: #ffebc2;
    position: relative;
    height: 600px;
    .upload-btn {
      height: 150px;
      width: 150px;
      border: 1px solid #dddddd;
      margin-left: 10px;
    }
    .school-line {
      top: 40px;
      position: absolute;
      left: 230px;
      .label {
        font-size: 16px;
        font-weight: 500;
      }
      .schoolInput {
        width: 300px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        height: 35px;
        margin-left: 10px;
        padding: 0 10px;
      }
      .field-error {
        color: #c70039;
        font-size: 16px;
        padding-top: 6px;
        letter-spacing: 1px;
        margin-left: 260px;
      }
    }
    .school-url-line {
      position: absolute;
      height: 150px;
      top: 120px;
      left: 260px;
      .label {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .water-mark-line {
      position: absolute;
      top: 300px;
      height: 150px;
      left: 260px;
      .label {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .footer {
      position: absolute;
      bottom: 60px;
      left: 285px;
      button[disabled] {
        opacity: 0.6;
      }
      .foot-left {
        position: absolute;
        width: 120px;
        height: 40px;
        border-radius: 20px;
        background: #ffcc66;
        font-size: 16px;
        font-weight: 700;
        color: black;
        border: 1px solid #dddddd;
      }
      .foot-right {
        position: absolute;
        width: 120px;
        height: 40px;
        border-radius: 20px;
        background: #ffcc66;
        font-size: 16px;
        font-weight: 700;
        color: black;
        border: 1px solid #dddddd;
        margin-left: 200px;
      }
    }
  }
`;

class DataSchoolManagement extends React.Component {
  constructor() {
    super();

    this.state = {
      show: false,
      disabledSubmit: true,
      showInputSchoolWarning: false,
      schoolList: [],
      editSchool: "",
      isEditModal: null,
      selectData: {},
      editSchoolPictureFile: "",
      editSchoolPictureImage: null,
      editSchoolWatermarkFile: "",
      editSchoolWatermarkImage: null,
    };
    this.columns = [
      {
        title: "學校名稱",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "校徽",
        dataIndex: "pictureUrl",
        key: "pictureUrl",
        render: (val, item) => {
          return <img src={val} style={{ width: "100px" }} />;
        },
      },
      {
        title: "水印",
        dataIndex: "watermarkUrl",
        key: "watermarkUrl",
        render: (val, item) => {
          return <img src={val} style={{ width: "100px" }} />;
        },
      },
      {
        title: "編輯",
        dataIndex: "operation",
        key: "operation",
        render: (val, item) => {
          return (
            <UpdateBtn onClick={(e) => this.handleEditAction(e, item.id)}>
              <Trans>UpdateDetails</Trans>
            </UpdateBtn>
          );
        },
      },
    ];
  }

  componentDidMount() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);

    this._getSchoolList(jwt);
  }

  async _getSchoolList(jwt) {
    var schoolList = [];
    const getSchoolResponse = await SchoolManager.getInstance().getSchoolsAsync(
      null,
      jwt
    );

    for (var school of getSchoolResponse.data) {
      schoolList.push({
        id: school.schoolUuid,
        name: school.schoolName,
        pictureUrl: school.pictureUrl,
        watermarkUrl: school.watermark,
      });
    }

    this.setState({
      schoolList: schoolList,
    });
  }

  handleModalHide = () => {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    this._getSchoolList(jwt);
  };

  handleEditAction = (e, sid) => {
    e.preventDefault();
    let selectData = null;
    this.setState({
      editSchoolPictureFile: "",
      editSchoolWatermarkFile: "",
    });
    if (sid !== null) {
      var school = this.state.schoolList.find((s) => s.id === sid);
      if (school !== undefined) {
        selectData = school;
      }
    }
    this.setState({
      editSchool: selectData === null ? "" : selectData.name,
      editSchoolPictureImage:
        selectData === null ? null : selectData.pictureUrl,
      editSchoolWatermarkImage:
        selectData === null ? null : selectData.watermarkUrl,
      showInputSchoolWarning: false,
      show: true,
      selectData: selectData,
      isEditModal: sid ? true : false,
    });
  };

  setShow(s) {
    this.setState({
      show: s,
    });
  }

  tableRowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: rowIdx % 2 === 0 ? "white" : "var(--table-bg-orange)",
      borderTop: "0px solid white",
      borderBottom: "0px solid white",
      height: "40px",
    };
  }

  render() {
    const schoolList = this.state.schoolList;
    const editSchool = this.state.editSchool;
    const editSchoolPicture = this.state.editSchoolPictureImage;
    const editSchoolWatermark = this.state.editSchoolWatermarkImage;
    return (
      <div className="h-100">
        <Modal
          id="uploadModal"
          open={this.state.show}
          onCancel={() =>this.modalOnClose()}
          footer={null}
          width={900}
          wrapClassName="recodeSchoolModal"
          style={{ padding: 0 }}
          closeIcon={false}
        >
          <SchoolModal>
            <div className="title">{<Trans>DataSchoolManagement</Trans>}</div>
            <div className="modal-body">
              <div className="school-line">
                <span className="label">
                  <Trans>SchoolName</Trans>&nbsp;:
                </span>
                <input
                  id="inputSchoolName"
                  className="schoolInput"
                  type="text"
                  value={editSchool}
                  onChange={(e) => this.validateInputSchool(e.target.value)}
                  maxLength={20}
                ></input>
                <div
                  className="field-error"
                  hidden={!this.state.showInputSchoolWarning}
                >
                  <Trans>DuplicatedSchoolName</Trans>
                </div>
              </div>
              <div className="school-url-line">
                <span className="label">
                  <Trans>SchoolPicture</Trans>&nbsp;:
                </span>
                <button className="upload-btn" style={{ padding: "2px" }}>
                  <div className="upload-image-wrap">
                    <span
                      className="close"
                      hidden={editSchoolPicture === null}
                      onClick={(e) =>
                        this.handleClearPicture(e, "file-upload-picture")
                      }
                    >
                      &times;
                    </span>
                    <div
                      className="file-upload-container"
                      onClick={(e) => {
                        $("#file-upload-picture").trigger("click");
                      }}
                    >
                      <div
                        className="file-upload-hint"
                        style={{ margin: "24px" }}
                        hidden={editSchoolPicture !== null}
                      >
                        <Trans>UploadImage</Trans>
                      </div>
                      <img
                        src={editSchoolPicture}
                        alt="img"
                        style={{
                          padding: "0px",
                          margin: "0px",
                          maxHeight: "150px",
                          maxWidth: "150px",
                        }}
                        hidden={editSchoolPicture === null}
                      />
                      <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        className="fa-3x"
                        style={{
                          padding: "10px",
                          margin: "0px",
                          height: "50px",
                          color: "#acacac",
                        }}
                        hidden={editSchoolPicture !== null}
                      />
                    </div>
                  </div>
                </button>
                <Form.Control
                  type="file"
                  id="file-upload-picture"
                  accept=".jpeg, .jpg, .png"
                  onChange={this.handleChangePicture.bind(this)}
                  hidden
                />
              </div>
              <div className="water-mark-line">
                <span className="label">
                  <Trans>SchoolWatermark</Trans>&nbsp;:
                </span>
                <button className="upload-btn" style={{ padding: "2px" }}>
                  <div className="upload-image-wrap">
                    <span
                      className="close"
                      hidden={editSchoolWatermark === null}
                      onClick={(e) =>
                        this.handleClearWatermark(e, "file-upload-watermark")
                      }
                    >
                      &times;
                    </span>
                    <div
                      className="file-upload-container"
                      onClick={(e) => {
                        $("#file-upload-watermark").trigger("click");
                      }}
                    >
                      <div
                        className="file-upload-hint"
                        style={{ margin: "24px" }}
                        hidden={editSchoolWatermark !== null}
                      >
                        <Trans>UploadImage</Trans>
                      </div>
                      <img
                        src={editSchoolWatermark}
                        alt="img"
                        hidden={editSchoolWatermark === null}
                        style={{
                          padding: "0px",
                          margin: "0px",
                          maxHeight: "150px",
                          maxWidth: "150px",
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        className="fa-3x"
                        style={{
                          padding: "10px",
                          margin: "0px",
                          height: "50px",
                          color: "#acacac",
                        }}
                        hidden={editSchoolWatermark !== null}
                      />
                    </div>
                  </div>
                </button>
                <Form.Control
                  type="file"
                  id="file-upload-watermark"
                  accept=".jpeg, .jpg, .png"
                  onChange={this.handleChangeWatermark.bind(this)}
                  hidden
                />
              </div>
              <div className="footer">
                <Button
                  id="submitButton"
                  className="foot-left"
                  onClick={() => this.submitSchool()}
                  disabled={this.state.disabledSubmit}
                >
                  <Trans>Save</Trans>
                </Button>
                <button
                  id="cancelButton"
                  className="foot-right"
                  onClick={() =>this.modalOnClose()}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </div>
          </SchoolModal>
        </Modal>
        <div className="h-100">
          <div className="userMangeTitle">
            <Trans>DataSchoolManagement</Trans>
          </div>
          <HeaderBar>
            <div
              onClick={(e) => this.handleEditAction(e, null)}
              className="addUserBtn"
            >
              <img src="icon/add.png" className="btnIcon"></img>
              <Trans>AddSchool</Trans>
            </div>
          </HeaderBar>
          <SchoolAntTable>
            <Table dataSource={schoolList} columns={this.columns} />
          </SchoolAntTable>
        </div>
      </div>
    );
  }

  validateInputSchool(inputSchool) {
    this.setState({
      editSchool: inputSchool,
    });
    var school = this.state.schoolList.find((s) => s.name === inputSchool);
    if (school === undefined) {
      this.setState({
        showInputSchoolWarning: false,
        disabledSubmit: false,
      });
      this.updateButtonStatus();
    } else {
      if (
        this.state.editSchool === null ||
        this.state.editSchool !== inputSchool
      ) {
        this.setState({
          showInputSchoolWarning: true,
          disabledSubmit: true,
        });
      } else {
        this.setState({
          showInputEmailWarning: false,
          disabledSubmit: false,
        });
        this.updateButtonStatus();
      }
    }
  }

  updateButtonStatus() {
    var schoolName = $("#inputSchoolName").val();
    if (
      schoolName !== undefined &&
      schoolName !== "" &&
      !this.state.showInputSchoolWarning
    ) {
      if (this.state.disabledSubmit === true) {
        this.setState({ disabledSubmit: false });
      }
    } else if (this.state.disabledSubmit === false) {
      this.setState({ disabledSubmit: true });
    }
  }

  handleChangePicture(e) {
    if (e.target.files[0] !== undefined) {
      this.setState({
        editSchoolPictureFile: e.target.files[0],
        editSchoolPictureImage: URL.createObjectURL(e.target.files[0]),
      });
    }
    this.updateButtonStatus();
  }

  handleChangeWatermark(e) {
    if (e.target.files[0] !== undefined) {
      this.setState({
        editSchoolWatermarkFile: e.target.files[0],
        editSchoolWatermarkImage: URL.createObjectURL(e.target.files[0]),
      });
    }
    this.updateButtonStatus();
  }

  handleClearPicture(e, id) {
    // clear previous image
    $("#" + id).val("");

    this.setState({
      editSchoolPictureFile: "",
      editSchoolPictureImage: null,
    });
    this.updateButtonStatus();
  }

  handleClearWatermark(e, id) {
    // clear previous image
    $("#" + id).val("");

    this.setState({
      editSchoolWatermarkFile: "",
      editSchoolWatermarkImage: null,
    });
    this.updateButtonStatus();
  }
  modalOnClose() {
    this.setState({ disabledSubmit: true });
    this.setShow(false);
  }
  async submitSchool() {
    const loginState = this.props[StoreState.LOGIN];
    const jwt = LoginStateSlice.getJwt(loginState);
    const {
      editSchool,
      isEditModal,
      selectData,
      editSchoolPictureFile,
      editSchoolPictureImage,
      editSchoolWatermarkFile,
      editSchoolWatermarkImage,
    } = this.state;
    // show status message and disabled buttons
    if (this.state.disabledSubmit === false) {
      this.setState({ disabledSubmit: true });
    }
    if (!isEditModal) {
      const schoolImgFromOss = await uploadAliOss(
        "img",
        editSchoolPictureFile.name,
        editSchoolPictureFile
      );
      const schoolWatermarkOss = await uploadAliOss(
        "img",
        editSchoolWatermarkFile.name,
        editSchoolWatermarkFile
      );
      if (!editSchool) {
        message.error("學校名稱不能為空！");
        return;
      }
      const params = {
        schoolName: editSchool,
      };
      if (schoolImgFromOss) {
        params.pictureUrl = schoolImgFromOss;
      }
      if (schoolWatermarkOss) {
        params.watermarkUrl = schoolWatermarkOss;
      }
      request("/school/addSchool", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        data: params,
      }).then((res) => {
        if (res.code === 200) {
          message.success("success");
        } else {
          message.error(res.msg);
        }
        this.setShow(false);
        this.handleModalHide();
      });
      // const postSchoolResponse =
      //   await SchoolManager.getInstance().postSchoolAsync(
      //     jwt,
      //     editSchool,
      //     this.state.editSchoolPictureFile,
      //     this.state.editSchoolWatermarkFile
      //   );
      // if (postSchoolResponse.errorName) {
      //   message.error(postSchoolResponse.errorName);
      // } else {
      //   this.setShow(false);
      //   message.success("success!");
      // }
    } else {
      if (!editSchool) {
        message.error("學校名稱不能為空!");
        return;
      }
      const params = {
        schoolName: editSchool,
        schoolUuid: selectData.id,
      };

      const updateSchoolImgUrl = await uploadAliOss(
        "img",
        editSchoolPictureFile.name,
        editSchoolPictureFile
      );
      const updateSchoolWaterMarkUrl = await uploadAliOss(
        "img",
        editSchoolWatermarkFile.name,
        editSchoolWatermarkFile
      );

      if (updateSchoolImgUrl) {
        params.pictureUrl = updateSchoolImgUrl;
      }
      if (updateSchoolWaterMarkUrl) {
        params.watermarkUrl = updateSchoolWaterMarkUrl;
      }
      request("/school/updateSchool", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        data: params,
      }).then((res) => {
        if (res.code === 200) {
          message.success("Success!");
        } else {
          message.error(res.msg);
        }
        this.setShow(false);
        this.handleModalHide();
      });
      // var schoolPicture, watermark;
      // if (this.state.editSchoolPictureImage !== null) {
      //   if (this.state.editSchoolPictureFile === "") {
      //     // image unchange
      //     schoolPicture = null;
      //   } else {
      //     schoolPicture = this.state.editSchoolPictureFile;
      //   }
      // } else {
      //   schoolPicture = "";
      // }
      // if (this.state.editSchoolWatermarkImage !== null) {
      //   if (this.state.editSchoolWatermarkFile === "") {
      //     // image unchange
      //     watermark = null;
      //   } else {
      //     watermark = this.state.editSchoolWatermarkFile;
      //   }
      // } else {
      //   watermark = "";
      // }
      // const putSchoolResponse =
      //   await SchoolManager.getInstance().putSchoolAsync(
      //     selectData.id,
      //     jwt,
      //     editSchool,
      //     schoolPicture,
      //     watermark
      //   );
      // if (putSchoolResponse.errorName) {
      //   alert(putSchoolResponse.errorName);
      // } else {
      //   this.setShow(false);
      // }
    }
  }
}

function mapStateToProps(state) {
  const props = {};

  props[StoreState.LOGIN] = state[StoreState.LOGIN];
  props[StoreState.SCHOOL] = state[StoreState.SCHOOL];

  return props;
}

export default connect(mapStateToProps)(DataSchoolManagement);
